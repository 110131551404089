import classNames from 'classnames/bind';
import { Button, Container, Title } from 'components';
import SVG from 'components/svg/svg';

import styles from './help.scss';
const cx = classNames.bind(styles);

function Help() {
	return (
		<Container className={cx('help-card-container')}>
			<Container.Column span={4} className={cx('help-card')}>
				<div>
					<Title size={5} className={cx('help-card-title')}>
						<SVG name="question_mark" size={12} className='help-top-button'/>
						Help and knowledge
					</Title>
					<p className={cx('help-card-description')}>
						Search our help center for documentation on specific product and account related functionality.
					</p>
				</div>
				<a href="https://tejik.com/help/" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">
					<Button type="secondary" shape="hollow-dark">Open help center</Button>
				</a>
			</Container.Column>
			<Container.Column span={4} className={cx('help-card')}>
				<div>
					<Title size={5} className={cx('help-card-title')}>
						<SVG name="question_answer"/>
						Open a support ticket
					</Title>
					<p className={cx('help-card-description')}>
						Our team is ready to help in fixing an issue. Our average response time is within 48 business hours.
					</p>
				</div>
				<a href="https://forms.clickup.com/4205861/f/40b95-7914/ZQATESQKAB4AWPF8UV" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">
					<Button type="secondary" shape="hollow-dark">Open ticket</Button>
				</a>
			</Container.Column>
			<Container.Column span={4} className={cx('help-card')}>
				<div>
					<Title size={5} className={cx('help-card-title')}>
						<SVG name="chat"/>
						Share feedback
					</Title>
					<p className={cx('help-card-description')}>
						Help us improve the app.
					</p>
				</div>
				<a href="https://forms.gle/cL98yuUEm9gTw9eQ7" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">
					<Button type="secondary" shape="hollow-dark">Share feedback</Button>
				</a>
			</Container.Column>
		</Container>
	);
}

export default Help;

import request from 'api/request';
import debounce from 'lodash.debounce';

import { Modal } from 'components';
import { flow, getParent, getSnapshot, types } from 'mobx-state-tree';

const { confirm, message } = Modal;
const { array, boolean, identifier, string, number, maybeNull, model } = types;

const KeywordModel = model('KeywordModel', {
	id: identifier,
	text: maybeNull(string),
	volume: maybeNull(number),
})
	.views((self) => ({
		get baseURL() {
			return `${self.parent.baseURL}/keywords/${self.id}`;
		},
		get parent() {
			return getParent(self, 2);
		},
	}))
	.actions((self) => ({
		saveKeyword: debounce(() => self.updateKeyword(), 700),
		updateKeyword: flow(function* () {
			try {
				yield request.put(self.baseURL, getSnapshot(self));
				message({ type: 'success', text: 'Successfully updated keyword.' });
			} catch (err) {
				throw err;
			} finally {
			}
		}),
		setText(text) {
			self.text = text;
			self.saveKeyword();
		},
		setVolume(volume) {
			self.volume = Number(volume);
			self.saveKeyword();
		},
	}));

const KeywordQuestionModel = model('KeywordQuestionModel', {
	id: identifier,
	text: maybeNull(string),
})
	.views((self) => ({
		get baseURL() {
			return `${self.parent.baseURL}/keyword-questions/${self.id}`;
		},
		get parent() {
			return getParent(self, 2);
		},
	}))
	.actions((self) => ({
		saveKeywordQuestion: debounce(() => self.updateKeywordQuestion(), 700),
		updateKeywordQuestion: flow(function* () {
			try {
				yield request.put(self.baseURL, getSnapshot(self));
				message({ type: 'success', text: 'Successfully updated keyword question.' });
			} catch (err) {
				throw err;
			} finally {
			}
		}),
		setText(text) {
			self.text = text;
			self.saveKeywordQuestion();
		},
	}));

const KeywordHashtagModel = model('KeywordHashtagModel', {
	id: identifier,
	tag: maybeNull(string),
})
	.views((self) => ({
		get baseURL() {
			return `${self.parent.baseURL}/keyword-hashtags/${self.id}`;
		},
		get parent() {
			return getParent(self, 2);
		},
	}))
	.actions((self) => ({
		saveKeywordHashtag: debounce(() => self.updateKeywordHashtag(), 700),
		updateKeywordHashtag: flow(function* () {
			try {
				yield request.put(self.baseURL, getSnapshot(self));
				message({ type: 'success', text: 'Successfully updated keyword hashtag.' });
			} catch (err) {
				throw err;
			} finally {
			}
		}),
		setTag(tag) {
			self.tag = tag;
			self.saveKeywordHashtag();
		},
	}));

export const KeywordResearchModel = model('KeywordResearchModel', {
	id: identifier,
	complete: boolean,
	keyword_questions: array(KeywordQuestionModel),
	keywords: array(KeywordModel),
	keyword_hashtags: array(KeywordHashtagModel),
})
	.views((self) => ({
		get baseURL() {
			return `${self.parent.campaign.baseURL}/keyword-research/${self.id}`;
		},
		get parent() {
			return getParent(self, 1);
		},
	}))
	.actions((self) => ({
		markKeywordResearchDone: flow(function* () {
			try {
				const confirmOkay = yield confirm({
					text: 'Are you sure you want to continue?',
					children: 'By clicking "Generate" this area will not be available again but other features on this page will remain accessible.',
					okText: 'Generate'
				});
				if (confirmOkay) {
					self.complete = true;
					yield request.put(self.baseURL, { complete: self.complete });
				}
			} catch (err) {
				throw err;
			} finally {
			}
		}),
		addKeyword: flow(function* () {
			try {
				const { data } = yield request.post(`${self.baseURL}/keywords`, { text: null, volume: null });
				self.keywords.push(data);
				message({ type: 'success', text: 'Successfully added keyword.' });
			} catch (err) {
				throw err;
			} finally {
			}
		}),
		addKeywordQuestion: flow(function* () {
			try {
				const { data } = yield request.post(`${self.baseURL}/keyword-questions`);
				self.keyword_questions.push(data);
				message({ type: 'success', text: 'Successfully added keyword question.' });
			} catch (err) {
				throw err;
			} finally {
			}
		}),
		addKeywordHashtag: flow(function* () {
			try {
				const { data } = yield request.post(`${self.baseURL}/keyword-hashtags`);
				self.keyword_hashtags.push(data);
				message({ type: 'success', text: 'Successfully added keyword hashtag.' });
			} catch (err) {
				throw err;
			} finally {
			}
		}),
	}));

import { Container } from "components";

// import classNames from 'classnames/bind';
// import styles from './workspace-settings.scss';

// const cx = classNames.bind(styles);

function Integrations(props) {

	return (
		<Container title="Integrations" titleSize={6} titleIcon={'hub'} titleWeight={400}>
			<div>Coming soon</div>
		</Container>
	);
}

export default Integrations;

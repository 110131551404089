import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SVG from 'components/svg/svg';

import classNames from 'classnames';

export default class Card extends Component {
	generateCardContent = () => {
		const { action, className, icon, onClick, text } = this.props;
		
		return <div className={classNames('tejik-card', className, { 'clickable': onClick })} onClick={onClick}>
			{icon && <SVG className="tejik-card-icon" name={icon} size={20} />}
			{text}
			{action && <SVG className="tejik-card-action" name={action} size={20} />}
		</div>;
	}

	render() {
		const { to } = this.props;

		if (to) {
			return <Link to={this.props.to} style={{ textDecoration: 'none' }}>
				{this.generateCardContent()}
			</Link>;
		}

		return this.generateCardContent();
	}
}
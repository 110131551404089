import { ModelConnector } from 'stores';
import { observer } from "mobx-react-lite";
import { Container, Input, LinkedIdeas, Loader, Modal } from "components";
import LinkedIdeaModel from "./linkedIdeas.model";

import classNames from 'classnames/bind';
import styles from './linkedIdeas.scss';

const cx = classNames.bind(styles);

const LinkedIdeaModal = observer(({ onOk, onCancel, model, visible }) => (
	<Modal
		visible={visible}
		closable
		overlayClosable
		onOk={onOk}
		onCancel={onCancel}
		width="80%"
		footer={[]}
		footerPosition="end"
		title="Linked Content"
		titleSVGName="link"
		titleSize={4}
	>
		<Container noPadding>
			<Container.Column span={8}>
				<div className={cx('linked-idea-modal-search-input')}>
					<Input
						placeholder=" search for existing content"
						value={model.searchTerm}
						icon="search"
						leftIcon={true}
						style={{ width: '100%', height: 45, marginTop: '-1em' }}
						inputStyle={{ backgroundColor: 'transparent' }}
						onChange={(e) => model.setSearchTerm(e.target.value)}
					/>
				</div>
				{model.isSearching ? <Loader className="mt-1" /> :
					<LinkedIdeas ideas={model.foundIdeasFiltered} onSelect={model.onIdeaSelect} />
				}
			</Container.Column>
			<Container.Column span={4} className='linked-content-column'>
				<p className="mb-1">Linked content:</p>
				<LinkedIdeas ideas={model.linkedIdeas} onUnlink={model.onIdeaUnlink}/>
			</Container.Column>
		</Container>
	</Modal>
));

export default ModelConnector(LinkedIdeaModal, LinkedIdeaModel);

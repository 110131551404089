import React from 'react';
import request from 'api/request';
import { flow, types } from 'mobx-state-tree';
import { RecyclePlatformModel } from 'models/recycle-platform.model';
import { PlatformIconMap, PlatformLabelMap } from "utils/utils";
import SVG from "components/svg/svg";

import { Modal } from 'components';
const { message } = Modal;

const { boolean, array, model, optional } = types;

const RecycleContentModel = model('RecycleContentModel', {
	recycle_platforms: array(RecyclePlatformModel),
	isLoading: optional(boolean, true),
})
	.views((self) => ({
		get baseURL() {
			return `${self.workspace.baseURL}/settings/recycle-platforms`;
		},
		get workspace() {
			return self.props.workspace;
		},
		get recyclePlatformsRows() {
			const platforms = self.workspace.joinedSocialPlatforms
			const recycleMap = new Map();
			for (const platform of platforms) {
				recycleMap.set(platform, {
					id: platform,
					title: PlatformLabelMap.get(platform),
					icon: <SVG name={PlatformIconMap.get(platform)} />,
					days_to_recycle: 0,
					setDaysToRecycle: (days_to_recycle) => self.addRecyclePlatform(platform, days_to_recycle),
					status_id: '',
					status_options: [],
				});
			}

			for (const recycle_platform of self.recycle_platforms) {
				recycleMap.set(recycle_platform.platform, {
					...recycle_platform,
					title: PlatformLabelMap.get(recycle_platform.platform),
					icon: <SVG name={PlatformIconMap.get(recycle_platform.platform)} />,
					setDaysToRecycle: (days_to_recycle) => recycle_platform.setDaysToRecycle(Number(days_to_recycle)),
					status_options: recycle_platform.statusOptions,
					status: recycle_platform.status,
					setStatusId: recycle_platform.setStatusId,
				});
			}
			return Array.from(recycleMap.values());
		},
	}))
	.actions((self) => ({
		afterCreate() {
			self.fetchRecyclePlatforms();
		},
		fetchRecyclePlatforms: flow(function* () {
			try {
				const { data } = yield request.get(self.baseURL);
				self.recycle_platforms = data;
			} catch (err) {
				console.error(err);
				throw err;
			} finally {
				self.isLoading = false;
			}
		}),
		addRecyclePlatform: flow(function* (platform, days_to_recycle) {
			try {
				const { data } = yield request.post(self.baseURL, {
					platform,
					days_to_recycle,
				});

				self.recycle_platforms.push(data);
				message({ type: 'success', text: 'Successfully added recycle platform.' });
			} catch (err) {
				console.error(err);
				throw err;
			}
		}),
	}));

export default RecycleContentModel;

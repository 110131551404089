import { types, flow } from 'mobx-state-tree';
import request from 'api/request';

const { string, boolean, model, optional } = types;
// Model
const ForgotPasswordModel = model('ForgotPasswordModel', {
	email: optional(string, ''),
	successfulRequest: optional(boolean, false),
})
.actions((self) => ({
	resetPassword: flow(function* () {
		try {
			yield request.post('/auth/forgot-password', { email: self.email });
			self.successfulRequest = true;
		} catch (err) {
			console.error(err);
		}
	}),
	// Setters
	setEmail(email) {
		self.email = email;
	},
}));

export default ForgotPasswordModel;
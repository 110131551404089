import { Container } from "components";
import SVG from "components/svg/svg";
import { Link } from "react-router-dom";

// import classNames from 'classnames/bind';
// import styles from './workspace-settings.scss';

// const cx = classNames.bind(styles);

function SocialMediaCaptions(props) {
	return (
		<Container title="Social Media Captions" actions={<Link to="/workspace-settings/templates"><SVG name="close" /></Link>}>
		</Container>
	);
}

export default SocialMediaCaptions;

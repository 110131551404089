import request from 'api/request';
import { destroy, flow, getParent, getSnapshot, types } from 'mobx-state-tree';
import { Observer } from 'mobx-react-lite';
import debounce from 'lodash.debounce';

import React from 'react';
import { Input, Modal } from 'components';
import { PlatformHashtagCount } from 'models/platform_hashtag_count.model';
import { IsSocialMediaPlatform } from 'utils/utils';

const { confirm, message } = Modal;
const { array, boolean, model, optional, identifier, string, number } = types;

const HashtagModel = model('HashtagModel', {
	id: identifier,
	tag: string,
	order: number,
})
	.views((self) => ({
		get baseURL() {
			return `${self.parent.baseURL}/${self.id}`;
		},
		get parent() {
			return getParent(self, 4);
		},
	}))
	.actions((self) => ({
		saveHashtag: debounce(() => self.updateHashtag(), 700),
		deleteHashtag: flow(function* () {
			try {
				yield request.delete(self.baseURL);
				self.parent.removeHashtag(self);
				message({ type: 'success', text: 'Successfully deleted hashtag.' });
			} catch (err) {
				throw err;
			} finally {
			}
		}),
		updateHashtag: flow(function* (showSuccess = true) {
			try {
				yield request.put(self.baseURL, getSnapshot(self));

				if (showSuccess) {
					message({ type: 'success', text: 'Successfully updated hashtag.' });
				}
			} catch (err) {
				console.log(err);
				throw err;
			} finally {
			}
		}),
		setTag(tag) {
			self.tag = tag;
			self.saveHashtag();
		},
	}));

export const HashtagGroupModel = model('HashtagGroupModel', {
	id: identifier,
	name: string,
	hashtags: array(HashtagModel),
})
	.views((self) => ({
		get baseURL() {
			return `${self.parent.baseURL}/group/${self.id}`;
		},
		get parent() {
			return getParent(self, 2);
		},
		get orderedHashtags() {
			return Array.from(self.hashtags)
				.sort((a, b) => a.order - b.order);
		},
	}))
	.actions((self) => ({
		saveHashtagGroup: debounce(() => self.updateHashtagGroup(), 700),
		deleteHashtagGroup: flow(function* () {
			try {
				yield request.delete(self.baseURL);
				self.parent.removeHashtagGroup(self);
				message({ type: 'success', text: 'Successfully deleted hashtag group.' });
			} catch (err) {
				throw err;
			} finally {
			}
		}),
		updateHashtagGroup: flow(function* () {
			try {
				yield request.put(self.baseURL, getSnapshot(self));
				message({ type: 'success', text: 'Successfully updated hashtag group.' });
			} catch (err) {
				console.log(err);
				throw err;
			} finally {
			}
		}),
		onDragEnd(result) {
			if (!result.destination) {
				return;
			}

			if (result.destination.index === result.source.index) {
				return;
			}

			const hashtagsToUpdate = [];

			if (result.source.index > result.destination.index) {
				// Coming from the bottom up
				const sourceHashtag = self.orderedHashtags.at(result.source.index);
				const destinationHashtag = self.orderedHashtags.at(result.destination.index);
				const temp = destinationHashtag.order;
				for (const hashtag of self.orderedHashtags) {
					if (hashtag.order >= destinationHashtag.order && hashtag.order < sourceHashtag.order) {
						hashtag.order = hashtag.order + 1;
						hashtagsToUpdate.push(hashtag);
					}
				}
				sourceHashtag.order = temp;
				hashtagsToUpdate.push(sourceHashtag);
			} else {
				// Moving from the top down
				const sourceHashtag = self.orderedHashtags.at(result.source.index);
				const destinationHashtag = self.orderedHashtags.at(result.destination.index);
				const temp = destinationHashtag.order;
				for (const hashtag of self.orderedHashtags) {
					if (hashtag.order <= destinationHashtag.order && hashtag.order > sourceHashtag.order) {
						hashtag.order = hashtag.order - 1;
						hashtagsToUpdate.push(hashtag);
					}
				}
				sourceHashtag.order = temp;
				hashtagsToUpdate.push(sourceHashtag);
			}

			for (const hashtag of hashtagsToUpdate) {
				hashtag.updateHashtag(null, null, false);
			}
			message({ type: 'success', text: 'Successfully reorded hashtags.' });
		},
		setName(name) {
			self.name = name;
			self.saveHashtagGroup();
		},
	}));

// Model
const HashtagsViewModel = model('HashtagsViewModel', {
	hashtagGroups: array(HashtagGroupModel),
	platformHashtagCounts: array(PlatformHashtagCount),
	isLoading: optional(boolean, true),
	showNewHashtagGroup: optional(boolean, false),
	newHashtagGroupName: optional(string, ''),
	searchTerm: optional(string, ''),
})
	.views((self) => ({
		get baseURL() {
			return `${self.props.workspace.baseURL}/settings/social/hashtags`;
		},
		get platformHashtagCountsBaseURL() {
			return `${self.baseURL}/platform-hashtag-counts`;
		},
	}))
	.actions((self) => ({
		afterCreate() {
			self.fetchHashtags();
			self.fetchPlatformHashtagCounts();
		},
		searchHashtags: debounce(() => self.fetchHashtags(), 700),
		fetchHashtags: flow(function* () {
			try {
				const { data } = yield request.get(self.baseURL, {
					params: {
						search_term: self.searchTerm,
					}
				});
				self.hashtagGroups = data;
			} catch (err) {
				console.log(err);
				throw err;
			} finally {
				self.isLoading = false;
			}
		}),
		fetchPlatformHashtagCounts: flow(function* () {
			try {
				const platforms = self.WorkspaceStore.current_workspace.platforms.filter((platform) => IsSocialMediaPlatform(platform) || platform === 'YOUTUBE');
				for (const platform of platforms) {
					self.platformHashtagCounts.push({
						id: `new-${platform}`,
						platform,
					});
				}

				const { data } = yield request.get(self.platformHashtagCountsBaseURL);

				for (const platformCount of data) {
					self.findAndReplacePlatformHashtag(platformCount.platform, platformCount);
				}
			} catch (err) {
				console.log(err);
				throw err;
			} finally {
				self.isLoading = false;
			}
		}),
		findAndReplacePlatformHashtag(p, newPlatformHashtagCount) {
			const index = self.platformHashtagCounts.findIndex(({ platform }) => platform === p);
			self.platformHashtagCounts.splice(index, 1, newPlatformHashtagCount);
		},
		setPlatformHashtagCount(platformHashtagCount, count) {
			if (Number.isNaN(Number(count))) return;

			if (platformHashtagCount.id.includes('new')) {
				// Create
				platformHashtagCount.setCount(Number(count), false);
				self.createPlatformHashtagCountDebounce({
					platform: platformHashtagCount.platform,
					count: Number(count),
				})
			} else {
				// Update
				platformHashtagCount.setCount(Number(count));
			}
		},
		createPlatformHashtagCountDebounce: debounce((...props) => self.createPlatformHashtagCount(...props), 700),
		createPlatformHashtagCount: flow(function* ({ platform, count }) {
			try {
				const { data } = yield request.post(self.platformHashtagCountsBaseURL, {
					platform,
					count,
				});

				self.findAndReplacePlatformHashtag(data.platform, data);
				message({ type: 'success', text: 'Successfully created platform hashtag count.' });
			} catch (err) {
				console.log(err);
				throw err;
			} finally {
				self.isLoading = false;
			}
		}),
		addHashtag: flow(function* (e, { tag, hashtag_group_ids }) {
			try {
				for (const hashtag_group_id of hashtag_group_ids) {
					const group = self.hashtagGroups.find((group) => group.id === hashtag_group_id);
					const maxOrder = Math.max(...group.hashtags.map(({ order }) => order), 0);
					const hashtag = { tag, order: maxOrder + 1, hashtag_group_id };
					const { data } = yield request.post(self.baseURL, hashtag);
					group.hashtags = [...group.hashtags, data];
				}
				message({ type: 'success', text: 'Successfully created hashtag.' });
			} catch (err) {
				throw err;
			} finally {
				self.isLoading = false;
			}
		}),
		addHashtagGroup: flow(function* () {
			try {
				const result = yield confirm({
					text: 'Create new hashtag group',
					children: <Observer>{() =>
						<Input
							placeholder="Hashtag group name"
							value={self.newHashtagGroupName}
							onChange={(e) => self.setNewHashtagGroupName(e.target.value)}
						/>
					}</Observer>
				});

				if (!result) return;

				const { data } = yield request.post(`${self.baseURL}/group`, { name: self.newHashtagGroupName });

				self.hashtagGroups.push(data);
				message({ type: 'success', text: 'Successfully created hashtag group.' });
			} catch (err) {
				console.log(err);
				throw err;
			}
		}),
		setNewHashtagGroupName(newHashtagGroupName) {
			self.newHashtagGroupName = newHashtagGroupName;
		},
		removeHashtag(hashtag) {
			self.isLoading = true;
			destroy(hashtag);
			self.isLoading = false;
		},
		removeHashtagGroup(hashtagGroup) {
			destroy(hashtagGroup);
		},
		setSearchTerm(searchTerm) {
			self.searchTerm = searchTerm;
			self.isLoading = true;
			self.searchHashtags();
		},
	}));

export default HashtagsViewModel;

import React, { Component } from 'react';
import { 
	UserImage, 
	SVG, 
	StatusSelection,
	DatePicker
} from 'components';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import classNames from 'classnames';
import { Observer } from 'mobx-react-lite';
import styles from './platform-widget.scss';
import { rootStore } from 'stores';

const cx = classNames.bind(styles);

class PlatformWidget extends Component {
	constructor(props) {
		super(props);
		this.dropdownRef = React.createRef();
    this.popoverRef = React.createRef();
		this.state = { active: false, };
	}

	handleDocumentClick = (e) => {
		this.setState({ active: false }, () => {
			document.removeEventListener('click', this.handleDocumentClick);
		});
	}

	toggleActive = (e) => {
		if(this.dropdownRef.current && this.popoverRef.current) {
			const rect = this.dropdownRef.current.getBoundingClientRect();

			this.popoverRef.current.style.top = `${rect.bottom}px`;
			this.popoverRef.current.style.left = `${rect.left}px`;
		}

    this.setState((prevState) => ({ active: !prevState.active }), () => {
			if (this.state.active) {
				setTimeout(() => {
					document.addEventListener('click', this.handleDocumentClick);
				}, 0);
			} else {
					document.removeEventListener('click', this.handleDocumentClick);
			}
		});
	}

	selectedItem = (e) => {
		this.props.onSelect(e);
		this.toggleActive();
	}

	selectedDate = (e) => {
		this.props.onDateSelect(e);
	}

	render() {
		const { className, item, style, selectedPlatform, onClick, showOptions } = this.props;

		return (
			<Observer>{() => (
				<div className={classNames('tejik-platform-widget', { 'selected': selectedPlatform === item.platform }, className)} style={style} onClick={onClick}>
					<div className={cx('tejik-platform-widget-row')}>
						<div>
							<div className={cx('tejik-platform-widget-name')}>
								<SVG name={item.channel} size={20} style={{ marginRight: '0.5em'}}></SVG>
								<span>{item.platform}</span>
							</div>
							{showOptions ?
							<>
								<div ref={this.dropdownRef} onClick={this.toggleActive} className={cx('tejik-platform-widget-status')} style={{ backgroundColor: item.status?.color }}>{item.status?.name}</div>
								<Observer>{() => (
									<div ref={this.popoverRef} className={cx('tejik-platform-widget-status-modal')} style={{ display: this.state.active ? 'block' : 'none' }}>
										<StatusSelection
											statusOptions={rootStore.WorkspaceStore.current_workspace.statusOptions(item.code)}
											onSelect={this.selectedItem}
											value={item.status?.id}
										/>
									</div>
								)}</Observer>
							</> : 
							<div ref={this.dropdownRef} onClick={this.toggleActive} className={cx('tejik-platform-widget-status')} style={{ backgroundColor: item.status?.color }}>{item.status?.name}</div>
							}
						</div>
						<div className={cx('tejik-platform-widget-progress')}>
							<CircularProgressbar value={item.percentage} text={`${item.percentage}%`} 
								styles={buildStyles({pathColor: `#355070`, textColor: '#000000', trailColor: '#E5E5E5',	textSize: '24px' })}/>
						</div>
					</div>
					<div className={cx('tejik-platform-widget-row', 'tejik-platform-widget-second')}>
						<div style={{ height: 14 }}>
							{item.sponsored && 
								<div className={cx('tejik-platform-widget-check')}>
									<SVG name='handshake' className={cx('tejik-platform-widget-icon')}/>
									<span className={cx('tejik-platform-widget-text')}>Collaboration</span>
								</div>
							}
						</div>
						{item.recycled && 
							<div className={cx('tejik-platform-widget-check')} style={{ justifySelf: 'flex-end'}}>
								<SVG name='recycle' className={cx('tejik-platform-widget-icon')}/>
								<span className={cx('tejik-platform-widget-text')}>Recycled</span>
							</div>
						}
					</div>
					<div className={cx('tejik-platform-widget-row', 'tejik-platform-widget-footer')}>
						{item.status?.assignee ? 
							<UserImage user={item.status?.assignee}/> :
							<div style={{ height: 30 }}></div>
						}
						{showOptions ?
							<DatePicker
								selected={item.raw_date ? new Date(item.raw_date) : new Date()}
								onChange={this.selectedDate}
								customInput={
									<div className={cx('tejik-platform-widget-date')}>
										<SVG name="calendar_today" style={{ marginRight: '0.5em', color: '#909090' }}/>
										<span>{item.due_date}</span>
									</div>
								}
							/> : 
							<div className={cx('tejik-platform-widget-date')} style={{ marginTop: 2 }}>
								<SVG name="calendar_today" style={{ marginRight: '0.5em', color: '#909090' }}/>
								<span>{item.due_date}</span>
							</div>
						}
					</div>
				</div>
			)}</Observer>
		);
	}
}

export default PlatformWidget;
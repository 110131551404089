import { Container, Info, Input, Popover, StatusSelection, Menu } from "components";
import SVG from "components/svg/svg";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

import RecycleContentModel from './recycle-content.model';
import { ModelConnector } from "stores";
import { Fragment } from "react";

import classNames from 'classnames/bind';
import styles from './recycle-content.scss';

const cx = classNames.bind(styles);

const RecycleContent = observer(({ model }) => {
	return (
		<Container title="Recycle Content" titleSize={1} titleIcon={'recycling'} titleWeight={400} actions={<div>
				<Link to="/workspace-settings/templates"><SVG name="close" /></Link>
			</div>}
			isLoading={model.isLoading}
		>		
			<Container.Column span={12}></Container.Column>
				{model.recyclePlatformsRows.map((row) => {
					return (
						<Fragment key={row.title}>
							<Container.Column span={12} className={cx('recycle-select-status-line')}>
								<div style={{ display: 'flex', flexDirection: 'row' }}>
									<div style={{ width: 160, marginTop: 12 }} ><Menu.Item icon={row.icon} text={row.title}/></div>
									<div style={{ width: 'fit-content', overflow: 'hidden', whiteSpace: 'nowrap', marginRight: '1em', fontSize: 14 }} className={classNames('tejik-preffix')}>recycle</div>
									<Input size="medium" type="number" placeholder="never" min={-1} value={row.days_to_recycle > 0 ? row.days_to_recycle : null} className={classNames('recycle-days')}
										onChange={(e) => {
											if(isNaN(e.target.value) || e.target.value < 1) e.target.value = null; row.setDaysToRecycle(e.target.value ? parseInt(e.target.value) : null);
									}}/>
								</div>
								{row.days_to_recycle > 0 && 
								<div className={cx('recycle-select-status-line')}>
									<div className={classNames('tejik-preffix','recycle-days-message')}>days after due date, once campaign is closed. Reopen in</div>
									<Popover position="left" width={220} style={{ fontSize: 14 }}
										content={<StatusSelection statusOptions={row?.status_options} onSelect={row.setStatusId} value={row.status?.id}/>}
									>
									{row.status ?
										<div className={cx('recycle-platform-select-status')}>
											<div style={{ width: 20, height: 20, backgroundColor: row.status.color, borderRadius: 20 }} />{row.status.name}<SVG name="expand_more" style={{ justifySelf: 'flex-end'}} />
										</div>
										:
										<div className={cx('recycle-platform-select-empty')} style={{ display: 'flex', flexDirection:'row', justifyContent: 'space-between' }}>
											<div className={classNames('tejik-preffix')}>Select status here </div><SVG name="expand_more" style={{ marginRight: 4 }} />
										</div>
									}
									</Popover>
								</div>}
							</Container.Column>
						</Fragment>
					)
				})}
			<Info infoKey="recycle-content" />
		</Container>
	);
});

export default ModelConnector(RecycleContent, RecycleContentModel);

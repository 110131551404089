import request from 'api/request';
import { destroy, flow, getParent, getSnapshot, types } from 'mobx-state-tree';
import { Modal } from 'components';
import debounce from 'lodash.debounce';

const { message } = Modal;
const { array, boolean, model, optional, identifier, string, number } = types;

export const PinterestBoardModel = model('PinterestBoardModel', {
	id: identifier,
	name: string,
	order: number,
})
	.views((self) => ({
		get baseURL() {
			return `${self.parent.baseURL}/${self.id}`;
		},
		get parent() {
			return getParent(self, 2);
		},
	}))
	.actions((self) => ({
		savePinterestBoard: debounce(() => self.updatePinterestBoard(), 700),
		deletePinterestBoard: flow(function* () {
			try {
				yield request.delete(self.baseURL);
				self.parent.removeBoard(self);
				message({ type: 'success', text: 'Successfully deleted Pinterest board.' })
			} catch (err) {
				throw err;
			} finally {
			}
		}),
		updatePinterestBoard: flow(function* (showSuccess = true) {
			try {
				yield request.put(self.baseURL, getSnapshot(self));

				if (showSuccess) {
					message({ type: 'success', text: 'Successfully updated Pinterest board.' })
				}
			} catch (err) {
				console.log(err);
				throw err;
			} finally {
			}
		}),
		setName(name) {
			self.name = name;
			self.savePinterestBoard();
		}
	}));

// Model
const PinterestBoardViewModel = model('PinterestBoardViewModel', {
	boards: array(PinterestBoardModel),
	isLoading: optional(boolean, true),
	searchTerm: optional(string, ''),
})
	.views((self) => ({
		get baseURL() {
			return `${self.props.workspace.baseURL}/settings/social/pinterest-board`;
		},
		get orderedBoards() {
			return Array.from(self.boards).slice().sort((a, b) => a.order - b.order);
		},
	}))
	.actions((self) => ({
		afterCreate() {
			self.fetchPinterestBoards();
		},
		searchPinterestBoards: debounce(() => self.fetchPinterestBoards(), 700),
		fetchPinterestBoards: flow(function* () {
			try {
				const { data } = yield request.get(self.baseURL, {
					params: {
						search_term: self.searchTerm,
					}
				});
				self.boards = data;
			} catch (err) {
				console.log(err);
				throw err;
			} finally {
				self.isLoading = false;
			}
		}),
		addBoard: flow(function* (e, { name }) {
			try {
				self.isLoading = true;
				const maxOrder = Math.max(...self.boards.map(({ order }) => order), 0);
				const board = { name, order: maxOrder + 1 };

				const { data } = yield request.post(self.baseURL, board);
				self.boards = [...self.boards, data];
				message({ type: 'success', text: 'Successfully created Pinterest board.' })
			} catch (err) {
				throw err;
			} finally {
				self.isLoading = false;
			}
		}),
		removeBoard(board) {
			self.isLoading = true;
			destroy(board);
			self.isLoading = false;
		},
		onDragEnd(result) {
			if (!result.destination) {
				return;
			}

			if (result.destination.index === result.source.index) {
				return;
			}

			const boardsToUpdate = [];

			if (result.source.index > result.destination.index) {
				// Coming from the bottom up
				const sourceBoard = self.orderedBoards.at(result.source.index);
				const destinationBoard = self.orderedBoards.at(result.destination.index);
				const temp = destinationBoard.order;
				for (const board of self.orderedBoards) {
					if (board.order >= destinationBoard.order && board.order < sourceBoard.order) {
						board.order = board.order + 1;
						boardsToUpdate.push(board);
					}
				}
				sourceBoard.order = temp;
				boardsToUpdate.push(sourceBoard);
			} else {
				// Moving from the top down
				const sourceBoard = self.orderedBoards.at(result.source.index);
				const destinationBoard = self.orderedBoards.at(result.destination.index);
				const temp = destinationBoard.order;
				for (const board of self.orderedBoards) {
					if (board.order <= destinationBoard.order && board.order > sourceBoard.order) {
						board.order = board.order - 1;
						boardsToUpdate.push(board);
					}
				}
				sourceBoard.order = temp;
				boardsToUpdate.push(sourceBoard);
			}

			for (const board of boardsToUpdate) {
				board.updatePinterestBoard(false);
			}
			message({ type: 'success', text: 'Successfully reorded Pinterest boards.' })
		},
		setSearchTerm(searchTerm) {
			self.searchTerm = searchTerm;
			self.isLoading = true;
			self.searchCallToActions();
		},
	}));

export default PinterestBoardViewModel;

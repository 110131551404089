import { types } from 'mobx-state-tree';
import { PlatformEnum } from './global/global.model';
import { StatusModel } from './status.model';

const { array, identifier, string, model } = types;

export const StatusTemplateModel = model('StatusTemplateModel', {
	id: identifier,
	name: string,
	platforms: array(PlatformEnum),
	statuses: array(StatusModel),
})
	.views((self) => ({}))
	.actions((self) => ({}));

/* eslint-disable react-hooks/exhaustive-deps */
import { observer } from 'mobx-react-lite';
import { ModelConnector } from 'stores';
import { Button, Checkbox, Form, PlatformSelection, UserImage } from 'components';

import classNames from 'classnames/bind';
import styles from './filters.scss';
import FilterModel from 'models/filter.model';
import { useEffect } from 'react';

const cx = classNames.bind(styles);

const FiltersForm = observer(({ onOk, model, filterType }) => {

	useEffect(() => {
		model.setFilterType(filterType);
	}, []);
	
	return (
		<div className="planner-filter-form">
			<Form >
				<Form.Item label="Platforms" style={{ padding: 0 }}>
					<PlatformSelection
						platforms={model.platforms}
						togglePlatform={model.togglePlatform}
						workspacePlatforms={model.sortedPlatforms}
					/>
				</Form.Item>
				<Form.Item label="Users">
					<div className={'workspace-users-list'}>
						{model.workspace.users.map((user) => (
							<Button
								key={user.id}
								className={cx('workspace-list-button','tejik-selections-button', { 'active': model.hasUser(user.id) })}
								type="text"
								onClick={() => model.toggleUser(user.id, user)}
							>
								<UserImage user={user} />
							</Button>
						))}
					</div>
				</Form.Item>
				<Form.Item labelPosition="row" style={{ padding: 0 }}>
					<Checkbox label="Collaboration content" checked={model.sponsoredContent}
						onChange={(e) => model.setSponsoredContent(e.target.checked)} />
				</Form.Item>
				<Form.Item labelPosition="row" >
					<Checkbox label="Recycled content" checked={model.recycledContent}
						onChange={(e) => model.setRecycledContent(e.target.checked)} />
				</Form.Item>
			</Form>
			<div className='actions'>
				<Button
					className={classNames('tejik-toggle-option')}
					type="secondary"
					shape="hollow"
					onClick={() => model.clearFilters(filterType)}
				>
					Clear
				</Button>
				<Button
					className={classNames('tejik-toggle-option')}
					type="black"
					onClick={() => { model.applyFilters(filterType); onOk({
							platforms: model.platforms, 
							users: model.users.map(user => user.id), 
							sponsoredContent: model.sponsoredContent, 
							recycledContent: model.recycledContent, 
							filterApplied: true  
						}); 
					}}
				>
					Apply Filter
				</Button>
			</div>
		</div>
	);
});

const Filters = ModelConnector(FiltersForm, FilterModel)

export { Filters };

const FiltersView = ({ onOk, filterType }) => {
	return (
		<Filters onOk={onOk} filterType={filterType} />
	);
};

export default FiltersView;

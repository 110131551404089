
import { Observer, observer } from 'mobx-react-lite';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ModelConnector } from "stores";
import CallToActionModel from "./call-to-action.model";

import SVG from 'components/svg/svg';
import { Container, Button, Input, NewEntity, Info } from "components";


import classNames from 'classnames/bind';
import styles from './call-to-action.scss';

const cx = classNames.bind(styles);

const CTA = observer(({ callToAction, index }) => (
	<Draggable draggableId={callToAction.id} index={index}>
		{(provided) => (
			<div
				className={cx('draggable-item')}
				ref={provided.innerRef}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
			>
				<Observer>{() => (<>
					<SVG className="text-neutral-2" name="drag_indicator" />
					<Input
						value={callToAction.name}
						onChange={(e) => callToAction.setName(e.target.value)}
						size="small"
						inputStyle={{ backgroundColor: 'transparent'}}
						hidden
					/>
					<Button type="text" icon="delete" size="small" iconSize={16} onClick={callToAction.deleteCTA} />
				</>)}
				</Observer>
			</div>
		)}
	</Draggable>
));

const CallToAction = observer(({ model }) => {
	return (
		<Container title="Call To Action" titleSize={1} titleIcon={'phone'} titleWeight={400} isLoading={model.isLoading} actions={			
			<div className={classNames('show-desktop-only')}>
				<Input
					key="search-bar"
					size="small"
					icon="search"
					value={model.searchTerm}
					onChange={(e) => model.setSearchTerm(e.target.value)}
					inputStyle={{ backgroundColor: 'transparent', height: 36 }}
					style={{ width: 400 }}
				/>
			</div>}>
			<div className={classNames('show-mobile-only')}>
				<Input
					key="search-bar-mobile"
					size="small"
					icon="search"
					value={model.searchTerm}
					onChange={(e) => model.setSearchTerm(e.target.value)}
					inputStyle={{ backgroundColor: 'transparent', height: 36 }}
					style={{ width: '100%'}}
				/>
			</div>
			<Container.Column span={12}>
				<NewEntity
					placeholder="add new call-to-action"
					confirmText="Create new CTA"
					icon="add"
					valueKey="name"
					onSubmit={model.addCTA}
					inputClass={cx('new-cta-input')}
				/>
			</Container.Column>
			<Container.Column span={12}>
				<div className={cx('draggable-list')}>
					<DragDropContext onDragEnd={model.onDragEnd}>
						<Droppable droppableId="list">
							{(provided) => (
								<div ref={provided.innerRef} {...provided.droppableProps}>
									{model.orderedCallToActions.map((callToAction, index) => <CTA callToAction={callToAction} index={index} key={callToAction.id} />)}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</div>
			</Container.Column>
			<Info infoKey="call-to-action"/>
		</Container>
	);
});

export default ModelConnector(CallToAction, CallToActionModel);

import request from 'api/request';

import { flow, getSnapshot, types } from 'mobx-state-tree';
import { PlatformEnum } from './global/global.model';
import { Modal } from 'components';
import debounce from 'lodash.debounce';
import { rootStore } from 'stores';

const { message } = Modal;
const { identifier, number, maybeNull, model } = types;

export const PlatformHashtagCount = model('PlatformHashtagCount', {
	id: identifier,
	platform: PlatformEnum,
	count: maybeNull(number),
})
	.views((self) => ({
		get baseURL() {
			return `${rootStore.WorkspaceStore.currentBaseURL}/settings/social/hashtags/platform-hashtag-counts/${self.id}`;
		},
	}))
	.actions((self) => ({
		savePlatformHashtagCount: debounce((...props) => self.updatePlatformHashtagCount(...props), 700),
		updatePlatformHashtagCount: flow(function* () {
			yield request.put(self.baseURL, getSnapshot(self));
			message({ type: 'success', text: 'Successfully updated platform hashtag count.' });
		}),
		setCount(count, shouldSave = true) {
			self.count = count;
			if (shouldSave) {
				self.savePlatformHashtagCount();
			}
		}
	}));

// import request from 'api/request';
import { types } from 'mobx-state-tree';
const { model } = types;

// Model
const MainModel = model('MainModel', {

}).actions((self) => ({
	afterCreate() {
		self.WorkspaceStore.fetchWorkspaces();
	},
}));

export default MainModel;

import React, { Component } from 'react';
import { Link, useMatch } from 'react-router-dom';

import classNames from 'classnames';
import SVG from 'components/svg/svg';

export default class Menu extends Component {
	static Item = (item) => {
		const match = useMatch({ path: item?.to || 'null', end: item?.exact || false });
		const subMatches = item.subPaths ? item.subPaths.map(route => { return useMatch({ path: route, end: true })}) : [];
		const subMatch = subMatches.filter(m => m !== null).length > 0;

		const forceClose = (e) => {
			const event = new CustomEvent('force-close', {
				bubbles: true,
				detail: 'MENU',
			});
			e.target.dispatchEvent(event);
		}

		const actionClick = (e) => {
			if(item.disabled) return;
			
			if (item.action) {
				if (!item.state) {
					forceClose(e);
				}

				item.action(e);
			}

			if (item.to) {
				forceClose(e);
			}
		}

		const generateItemContent = (item) => {
			if (item.href) {
				return (
					<a href={item.href} target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">
						<span style={item.textStyle} className={classNames('tejik-menu-item-text', item.textClass, { 'text-ellipsis': item.ellipsis })}>{item.text}</span>
					</a>
				);
			}

			if (item.to) {
				return (
					<Link to={item.to} onClick={forceClose} style={{ textDecoration: 'none' }} state={item.payload}>
						<span style={item.textStyle} className={classNames('tejik-menu-item-text', item.textClass, { 'text-ellipsis': item.ellipsis })}>{item.text}</span>
					</Link>
				)
			} else {
				return <span style={item.textStyle} className={classNames('tejik-menu-item-text', item.textClass, { 'text-ellipsis': item.ellipsis })}>{item.text}</span>;
			}
		}

		const generateItemDescription = (item) => {
			if (item.description) {
				return (
					<div className="tejik-menu-item-description">
						{item.description}
					</div>
				);
			}
		}

		return (
			<li
				className={classNames(
					item.sidebar ? (item.isSubMenu ? 'tejik-side-subitem': 'tejik-side-item') : 'tejik-menu-item',
					{
						'tejik-menu-item-action': item.action || item.to || item.href,
						'tejik-menu-item-icon': item.icon || item.avatar,
						'tejik-menu-item-state': item.state,
						'active': Boolean(match || subMatch),
						'disabled': item.disabled,
						'selected': item.selected,
						'header': item.header,
						'footer': item.footer,
						'wrap-text': item.description,
					},
					item.className
				)}
				onClick={actionClick}
			>
				<span className={classNames('tejik-menu-item-container')}>
					{item.icon && <SVG className={classNames(item.iconClass, 'tejik-menu-item-icon')} style={item.iconStyle} name={item.icon} />}
					{item.avatar && <span className="tejik-menu-item-icon">{item.avatar}</span>}
					{generateItemContent(item)}
					{generateItemDescription(item)}
				</span>
				{item.state && <SVG className="tejik-menu-item-state" name={item.state} />}
			</li>
		)
	};

	render() {
		const { className, items = [], overflowMax, sidebar } = this.props;

		return (
			<ul className={classNames('tejik-menu', `tejik-menu-max-${overflowMax}`, className)}>
				{items.filter((item) => ((item.access !== undefined ? item.access : true) && !item.hidden)).map((item, index) => <Menu.Item key={index} {...{...item, sidebar: sidebar}} />)}
			</ul>
		);
	}
}
import { types } from 'mobx-state-tree';
const { enumeration } = types;

export const PlatformEnum = enumeration('PlatformEnum', [
	'BLOG',
	'PODCAST',
	'YOUTUBE',
	'EMAIL',
	'INSTAGRAM',
	'FACEBOOK',
	'PINTEREST',
	'TIKTOK',
	'LINKEDIN',
	'TWITTER',
	'SOCIAL',
	'SIDEMENU',
]);
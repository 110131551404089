import { Button, Form, Input, Title } from 'components';
import { observer } from 'mobx-react-lite';
import { ModelConnector } from 'stores';
import SignUpModel from './sign-up.model';
import { TejikLogo } from 'components/svg/svg';
import { Link } from 'react-router-dom';

import classNames from 'classnames/bind';
import styles from './sign-up.scss';

const cx = classNames.bind(styles);
const SignUp = observer(({ model }) => {
	return (
		<div className={cx('tejik-signup-container')}>
		{!model.last_step &&
			<div className={cx('tejik-signup')}>
				<TejikLogo />
				<Title size={4} weight={600} className={cx('tejik-signup-text')}>Create your account</Title>
				<Form style={{ paddingRight: 0, paddingLeft: 0 }}>
					<>
						<Form.Row>
							<Form.Item>
								<Input
									placeholder="First Name (required)"
									value={model.first_name}
									onChange={(e) => model.setFirstName(e.target.value)}
									autoComplete="given-name"
									type="text"
								/>
							</Form.Item>
						</Form.Row>
						<Form.Row>
							<Form.Item>
								<Input
									placeholder="Last Name (required)"
									value={model.last_name}
									onChange={(e) => model.setLastName(e.target.value)}
									autoComplete="family-name"
									type="text"
								/>
							</Form.Item>
						</Form.Row>
						<Form.Item>
							<Input
								placeholder="Email (required)"
								value={model.email}
								onChange={(e) => model.setEmail(e.target.value)}
								autoComplete="email"
								type="text" />
						</Form.Item>
						<Form.Item>
							<Button onClick={() => model.setLastStep(true)} suffix="east" type="secondary" size="large" style={{ width: '100%', padding: 14, justifyContent: 'center', flex: 1 }}>Continue</Button>
						</Form.Item>
					</>
				</Form>
				<p className={cx('tejik-terms-and-conditions')}>
					By signing up, you agree to <a href="https://tejik.com/terms/" target="_blank">Tejik's terms</a><br />and acknowledge you've read our <a href="https://tejik.com/privacy-policy/" target="_blank">privacy policy</a>
				</p>
				<p className={cx('tejik-login')}>
					Already have an account? <Link to="/login">Login</Link>
				</p>
			</div>
			}
			{model.last_step &&
				<div className={cx('tejik-signup-final')}>
					<TejikLogo />
					<Title size={4} weight={600} className={cx('tejik-signup-text')}>Create your account</Title>
					<Form style={{ paddingRight: 0, paddingLeft: 0 }}>
						<>
							<p>Signing up as {model.email}. <Button type="link" onClick={() => model.setLastStep(false)}>Not right?</Button></p>
							<Form.Item>
								<Input
									placeholder="Password (required)"
									value={model.password}
									onChange={(e) => model.setPassword(e.target.value)}
									autoComplete="new-password"
									type="password" />
							</Form.Item>
							<Form.Item>
								<Button onClick={model.signUp} type="secondary" size="large" style={{ width: '100%', padding: 14, justifyContent: 'center', flex: 1 }}>Sign Up</Button>
							</Form.Item>
						</>
					</Form>
					<p className={cx('tejik-terms-and-conditions')}>
						By signing up, you agree to <a href="https://tejik.com/terms/" target="_blank">Tejik's terms</a><br />and acknowledge you've read our <a href="https://tejik.com/privacy-policy/" target="_blank">privacy policy</a>
					</p>
					<p className={cx('tejik-login')}>
						Already have an account? <Link to="/login">Login</Link>
					</p>
				</div>
			}
		</div>
	);
});

export default ModelConnector(SignUp, SignUpModel);

import request from 'api/request';
import { flow, types } from 'mobx-state-tree';
import { WorkspaceModel } from 'models/workspace.model';

import { Modal } from 'components';
const { confirm } = Modal;

const { array, model, optional, boolean } = types;
// Model
const WorkspacesModel = model('WorkspacesModel', {
	isLoading: optional(boolean, true),
	workspaces: array(WorkspaceModel),
})
	.views((self) => ({
		get baseURL() {
			return `/organizations/${self.organizationId}/workspaces`;
		},
		get organizationId() {
			return self.props.params.organization_id || self.AuthStore.currentOrganizationId;
		},
	}))
	.actions((self) => ({
		afterCreate() {
			self.fetchWorkspaces();
		},
		fetchWorkspaces: flow(function* () {
			try {
				const { data } = yield request.get(self.baseURL);
				self.workspaces = data;
			} catch (err) {
				console.error(err);
			} finally {
				self.isLoading = false;
			}
		}),
		deleteWorkspace: flow(function* (workspaceId) {
			const confirmDelete = yield confirm({ text: 'Are you sure you want to delete this workspace?', okText: 'Delete' });
			if (confirmDelete) {
				yield request.delete(`${self.baseURL}/${workspaceId}`);
				yield self.fetchWorkspaces();
				if (self.WorkspaceStore.current_workspace.id === workspaceId) {
					self.WorkspaceStore.selectCurrentWorkspace(self.workspaces.at(0)?.id || null);
				}
				yield self.WorkspaceStore.fetchWorkspaces();
			}
		}),
		setIsLoading(isLoading) {
			self.isLoading = isLoading;
		},
	}));

export default WorkspacesModel;

import CampaignView from "views/app/campaigns/campaign/campaign.view";
import SideMenu from "./campaign-menu/campaign-menu.view";

import { observer } from 'mobx-react-lite';
import { ModelConnector } from 'stores';

import CampaignModel from '../campaign/campaign.model';

import classNames from 'classnames/bind';
import styles from './campaign-detail.scss';
import { useState } from "react";

const cx = classNames.bind(styles);

const CampaignDetail = observer(({ model }) => {
	const [campaignUrlSlug, setCampaignUrlSlug] = useState('');

    return (
		<div className={cx('main')}>
			<SideMenu campaignUrlSlug={campaignUrlSlug} setCampaignUrlSlug={setCampaignUrlSlug}/>
			<div style={{ width: 300, height: '100%' }} className={cx('show-desktop-only')}></div>

			<div className={cx('main-content')} id="main-content" >
				<CampaignView campaignUrlSlug={campaignUrlSlug} setCampaignUrlSlug={setCampaignUrlSlug}/>
			</div>
		</div>
    );
});

export default ModelConnector(CampaignDetail, CampaignModel);

import { observer } from "mobx-react-lite";
import { Modal } from "components";
import FiltersView from "views/app/shared/filters/filters.view";

const FiltersModal = observer(({ onOk, onCancel, visible, filterType }) => (
	<Modal
		visible={visible}
		closable
		onOk={onOk}
		onCancel={onCancel}
		footer={[]}
		width={450}
		title="Filters"
		titleSVGName="filter_list"
		titleSVGClassName="tejik-svg-icon"
		titleSize={5}
		footerPosition="end"
		paddingModalContent={'0 16px'}
		headerStyle={{'paddingTop': '1rem'}}
		closeIconStyle={{'top': '14px', 'right': '14px'}}
	>
		{visible && <FiltersView onOk={onOk} filterType={filterType}/>}
	</Modal>
));

export default FiltersModal;

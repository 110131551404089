/* eslint-disable react-hooks/exhaustive-deps */
import { observer } from 'mobx-react-lite';
import { ModelConnector } from 'stores';
import { Container } from 'components';
import { useLocation } from 'react-router-dom';

import BlogView from './campaign-platform/blog.view';
import EmailView from './campaign-platform/email.view';
import PinterestView from './campaign-platform/pinterest.view';
import PodcastView from './campaign-platform/podcast.view';
import SocialView from './campaign-platform/social.view';
import YoutubeView from './campaign-platform/youtube.view';

import CampaignModel from './campaign.model';
import { PlatformLabelMap } from 'utils/utils';

import classNames from 'classnames/bind';
import styles from './campaign.scss';
import { useEffect } from 'react';

const cx = classNames.bind(styles);

const Campaign = observer(({ model, setCampaignUrlSlug, campaignUrlSlug }) => {
	const { state } = useLocation();

	useEffect(() => {
		if(state && state.platform) {
			model.setPreSelectedPlatform(PlatformLabelMap.get(state.platform));
		}
	}, [state]);

  return (
		<Container
			headerClassName={cx('campaign-header-container')}
			titleSize={4}
			isLoading={model.isLoading}
			style={{ overflow: 'hidden' }}
		>
			<div className={'platform-scrollbar'} >
				{model.platformSlides}
			</div>
			{model.isBlogSelected && <BlogView campaign_platform={model.blogCampaign} updateUrlFn={setCampaignUrlSlug} urlValue={campaignUrlSlug} delePlatformFn={model.deleteCampaignPlatform}/>}
			{model.isEmailSelected && <EmailView campaign_platform={model.emailCampaign} delePlatformFn={model.deleteCampaignPlatform}/>}
			{model.isBPodcastSelected && <PodcastView campaign_platform={model.podcastCampaign} delePlatformFn={model.deleteCampaignPlatform}/>}
			{model.isPinterestSelected && <PinterestView campaign_pinterest={model.pinterestCampaign} additional_keywords={model.pinterestKeywords} addPinterestBoard={model.addPinterestBoard}/>}
			{model.isSocialSelected && <SocialView campaign_social={model.socialCampaign}/>}
			{model.isYoutubeSelected && <YoutubeView campaign_platform={model.youtubeCampaign} delePlatformFn={model.deleteCampaignPlatform}/>}
		</Container>
  );
});

export default ModelConnector(Campaign, CampaignModel);

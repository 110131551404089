import { Observer, observer } from 'mobx-react-lite';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ModelConnector } from "stores";
import PinterestBoardModel from "./pinterest-board.model";

import SVG from 'components/svg/svg';
import { Container, Button, Input, NewEntity } from "components";

import classNames from 'classnames/bind';
import styles from './pinterest-board.scss';

const cx = classNames.bind(styles);

const Board = observer(({ board, index }) => (
	<Draggable draggableId={board.id} index={index}>
		{(provided) => (
			<div
				className={cx('draggable-item')}
				ref={provided.innerRef}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
			>
				<Observer>{() => (<>
					<SVG className="text-neutral-2" name="drag_indicator" />
					<Input
						value={board.name}
						onChange={(e) => board.setName(e.target.value)}
						size="small"
						inputStyle={{ backgroundColor: 'transparent'}}
						hidden
					/>
					<Button type="text" icon="delete" size="small" iconSize={16} onClick={board.deletePinterestBoard} />
				</>)}
				</Observer>
			</div>
		)}
	</Draggable>
));

const PinterestBoard = observer(({ model }) => {
	return (
		<Container title="Pinterest boards" titleSize={1} titleIcon={'pinterest'} titleWeight={400} isLoading={model.isLoading} actions={			
			<div className={classNames('show-desktop-only')}>
				<Input
					key="search-bar"
					size="small"
					icon="search"
					value={model.searchTerm}
					onChange={(e) => model.setSearchTerm(e.target.value)}
					inputStyle={{ backgroundColor: 'transparent', height: 36 }}
					style={{ width: 400 }}
				/>
			</div>}>
			<div className={classNames('show-mobile-only')}>
				<Input
					key="search-bar-mobile"
					size="small"
					icon="search"
					value={model.searchTerm}
					onChange={(e) => model.setSearchTerm(e.target.value)}
					inputStyle={{ backgroundColor: 'transparent', height: 36 }}
					style={{ width: '100%'}}
				/>
			</div>
			<Container.Column span={12}>
				<NewEntity
					placeholder="add new Pinterest board"
					confirmText="Create new board"
					icon="add"
					valueKey="name"
					onSubmit={model.addBoard}
					inputClass={cx('new-board-input')}
				/>
			</Container.Column>
			<Container.Column span={12}>
				<div className={cx('draggable-list')}>
					<DragDropContext onDragEnd={model.onDragEnd}>
						<Droppable droppableId="list">
							{(provided) => (
								<div ref={provided.innerRef} {...provided.droppableProps}>
									{model.orderedBoards.map((board, index) => <Board board={board} index={index} key={board.id} />)}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</div>
			</Container.Column>
		</Container>
	);
});

export default ModelConnector(PinterestBoard, PinterestBoardModel);

import { observer } from 'mobx-react-lite';
import { ModelConnector } from 'stores';
import { Container, Info, Input, List, Title } from 'components';
import CreateIdeaModal from "modals/createIdea/createIdea";

import IdeasModel from './ideas.model';

import classNames from 'classnames/bind';
import styles from './ideas.scss';
import SVG from 'components/svg/svg';
const cx = classNames.bind(styles);

const Ideas = observer(({ model }) => {
	return (
		<Container
			className='ideas-container'
			title='Ideas'
			headerClassName='show-desktop-only'
			isLoading={model.isLoading}
			actions={[
				<Input
					key="search-bar"
					size="small"
					icon="search"
					value={model.searchTerm}
					onChange={(e) => model.setSearchTerm(e.target.value)}
					className='ideas-container-search'
					inputStyle={{ backgroundColor: 'transparent', height: '100%' }}
				/>
			]}
		>
			<div className='show-mobile-only'>
				<div style={{ display: 'flex', alignItems: 'center', marginBottom: '1em'}}>
					<SVG name='emoji_objects' style={{ marginRight: '0.5em'}} size={20} /><Title size={4}>Ideas</Title>
				</div>
				<Input
					key="search-bar"
					icon="search"
					value={model.searchTerm}
					onChange={(e) => model.setSearchTerm(e.target.value)}
					inputStyle={{ backgroundColor: 'transparent' }}
				/>
			</div>
			<div className={cx('idea-status-subtitle')} onClick={model.toggleShowStarred} style={{ marginTop: '-0.5em' }}>
				<SVG className="mr-1" name={model.showStarred ? 'expand_less' : 'expand_more'} size={20} />
				Starred
			</div>
			<div style={{ overflowY:'auto', paddingTop: '0.5em' }}>
				<List style={{ minWidth: 580 }} rows={model.ideasList(model.starredIdeas)} className={cx('ideas-list', { 'ideas-list-hidden': !model.showStarred })} />
			</div>
			<div className={cx('idea-status-subtitle')} onClick={model.toggleShowIdeas}>
				<SVG className="mr-1" name={model.showIdeas ? 'expand_less' : 'expand_more'} size={20} />
				Everything else
			</div>
			<div style={{ overflowY:'auto', paddingTop: '0.5em' }}>
				<List style={{ minWidth: 580 }} rows={model.ideasList(model.unStarredIdeas)} className={cx('ideas-list',  { 'ideas-list-hidden': !model.showIdeas })} />
			</div>
			{model.visibleCreateIdeaModal && <CreateIdeaModal
				onOk={model.onIdeaCreation}
				onCancel={model.hideCreateIdeaModal}
				visible={model.visibleCreateIdeaModal}
			/>}
			<Info infoKey="ideas"/>
		</Container>
	);
});

export default ModelConnector(Ideas, IdeasModel);

import React, { Component } from 'react';
import { Observer } from 'mobx-react-lite';

import classNames from 'classnames';
import { Button } from 'components';
import SVG from 'components/svg/svg';
import { Link } from 'react-router-dom';

export default class LinkedIdeas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showOptions: false,
    };
  }

	showChild = () => {
    this.setState((prevState) => ({
      showOptions: !prevState.showOptions,
    }));
  };

  hideChild = () => {
    this.setState({ showOptions: false });
  };

	render() {
		const { className, ideas, onSelect, onAddLinkedIdea, onUnlink } = this.props;

		const whenSelected = (idea) => {
			if (!onSelect) return;
			onSelect(idea);
		}

		return (
			<Observer>
				{() => (
					<div className={classNames('tejik-linked-ideas', className)}>
						{Array.from(ideas).map((idea) => (
							onSelect ?
							<div key={idea.id} className={classNames('tejik-linked-idea', { 'selectable': onAddLinkedIdea })} onClick={() => whenSelected(idea)}>
								{idea.title}
								{onSelect && <SVG name="add" style={{ cursor: 'pointer' }}/>}
							</div>
							:
							<div className={classNames('tejik-linked-idea', { 'selectable': onAddLinkedIdea })}
								onMouseEnter={this.showChild}
								onMouseLeave={this.hideChild}
							>
								<div>
									<div>{idea.title}</div>
									{this.state.showOptions &&
										<div className='unlink-button'>
											<Link key={idea.id} to={`/ideas/${idea.id}`} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
												<SVG name="open_in_new" style={{ marginRight: '0.5em'}}/>
												<span>Open</span>
											</Link>
											<span>|</span>
											<div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => onUnlink(idea)}>
												<SVG name="link_off" style={{ marginRight: '0.5em'}}/>
												<span>Unlink</span>
											</div>
										</div>
									}
								</div>
							</div>
						))}
						{onAddLinkedIdea && <Button onClick={onAddLinkedIdea} type="text" icon="add_box" className="tejik-linked-ideas-new-button"/>}
					</div>
				)}
			</Observer>
		);
	}
}
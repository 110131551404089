import { observer } from 'mobx-react-lite';
import { rootStore } from 'stores';
import { useEffect } from 'react';
import { Popover, Menu, UserImage, Modal, Title, Button } from 'components';
import CreateIdeaModal from '../../../modals/createIdea/createIdea';
import SVG from 'components/svg/svg';

import classNames from 'classnames/bind';
import styles from './topbar.scss';
import { Link } from 'react-router-dom';
import { OrganizationRoleMap } from 'utils/utils';

const cx = classNames.bind(styles);

const Topbar = observer(() => {
	const email = rootStore.AuthStore.user.email;

	const userMenuItems = [{
		className: 'text-neutral-2',
		text: email,
	}, {
		icon: 'manage_accounts',
		text: 'Account Settings',
		to: '/account-settings',
		exact: true,
	}, {
		icon: 'switch_account',
		text: 'Switch organization',
		action: () => rootStore.AuthStore.setChangeOrganizationsModal(true),
	}, {
		icon: 'logout',
		text: 'Log Out',
		to: '/logout',
	}, {
		text: <div style={{ fontSize: '11px' }}>
			<a href="https://tejik.com/privacy-policy/" target="_blank" rel="noreferrer" style={{ marginRight: '1em' }}>Privacy Policy</a> |
			<a href="https://tejik.com/terms/" target="_blank" rel="noreferrer" style={{ marginLeft: '1em' }}>Terms of Service</a>
		</div>,
		footer: true,
	}];

	useEffect(() => {
		const eventHandler = (e) => {
			if (e.detail === 'MENU') {
				rootStore.AuthStore.closeSidebar();
			}
		};

		document.addEventListener('force-close', eventHandler);
		
		return () => {
			document.removeEventListener('force-close', eventHandler);
		}
	}, []);

	return (
		<div className={cx('topbar')}>
			<div className="show-desktop-only" style={{ width: 250 }}></div>
			{rootStore.AuthStore.currentCampaignPlatform &&
				<div className={classNames(cx('show-desktop-only', 'campaign-platform-path'))}>
				<div>
					<Link to='../campaigns' style={{ textDecoration: 'none' }}>{'Campaign / '}</Link>
					{rootStore.AuthStore.currentCampaignPlatform}
				</div></div>
			}
			<Button className="show-mobile-only" iconSize={30} type="text" icon="menu" onClick={rootStore.AuthStore.toggleShowSidebar} style={{ marginTop: -8 }}/>
			{!rootStore.AuthStore.isLatestVersion && <div className={cx('update-available')}>New version is available, please refresh your browser</div>}
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				{rootStore.WorkspaceStore.visibleNewIdea && 
				<Button icon="add" id="main-create-idea-button" type="header" onClick={rootStore.WorkspaceStore.showCreateIdeaModal}>
					New Idea
				</Button>
				}
				<Link to="../help" key="help-top-button">
					<SVG className='help-top-button' name="question_mark" size={12} />
				</Link>
				<Popover content={<Menu items={userMenuItems} />}>
					<UserImage user={rootStore.AuthStore.user} />
				</Popover>
			</div>
			<Modal
				visible={rootStore.AuthStore.changeOrganizationsModal}
				closable
				onOk={() => rootStore.AuthStore.setChangeOrganizationsModal(false)}
				onCancel={() => rootStore.AuthStore.setChangeOrganizationsModal(false)}
				title="Switch organization"
				titleSize={5}
				footer={[]}
			>
				<div className={cx('organizations-list')}>
					{rootStore.AuthStore.organizations.map((organization) => (
						<div
							key={organization.id}
							onClick={() => rootStore.AuthStore.selectCurrentOrganization(organization.id)}
							className={cx('organization-item', { active: rootStore.AuthStore.current_organization?.id === organization.id })}
						>
							<div>
								<Title size={6}>{organization.name}</Title>
								<span>{OrganizationRoleMap.get(organization.role)}</span>
							</div>
							<div className={cx('organization-item-actions')}>
								{rootStore.AuthStore.current_organization?.id === organization.id && <SVG name="check" size={24} />}
								{organization.role === 'OWNER' && <Link to={`/organization-settings/${organization.id}`} onClick={(e) => {
									e.stopPropagation();
									rootStore.AuthStore.setChangeOrganizationsModal(false)
								}}>
									<Button type="text" icon="settings" />
								</Link>}
							</div>
						</div>
					))}
				</div>
			</Modal>
			<CreateIdeaModal
				onOk={rootStore.WorkspaceStore.onNewIdea}
				onCancel={rootStore.WorkspaceStore.hideCreateIdeaModal}
				visible={rootStore.WorkspaceStore.visibleCreateIdeaModal}
			/>
		</div>
	);
});

export default Topbar;

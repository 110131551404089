
import request from 'api/request';
import debounce from 'lodash.debounce';
import { Modal } from 'components';

import { PlatformEnum } from 'models/global/global.model';
import { flow, getParent, getSnapshot, types } from 'mobx-state-tree';
import { rootStore } from 'stores';

const { message } = Modal;
const { string, number, maybeNull, model } = types;

export const RecyclePlatformModel = model('RecyclePlatformModel', {
	id: string,
	workspace_id: string,
	platform: PlatformEnum,

	days_to_recycle: maybeNull(number),
	status_id: maybeNull(string),
}).views((self) => ({
	get baseURL() {
		return `${rootStore.WorkspaceStore.currentBaseURL}/settings/recycle-platforms/${self.id}`;
	},
	get parent() {
		return getParent(self, 2);
	},
	get statusOptions() {
		return rootStore.WorkspaceStore.current_workspace.statusOptions([self.platform]);
	},
	get status() {
		return self.statusOptions.find((status) => status.id === self.status_id);
	},
}))
.actions((self) => ({
	saveRecyclePlatform: debounce(() => self.updateRecyclePlatform(), 700),
	updateRecyclePlatform: flow(function* () {
		try {
			yield request.put(self.baseURL, getSnapshot(self));
			message({ type: 'success', text: 'Successfully updated recycle platform.' });
		} catch (err) {
		} finally {
		}
	}),
	setDaysToRecycle(days_to_recycle) {
		self.days_to_recycle = days_to_recycle;
		
		if (days_to_recycle === 0) {
			self.status_id = null;
		}

		self.saveRecyclePlatform();
	},
	setStatusId(status_id) {
		self.status_id = status_id;
		self.saveRecyclePlatform();
	},
}));

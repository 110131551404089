import request from 'api/request';
import { Modal } from 'components';
import debounce from 'lodash.debounce';
import { flow, getParent, getSnapshot, types } from 'mobx-state-tree';
import { PlatformEnum } from 'models/global/global.model';
import { IsSocialMediaPlatform } from 'utils/utils';

const { message } = Modal;
const { array, boolean, enumeration, model, identifier, union, optional, maybeNull, number, string } = types;

const SchedulingTargetScheduleModel = model('SchedulingTargetScheduleModel', {
	pillar: maybeNull(model('PillarScheduleModel', {
		sharing_frequency: maybeNull(number),
		sharing_consistency: maybeNull(enumeration('SharingConsistencyEnum', ['DAILY','WEEKLY', 'MONTHLY'])),
		days_of_week: array(enumeration('DaysOfWeekEnum', ['0', '1', '2', '3', '4', '5', '6'])),
		time_of_day: maybeNull(string), // HH:MM
	}).views((self) => ({
		get formattedTimeOfDay() {
			if (!self.time_of_day) return null;

			const [hours, minutes] = self.time_of_day.split(':');

			const time = new Date();
			time.setHours(hours);
			time.setMinutes(minutes);
			return time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
		}
	}))),
	supporting: maybeNull(model('SupportingScheduleModel', {
		immediate: maybeNull(boolean),
		number_of_days_after: maybeNull(number),
		time_of_day: maybeNull(string), // HH:MM
	}).views((self) => ({
		get showNumberOfDays() {
			return !self.immediate;
		}
	}))),
	social: maybeNull(model('SocialScheduleModel', {
		number_of_posts: maybeNull(number),
		spaced_number_of_days: maybeNull(number),
		time_of_day: maybeNull(string), // HH:MM
	}).views((self) => ({
		get showNumberOfDays() {
			return Number(self.number_of_posts) && self.number_of_posts > 0;
		}
	}))),
}).views((self) => ({
	get parent() {
		return getParent(self, 1);
	},
})).actions((self) => ({
	setPillarSchedule(data) {
		if (self.supporting) self.supporting = null;
		if (self.social) self.social = null;

		self.pillar = {...self.pillar, ...data};
		self.parent.saveSchedulingTarget();
	},
	setSupportingSchedule(data) {
		if (self.pillar) self.pillar = null;
		if (self.social) self.social = null;

		self.supporting = {...self.supporting, ...data};
		self.parent.saveSchedulingTarget();
	},
	setSocialSchedule(data) {
		if (self.pillar) self.pillar = null;
		if (self.supporting) self.supporting = null;

		self.social = {...self.social, ...data};
		self.parent.saveSchedulingTarget();
	},
}))

const SchedulingTargetModel = model('SchedulingTargetModel', {
	id: identifier,
	platform: union(PlatformEnum, enumeration('SOCIAL', ['SOCIAL'])),
	schedule: SchedulingTargetScheduleModel,
}).views((self) => ({
	get parent() {
		return getParent(self, 2);
	},
	get baseURL() {
		return `${self.parent.baseURL}/${self.id}`;
	},
})).actions((self) => ({
	saveSchedulingTarget: debounce(() => self.updateSchedulingTarget(), 700),
	updateSchedulingTarget: flow(function* () {
		try {
			yield request.put(self.baseURL, getSnapshot(self));
			message({ type: 'success', text: 'Successfully updated scheduling target.' });
		} catch (err) {
			console.error(err);
			throw err;
		} finally {
		}
	}),
}));

const SchedulingTargetsModel = model('SchedulingTargetsModel', {
	schedulingTargets: array(SchedulingTargetModel),
	isLoading: optional(boolean, true),
})
	.views((self) => ({
		get baseURL() {
			return `${self.WorkspaceStore.current_workspace.baseURL}/settings/scheduling-targets`;
		},
		get workspace() {
			return self.props.workspace;
		},
		get pillarSchedulingTarget() {
			return self.schedulingTargets.find((schedulingTarget) => (IsSocialMediaPlatform(self.workspace.primary_platform) && IsSocialMediaPlatform(schedulingTarget.platform)) || (schedulingTarget.platform === self.workspace.primary_platform));
		}
	}))
	.actions((self) => ({
		afterCreate() {
			self.fetchSchedulingTargets();
		},
		fetchSchedulingTargets: flow(function* () {
			try {
				const { data } = yield request.get(self.baseURL);
				self.schedulingTargets = data;

				for (const platform of self.workspace.platforms) {
					// Skip any that already exist
					if (self.schedulingTargets.find((target) => target.platform === platform)) continue;

					if (IsSocialMediaPlatform(platform)) {
						if (!self.schedulingTargets.find((target) => target.platform === 'SOCIAL')) {
							const newTarget = yield self.createSchedulingTarget('SOCIAL');
							self.schedulingTargets.push(newTarget)
						}
						
						continue;
					}

					const newTarget = yield self.createSchedulingTarget(platform);
					self.schedulingTargets.push(newTarget)
				}
			} catch (err) {
			} finally {
				self.isLoading = false;
			}
		}),

		createSchedulingTarget: flow(function* (platform) {
			const { data } = yield request.post(self.baseURL, { platform });
			return data;
		}),
		// SETTERS
	}));

export default SchedulingTargetsModel;

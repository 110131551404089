import { Button, Form, Input, Title } from 'components';
import { observer } from 'mobx-react-lite';
import { ModelConnector } from 'stores';
import JoinOrganizationModel from './join-organization.model';
import { TejikLogo } from 'components/svg/svg';

import classNames from 'classnames/bind';
import styles from './join-organization.scss';
const cx = classNames.bind(styles);

const JoinOrganization = observer(({ model }) => {
	return !model.isLoading && (

		<div className={cx('tejik-signup-container')}>
			<div className={cx('tejik-signup')}>
				{/* Logo */}
				<TejikLogo />
				{/* Title */}
				<Title size={4} weight={600}>Join the organization</Title>
				{/* Sign in form */}
				<Form>
					<Form.Row>
						<Form.Item>
							<Input
								placeholder="First Name (required)"
								value={model.first_name}
								onChange={(e) => model.setFirstName(e.target.value)}
								autoComplete="given-name"
								type="text"
							/>
						</Form.Item>
						<Form.Item>
							<Input
								placeholder="Last Name (required)"
								value={model.last_name}
								onChange={(e) => model.setLastName(e.target.value)}
								autoComplete="family-name"
								type="text"
							/>
						</Form.Item>
					</Form.Row>
					<Form.Item>
						<Input
							disabled
							value={model.email}
							type="text" />
					</Form.Item>
					<Form.Item>
						<Input
							placeholder="Password (required)"
							value={model.password}
							onChange={(e) => model.setPassword(e.target.value)}
							autoComplete="new-password"
							type="password" />
					</Form.Item>
					<Form.Item>
						<Button onClick={model.joinOrganization}>Join now</Button>
					</Form.Item>
				</Form>
				<p className={cx('tejik-terms-and-conditions')}>
					By signing up, you agree to <a href="/">Tejik's terms</a><br />and acknowledge you've read our <a href="/">privacy policy</a>
				</p>
			</div>
		</div>
	);
});

export default ModelConnector(JoinOrganization, JoinOrganizationModel);

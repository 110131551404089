import request from 'api/request';
import debounce from 'lodash.debounce';
import { flow, types } from 'mobx-state-tree';
import { CharacterLimitModel } from 'models/character-limits.model';
import { PlatformOrderList } from 'utils/utils';

import { Modal } from 'components';
const { message } = Modal;

const { boolean, map, model, optional } = types;

const CharacterLimitsModel = model('CharacterLimitsModel', {
	character_limits: map(CharacterLimitModel),
	isLoading: optional(boolean, true),
})
	.views((self) => ({
		get baseURL() {
			return `${self.workspace.baseURL}/settings/character-limits`;
		},
		get workspace() {
			return self.props.workspace;
		},
		get platformsData() {
			const platformsSorted = [...self.workspace.platforms].sort((a, b) => {
				const indexA = PlatformOrderList.findIndex(type => a === type);
				const indexB = PlatformOrderList.findIndex(type => b === type);
				return indexA - indexB;
			});

			if (self.workspace?.primary_platform) {
				const index = platformsSorted.findIndex(p => p === self.workspace.primary_platform);
				platformsSorted.unshift(platformsSorted.splice(index, 1)[0]);
			}

			return platformsSorted.map((platform) => ({
				id: platform,
				platform,
				title: self.character_limits.get(platform)?.title || '',
				setTitle: self.character_limits.get(platform)?.setTitle,
				description: self.character_limits.get(platform)?.description || '',
				setDescription: self.character_limits.get(platform)?.setDescription,
			}));
		},
	}))
	.actions((self) => ({
		afterCreate() {
			self.fetchCharacterLimits();
		},
		fetchCharacterLimits: flow(function* () {
			try {
				const { data } = yield request.get(self.baseURL);

				for (const d of data) {
					self.character_limits.set(d.platform, d);
				}

				for (const platform of self.workspace.platforms) {
					if (!self.character_limits.get(platform)) {
						self.character_limits.set(platform, { id: platform, workspace_id: self.workspace.id, platform });
					}
				}
			} catch (err) {
				console.error(err);
				throw err;
			} finally {
				self.isLoading = false;
			}
		}),
		debouncedSave: debounce(() => self.saveCharacterLimits(), 1000),
		saveCharacterLimits: flow(function* () {
			try {
				yield request.put(self.baseURL, { character_limits: Array.from(self.character_limits.values())});
				message({ type: 'success', text: 'Successfully saved character limits.' })

			} catch (err) {
				throw err;
			}
		}),
	}));

export default CharacterLimitsModel;

import classNames from 'classnames';
import React, { Component } from 'react';

const FacebookSVG = (props) => (
	<svg className={props.className} width={props.width || '1em'} height={props.height || '1em'} viewBox="-3 -3 36 36" fill="none" style={props.style}>
		<path d="M30 15.091C30 6.756 23.285 0 15 0S0 6.756 0 15.091C0 22.625 5.484 28.868 12.656 30V19.454H8.848V15.09h3.808v-3.324c0-3.782 2.239-5.872 5.666-5.872 1.64 0 3.358.295 3.358.295v3.714h-1.893c-1.863 0-2.443 1.164-2.443 2.358v2.83h4.16l-.665 4.362h-3.495V30C24.516 28.868 30 22.625 30 15.091z" fill="currentColor"></path>
	</svg>);

const InstagramSVG = (props) => (
	<svg className={props.className} width={props.width || '1em'} height={props.height || '1em'} viewBox="-3 -3 36 36" fill="none" style={props.style}>
		<path fillRule="evenodd" clipRule="evenodd" d="M15 0c-4.074 0-4.584.017-6.184.09-1.597.073-2.688.327-3.642.698-1 .376-1.907.966-2.657 1.73a7.358 7.358 0 00-1.73 2.656C.418 6.128.163 7.22.09 8.817.018 10.416 0 10.925 0 15c0 4.075.017 4.584.09 6.184.073 1.597.327 2.688.698 3.642.376 1 .966 1.907 1.73 2.657a7.359 7.359 0 002.656 1.73c.954.37 2.045.624 3.642.697 1.6.073 2.11.09 6.184.09s4.584-.017 6.184-.09c1.597-.073 2.688-.327 3.642-.698 1-.376 1.907-.966 2.657-1.73a7.358 7.358 0 001.73-2.656c.37-.954.624-2.045.697-3.642.073-1.6.09-2.11.09-6.184s-.017-4.584-.09-6.184c-.073-1.597-.327-2.688-.698-3.642a7.353 7.353 0 00-1.73-2.657 7.358 7.358 0 00-2.656-1.73C23.872.418 22.78.163 21.183.09 19.584.018 19.075 0 15 0zm0 2.703c4.005 0 4.48.015 6.062.087 1.462.067 2.256.31 2.785.517.7.271 1.2.597 1.725 1.121.525.525.85 1.025 1.121 1.725.206.529.45 1.323.517 2.785.072 1.582.087 2.057.087 6.062 0 4.005-.015 4.48-.087 6.062-.067 1.462-.31 2.256-.517 2.785a4.646 4.646 0 01-1.121 1.725 4.642 4.642 0 01-1.725 1.121c-.529.206-1.323.45-2.785.517-1.582.072-2.056.087-6.062.087s-4.48-.015-6.062-.087c-1.462-.067-2.256-.31-2.785-.517a4.646 4.646 0 01-1.725-1.121 4.648 4.648 0 01-1.121-1.725c-.206-.529-.45-1.323-.517-2.785-.072-1.582-.087-2.057-.087-6.062 0-4.005.015-4.48.087-6.062.067-1.462.31-2.256.517-2.785.271-.7.597-1.2 1.121-1.725a4.643 4.643 0 011.725-1.121c.529-.206 1.323-.45 2.785-.517 1.582-.072 2.057-.087 6.062-.087z" fill="currentColor"></path>
		<path fillRule="evenodd" clipRule="evenodd" d="M15 20.005a5.005 5.005 0 110-10.01 5.005 5.005 0 010 10.01zM15 7.29a7.71 7.71 0 100 15.42 7.71 7.71 0 000-15.42zm9.953-.14a1.822 1.822 0 11-3.645 0 1.822 1.822 0 013.645 0" fill="currentColor"></path>
	</svg>
)

const LinkedInSVG = (props) => (
	<svg className={props.className} width={props.width || '1em'} height={props.height || '1em'} viewBox="-25 -25 306 306" xmlns="http://www.w3.org/2000/svg" style={props.style}>
		<g fill="none">
			<path d="M0 18.338C0 8.216 8.474 0 18.92 0h218.16C247.53 0 256 8.216 256 18.338v219.327C256 247.79 247.53 256 237.08 256H18.92C8.475 256 0 247.791 0 237.668V18.335z" fill="currentColor" />
			<path d="M77.796 214.238V98.986H39.488v115.252H77.8zM58.65 83.253c13.356 0 21.671-8.85 21.671-19.91-.25-11.312-8.315-19.915-21.417-19.915-13.111 0-21.674 8.603-21.674 19.914 0 11.06 8.312 19.91 21.169 19.91h.248zM99 214.238h38.305v-64.355c0-3.44.25-6.889 1.262-9.346 2.768-6.885 9.071-14.012 19.656-14.012 13.858 0 19.405 10.568 19.405 26.063v61.65h38.304v-66.082c0-35.399-18.896-51.872-44.099-51.872-20.663 0-29.738 11.549-34.78 19.415h.255V98.99H99.002c.5 10.812-.003 115.252-.003 115.252z" fill="#ffffff" />
		</g>
	</svg>
);

const PinterestSVG = (props) => (
	<svg className={props.className} fill="currentColor" height={props.height || '1em'} width={props.width || '1em'} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="-291 253 296 296" style={props.style}>
		<path d="M-143,273c-70.7,0-128,57.3-128,128c0,52.4,31.5,97.4,76.6,117.2c-0.4-8.9-0.1-19.7,2.2-29.4c2.5-10.4,16.5-69.7,16.5-69.7
		s-4.1-8.2-4.1-20.2c0-19,11-33.1,24.7-33.1c11.6,0,17.3,8.7,17.3,19.2c0,11.7-7.5,29.2-11.3,45.4c-3.2,13.6,6.8,24.6,20.2,24.6
		c24.3,0,40.6-31.1,40.6-68c0-28-18.9-49-53.3-49c-38.8,0-63,28.9-63,61.3c0,11.2,3.3,19,8.4,25.1c2.4,2.8,2.7,3.9,1.8,7.1
		c-0.6,2.3-2,8-2.6,10.3c-0.9,3.2-3.5,4.4-6.4,3.2c-17.9-7.3-26.2-26.9-26.2-48.9c0-36.4,30.7-80,91.5-80c48.9,0,81,35.4,81,73.3
		c0,50.2-27.9,87.7-69.1,87.7c-13.8,0-26.8-7.5-31.3-15.9c0,0-7.4,29.5-9,35.2c-2.7,9.9-8,19.7-12.9,27.4c11.5,3.4,23.7,5.3,36.3,5.3
		c70.7,0,128-57.3,128-128C-15,330.3-72.3,273-143,273z"/>
	</svg>

);

const TwitterSVG = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" className={props.className} width={props.width || '1em'} height={props.height || '1em'} viewBox="-300 -200 1500 1477" fill="none" style={props.style}>
		<path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="currentColor" />
	</svg>
);

const YoutubeSVG = (props) => (
	<svg className={props.className} width={props.width || '1em'} height={props.height || '1em'} viewBox="0 0 192 192" xmlns="http://www.w3.org/2000/svg" style={props.style}>
		<path d="m180.32 53.36c-2.02-7.62-7.99-13.62-15.56-15.66-13.72-3.7-68.76-3.7-68.76-3.7s-55.04 0-68.76 3.7c-7.57 2.04-13.54 8.04-15.56 15.66-3.68 13.82-3.68 42.64-3.68 42.64s0 28.82 3.68 42.64c2.02 7.62 7.99 13.62 15.56 15.66 13.72 3.7 68.76 3.7 68.76 3.7s55.04 0 68.76-3.7c7.57-2.04 13.54-8.04 15.56-15.66 3.68-13.82 3.68-42.64 3.68-42.64s0-28.82-3.68-42.64zm-102.32 68.81v-52.34l46 26.17z" fill="currentColor" />
	</svg>
);

export const TejikLogo = () => (
	<svg id="tejik-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 20 340.65 170.23">
		<defs>
			{/* <style>.cls-1{fill:url(#New_Gradient_Swatch_1);}</style> */}
			<linearGradient id="New_Gradient_Swatch_1" x1="0" y1="105.96" x2="325.01" y2="105.96" gradientUnits="userSpaceOnUse">
				<stop offset=".08" stopColor="#355070" />
				<stop offset=".29" stopColor="#6e5a7a" />
				<stop offset=".54" stopColor="#b56576" />
				<stop offset=".74" stopColor="#e56a6f" />
				<stop offset="1" stopColor="#eaac8b" />
			</linearGradient>
		</defs>
		<path style={{ fill: "url(#New_Gradient_Swatch_1)" }} d="M220.33,146.49l24.32-.02V46.99l-24.32,8.13v91.38Zm104.68-72.12l-11.45-22.95-28.9,28.9,.05-60.47-24.35,7.39-.08,119.76h24.32l.02-28.79,28.94,28.94,11.45-22.95-24.92-24.92,24.92-24.92ZM123.76,122.48c-9.45,1.23-16.58-9.37-16.58-21.8l58.4,.56c0-26.06-18.72-47.19-41.82-47.19s-41.82,21.13-41.82,47.19,18.72,47.19,41.82,47.19c19.06,0,35.15-14.4,40.18-34.08l-24.79-7.43c-.88,8.27-5.93,14.32-15.39,15.56Zm-.46-46.5c5.44,0,9.57,2.41,12.26,6.4l-24.72-.1c2.85-3.92,7.09-6.3,12.46-6.3Zm56.04-11.16l.25,74.57c.12,13.86-6.83,26.08-26.23,25.76v26.88c17.01,.68,49.93-9.26,51.52-53.75l-.27-81.61-25.27,8.15Zm-132.57,10.58v18.75c0,10.34,4.14,16.72,7.33,19.62,4.61,4.21,14.18,6.14,18.91,6.14v26.88c-12.09,.43-30.55-6.65-38.29-16.49-10.36-13.16-13.32-24.71-13.24-37.27l-.25-17.33L0,75.98l8.13-24.32,12.76-.05-.34-23.38,26.47-8.04v31.2h26v23.7l-26.23,.32Z" />
	</svg>
);

const RecycleSVG = (props) => (
	<svg className={props.className} viewBox="0 0 32 32" width={props.width || '1em'} height={props.height || '1em'} xmlns="http://www.w3.org/2000/svg">
		<g fill="none" fill-rule="evenodd" transform="translate(4 4)">
			<path d="m0 0h24v24h-24z" transform="matrix(1 0 0 -1 0 24)" />
			<path d="m18.4 4.6c-1.85-1.61-4.25-2.6-6.9-2.6-4.65 0-8.58 3.03-9.96 7.22l2.36.78c1.05-3.19 4.05-5.5 7.6-5.5 1.95 0 3.73.72 5.12 1.88l-3.62 3.62h9v-9zm-6.9 18.4c-2.65 0-5.05-.99-6.9-2.6l-3.6 3.6v-9h9l-3.62 3.62c1.39 1.16 3.16 1.88 5.12 1.88 3.54 0 6.55-2.31 7.6-5.5l2.37.78c-1.39 4.19-5.32 7.22-9.97 7.22z" fill="#000" fillRule="nonzero" transform="matrix(1 0 0 -1 0 25)" />
		</g>
	</svg>
);

const SideMenuSVG = (props) => (
	<svg className={props.className} width={props.width || '1em'} height={props.height || '1em'}  viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M14 2C14.5523 2 15 2.44772 15 3V13C15 13.5523 14.5523 14 14 14H2C1.44772 14 1 13.5523 1 13V3C1 2.44772 1.44772 2 2 2H14ZM2 1C0.895431 1 0 1.89543 0 3V13C0 14.1046 0.895431 15 2 15H14C15.1046 15 16 14.1046 16 13V3C16 1.89543 15.1046 1 14 1H2Z" fill="black"/>
		<path d="M3 4C3 3.44772 3.44772 3 4 3H6C6.55228 3 7 3.44772 7 4V12C7 12.5523 6.55228 13 6 13H4C3.44772 13 3 12.5523 3 12V4Z" fill="black"/>
	</svg>
);

const GetSVG = (name, className, size, style) => ({
	'facebook': <FacebookSVG className={className} height={size} width={size} style={style} />,
	'instagram': <InstagramSVG className={className} height={size} width={size} style={style} />,
	'linkedin': <LinkedInSVG className={className} height={size} width={size} style={style} />,
	'pinterest': <PinterestSVG className={className} height={size} width={size} style={style} />,
	'twitter': <TwitterSVG className={className} height={size} width={size} style={style} />,
	'youtube': <YoutubeSVG className={className} height={size} width={size} style={style} />,
	'recycle': <RecycleSVG className={className} height={size} width={size} style={style} />,
	'sidepanel': <SideMenuSVG className={className} height={size} width={size} style={style} />,
}[name]);

export default class SVG extends Component {
	render() {
		const { className, name, onClick, size, style } = this.props;
		const PREDEFINED_SVGS = ['facebook', 'instagram', 'linkedin', 'pinterest', 'twitter', 'youtube', 'recycle', 'sidepanel'];

		return (
			PREDEFINED_SVGS.includes(name) ? GetSVG(name, className, size, style) : <span className={classNames('material-icons-outlined', className)} style={{...style, fontSize: size }} onClick={onClick}>
				{name}
			</span>
		);
	}
}
import {useNavigate} from "react-router-dom";
import SVG from "../../../../components/svg/svg";
import {PlatformIconMap, PlatformOrderList, IsSocialMediaPlatform} from "../../../../utils/utils";
import {rootStore} from 'stores';
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./calendar.scss";
import {useMemo} from "react";

const cx = classNames.bind(styles);

const EventLink = (props) => {
  const {event, footer} = props;
  const navigate = useNavigate();

  const statusColor = useMemo(() => {
    const platforms = event.platforms.filter((platform) => platform.statusColor)
    if (platforms.length === 0) {
      return '#D9D9D9';
    }
    return platforms[0].statusColor ?? '#D9D9D9';
  }, [event])

  const sorted_platforms = useMemo(() => {
    const platformsSorted = event.platforms.slice().sort((a, b) => {
      const indexA = PlatformOrderList.findIndex(type => a.platform === type);
      const indexB = PlatformOrderList.findIndex(type => b.platform === type);
      return indexA - indexB;
    });

    if (rootStore.WorkspaceStore.current_workspace.primary_platform) {
      const index = platformsSorted.findIndex(p => p.platform === rootStore.WorkspaceStore.current_workspace.primary_platform);
      platformsSorted.unshift(platformsSorted.splice(index, 1)[0]);
    }
        
    return platformsSorted;
  }, [event])

  const handleClick = () => {
    navigate(event.event_link, { state: { platform: IsSocialMediaPlatform(sorted_platforms[0].platform) ? 'SOCIAL' : sorted_platforms[0].platform }});
  }

  return (<div className={cx({'event-item-container': !footer}, {'footer-item-container': footer})}>
    <div
      className={cx({'event-item': !footer}, {'footer-item': footer})}
      onClick={handleClick}
	  	style={{ backgroundColor: statusColor + '40', borderColor: statusColor}}
    >
      <div key={event.id}
        onClick={handleClick}
        className={cx({'event-link': !footer})}
      >
        <div>
          <p>{event.name}</p>
          <div>
            {sorted_platforms.map((item, key) => <SVG key={key} name={PlatformIconMap.get(item.platform)} style={{ marginRight: 4}}/>)}
          </div>
        </div>
      </div>
    </div>
  </div>)
};

EventLink.propType = {
  event: PropTypes.object
}

export default EventLink
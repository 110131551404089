import { useEffect } from "react";
import { ModelConnector, rootStore } from 'stores';
import { observer } from "mobx-react-lite";
import { Button, Container, Form, Input, List, Title } from "components";
import { Workspace } from "views/app/organization-settings/workspaces/workspace/workspace.view";

import NewOrganizationModel from "./new-organization.model";

// import classNames from 'classnames/bind';
// import styles from './app.scss';

// const cx = classNames.bind(styles);
const WelcomePage = observer(({ model }) => (
	<>
		<p className="mb-2 text-neutral-5">
			<i>It seems that you are not part of any organization. Let's fix that with one of the options below!</i>
		</p>

		<div className="steps-container">
			<Title size={3}>1</Title>
			<div className="step">
				<p>Subcribe to Tejik’s services to create your own organization.</p>
				<Button onClick={() => model.setState('create')}>
					Create Organization
				</Button>
			</div>

			<Title size={3}>2</Title>
			<div className="step">
				<p>To get started, please request your Admin of the organization to invite you to a workspace using the same email you used to set up your Tejik account.</p>
			</div>
		</div>
	</>
));

const CreateOrganization = observer(({ model }) => (
	<Container noPadding>
		<p className="mb-1 text-neutral-5">
			<i>Let’s get started with your organization!</i>
		</p>
		<Container.Column span={8}>
			<iframe width="560" height="315" src="https://www.youtube.com/embed/K317cyhMkIU?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</Container.Column>
		<Container.Column span={4}>
			<p className="mb-1 text-neutral-5">
				<i>Organizations serve as the framework where users and workspaces thrive. As a user, you can be part of one or multiple organizations.</i>
			</p>
			<Form onSubmit={model.onSubmitOrganizationName}>
				<Form.Item label="Organization Name">
					<Input value={model.organization_name} onChange={(e) => model.setOrganizationName(e.target.value)} />
				</Form.Item>
				<Form.Item>
					<Button htmlType="submit">Create</Button>
				</Form.Item>
			</Form>
		</Container.Column>
	</Container>
));

const SetupBilling = observer(({ model }) => {
	useEffect(() => {
		const script = document.createElement('script');

		script.src = "https://js.stripe.com/v3/pricing-table.js";
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		}
	}, []);

	const currentTableId = process.env.NODE_ENV === 'development' ?
		'prctbl_1NONIIL6syencrF9mXqLfTPB' :
		'prctbl_1NSjTZL6syencrF95hIjLTEL';
	const currentKey = process.env.NODE_ENV === 'development' ?
		'pk_test_51MeR7NL6syencrF9XRobifehM4ZiXhG3rlQovkWd5hBw8pJSawq5gie9cTkuypofhlb87Y4eYyQPCEYS7AkvuIFd00jrueW1va' :
		'pk_live_51MeR7NL6syenc	rF9NHezN2OqXZsRILtHrzhplpZu8NVCEMKdKIXuYGKJ5iDLnLhBIbJ41PEmWUZOi9TXdImwT05B00IskYLZt9';

	const RenderFAQItem = ({ id }) => (
		<div style={{ lineHeight: 1.2 }}>
			{{
				'what-is-workspace': 'A Workspace is an organized group within your Tejik organization. It’s a place where you can coordinate different projects or teams. Depending on your subscription, you can have multiple Workspaces, each with its own settings, campaigns, and users.',
				'what-is-campaign': 'A Campaign on Tejik is a space where you arrange and plan your content for various platforms. It starts when you’ve brainstormed an idea and encompasses all the associated platforms.',
				'what-is-guest': 'A Guest is a user within your Tejik organization who has limited permissions. They can add content but cannot create new ideas, start campaigns, or change any settings.',
				'what-is-member': 'A Member is a user within your Tejik organization who can add ideas, campaigns, and content but cannot change any settings.',
				'annual-plans': 'When you sign up for any of our paid annual plans, you can save money per year compared to our monthly plans. Annual plans are billed yearly as a one-time payment and will automatically renew unless canceled.',
				'free-trial': 'Absolutely! You can try an of our paid plans 100% free for 30 days. You won’t be charged for the duration of your 30-day free trial, unless you decide to continue on with your plan once your trial is over. Cancel anytime before your free trial ends to avoid being changed.',
				'switch-plans': 'Definitely! You can switch between plans at any time from your billing portal within Tejik.',
				'cancel-subscription': 'You can cancel your subscription at any time. Please note that if you cancel your subscription, you will not receive a refund or credit for any amounts that have already been billed.',
				'exceed-limits': <><p>If you exceed your plan’s usage limits for campaigns, you won’t be able to add more content to campaigns until your monthly allowance renews or until you upgrade to a plan with higher limits.</p>
					<p>If you exceed your plan’s usage limits for users or workspaces, you will not be able to add more until you either remove some of the existing users or workspaces to bring you back within your plan’s limits, or upgrade to a plan that offers higher limits.</p>
				</>,
			}[id]}
		</div>
	);

	return (<>
		<stripe-pricing-table
			pricing-table-id={currentTableId}
			publishable-key={currentKey}
			customer-email={rootStore.AuthStore.user.email}
			client-reference-id={rootStore.AuthStore.current_organization.id}
		>
		</stripe-pricing-table>
		<Title size={4} className="mb-1">
			Frequently Asked Questions
		</Title>
		<List
			rows={[
				{
					id: 'what-is-workspace',
					title: 'What is a Workspace?',
					expandedView: <RenderFAQItem id={'what-is-workspace'} />
				},
				{
					id: 'what-is-campaign',
					title: 'What is a Campaign?',
					expandedView: <RenderFAQItem id={'what-is-campaign'} />
				},
				{
					id: 'what-is-guest',
					title: 'What is a Guest?',
					expandedView: <RenderFAQItem id={'what-is-guest'} />
				},
				{
					id: 'what-is-member',
					title: 'What is a Member?',
					expandedView: <RenderFAQItem id={'what-is-member'} />
				},
				{
					id: 'annual-plans',
					title: 'How do the annual plans work?',
					expandedView: <RenderFAQItem id={'annual-plans'} />
				},
				{
					id: 'free-trial',
					title: 'Is there a free trial available?',
					expandedView: <RenderFAQItem id={'free-trial'} />
				},
				{
					id: 'switch-plans',
					title: 'Can I switch between plans?',
					expandedView: <RenderFAQItem id={'switch-plans'} />
				},
				{
					id: 'cancel-subscription',
					title: 'What is the process for canceling a subscription?',
					expandedView: <RenderFAQItem id={'cancel-subscription'} />
				},
				{
					id: 'exceed-limits',
					title: 'What happens if I exceed my plan’s usage limits?',
					expandedView: <RenderFAQItem id={'exceed-limits'} />
				},
			]}
		/>
	</>
	);
});

const SetupWorkspace = observer(({ model }) => (
	<Container noPadding>
		<Container.Column span={6}>
			<p className="text-neutral-5">
				<i>Let’s customize your experience by setting up your first workspace.</i>
			</p>
			<Workspace preventFetch onOk={model.onCreateWorkspace} onCancel={() => { }} />
		</Container.Column>
	</Container>
));

const NotAnOwner = observer(({ model }) => (
	<>
		<p className="mb-2 text-neutral-5">
			<i>It seems that you are not part of any workspaces yet or subscribed to our services. Let’s fix that!</i>
		</p>

		<div className="steps-container">
			<Title size={3}>1</Title>
			<div className="step">
				<p>To get started, sign up for your 30-day trial.</p>
				<Button onClick={() => model.setState('create')}>
					Subscribe
				</Button>
			</div>

			<Title size={3}>2</Title>
			<div className="step">
				<p>To get started, please request your Admin to invite you to a workspace using the same email you used to set up your Tejik account.</p>
			</div>
		</div>
	</>
));

const WorkspaceTutorial = observer(({ model }) => (
	<Container noPadding>
		<p className="mb-2 text-neutral-5">
			<i>Congrats on a new workspace!</i>
		</p>
		<Container.Column span={8}>
			<iframe width="560" height="315" src="https://www.youtube.com/embed/FA6UXMd63_Q?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</Container.Column>
		<Container.Column span={4}>
			<p className="mb-1 text-neutral-5">
				<i>Before you can create projects in our workspace, it is essential to configure the workspace settings. This ensures proper system functionality and optimizes your workflow. Select the settings that best fit your needs and objectives.</i>
			</p>
			<Button onClick={model.onFinishWorkspace}>Next</Button>
		</Container.Column>
	</Container>
));

const NewOrganization = observer(({ model }) => {
	return (
		<Container>
			<Container.Column span={1} />
			<Container.Column span={10}>
				<div style={{
					backgroundColor: '#DDDDDD',
					height: '4px',
					width: '200px',
					marginBottom: '1em',
				}}>
					<div style={{
						backgroundColor: '#000000',
						width: model.progress,
						height: '100%',
					}} />
				</div>
				<Title size={2} className="mb-1">Welcome to Tejik 👋</Title>
				{model.state === 'welcome' && <WelcomePage model={model} />}
				{model.state === 'create' && <CreateOrganization model={model} />}
				{model.state === 'billing' && <SetupBilling model={model} />}
				{model.state === 'workspace' && <SetupWorkspace model={model} />}
				{model.state === 'workspace-tutorial' && <WorkspaceTutorial model={model} />}
				{model.state === 'not-owner' && <NotAnOwner model={model} />}
			</Container.Column>
			<Container.Column span={1} />
		</Container>
	);
});

export default ModelConnector(NewOrganization, NewOrganizationModel);

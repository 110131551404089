import { observer } from 'mobx-react-lite';
import { ModelConnector } from 'stores';
import { Button, Form, Input, Title } from 'components';
import { Link } from 'react-router-dom';
import { TejikLogo } from 'components/svg/svg';
import LoginModel from './login.model';

import classNames from 'classnames/bind';
import styles from './login.scss';

const cx = classNames.bind(styles);
const Login = observer(({ model }) => {
	return (
		<div className={cx('tejik-login-container')}>
			<div className={cx('tejik-login')}>
				{/* Logo */}
				<TejikLogo />
				{/* Title */}
				<Title size={4} weight={600}>Sign in to your account</Title>
				<p className={cx('tejik-login-text')}>Or <Link to="/sign-up">get started for free</Link></p>
				{/* Sign in form */}
				<Form onSubmit={model.signIn} style={{ paddingRight: 0, paddingLeft: 0 }}>
					<Form.Item>
						<Input
							value={model.email}
							onChange={(e) => model.setEmail(e.target.value)}
							type="text"
							placeholder="email"
							autoComplete="email"
						/>
					</Form.Item>
					<Form.Item>
						<Input
							value={model.password}
							onChange={(e) => model.setPassword(e.target.value)}
							type="password"
							placeholder="password"
							autoComplete="current-password"
						/>
					</Form.Item>
					<Form.Item>
						<Button htmlType="submit" type="secondary" size="large" style={{ width: '100%', padding: 14, justifyContent: 'center', flex: 1 }}>Sign In</Button>
					</Form.Item>
				</Form>
				<div className={cx('tejik-forgot-password')}>
					<Link to="/forgot-password">Forgot your password?</Link>
				</div>
				<p className={cx('tejik-terms-and-conditions')}>
					By signing up, you agree to <a href="https://tejik.com/terms/" target="_blank">Tejik's terms</a><br />and acknowledge you've read our <a href="https://tejik.com/privacy-policy/" target="_blank">privacy policy</a>
				</p>
			</div>
		</div>
	);
});

export default ModelConnector(Login, LoginModel);

import request from 'api/request';
import { destroy, flow, getParent, getSnapshot, types } from 'mobx-state-tree';
import { Modal } from 'components';
import debounce from 'lodash.debounce';

const { message } = Modal;
const { array, boolean, model, optional, identifier, string, number } = types;


export const CTAModel = model('CTAModel', {
	id: identifier,
	name: string,
	order: number,
})
	.views((self) => ({
		get baseURL() {
			return `${self.parent.baseURL}/${self.id}`;
		},
		get parent() {
			return getParent(self, 2);
		},
	}))
	.actions((self) => ({
		saveCTA: debounce(() => self.updateCTA(), 700),
		deleteCTA: flow(function* () {
			try {
				yield request.delete(self.baseURL);
				self.parent.removeCTA(self);
				message({ type: 'success', text: 'Successfully deleted CTA.' })
			} catch (err) {
				throw err;
			} finally {
			}
		}),
		updateCTA: flow(function* (showSuccess = true) {
			try {
				yield request.put(self.baseURL, getSnapshot(self));

				if (showSuccess) {
					message({ type: 'success', text: 'Successfully updated CTA.' })
				}
			} catch (err) {
				console.log(err);
				throw err;
			} finally {
			}
		}),
		setName(name) {
			self.name = name;
			self.saveCTA();
		}
	}));

// Model
const CTAViewModel = model('CTAViewModel', {
	callToActions: array(CTAModel),
	isLoading: optional(boolean, true),
	searchTerm: optional(string, ''),
})
	.views((self) => ({
		get baseURL() {
			return `${self.props.workspace.baseURL}/settings/social/call-to-action`;
		},
		get orderedCallToActions() {
			return Array.from(self.callToActions).slice().sort((a, b) => a.order - b.order);
		},
	}))
	.actions((self) => ({
		afterCreate() {
			self.fetchCallToActions();
		},
		searchCallToActions: debounce(() => self.fetchCallToActions(), 700),
		fetchCallToActions: flow(function* () {
			try {
				const { data } = yield request.get(self.baseURL, {
					params: {
						search_term: self.searchTerm,
					}
				});
				self.callToActions = data;
			} catch (err) {
				console.log(err);
				throw err;
			} finally {
				self.isLoading = false;
			}
		}),
		addCTA: flow(function* (e, { name }) {
			try {
				self.isLoading = true;
				const maxOrder = Math.max(...self.callToActions.map(({ order }) => order), 0);
				const CTA = { name, order: maxOrder + 1 };

				const { data } = yield request.post(self.baseURL, CTA);
				self.callToActions = [...self.callToActions, data];
				message({ type: 'success', text: 'Successfully created CTA.' })
			} catch (err) {
				throw err;
			} finally {
				self.isLoading = false;
			}
		}),
		removeCTA(callToAction) {
			self.isLoading = true;
			destroy(callToAction);
			self.isLoading = false;
		},
		onDragEnd(result) {
			if (!result.destination) {
				return;
			}

			if (result.destination.index === result.source.index) {
				return;
			}

			const callToActionsToUpdate = [];

			if (result.source.index > result.destination.index) {
				// Coming from the bottom up
				const sourceCTA = self.orderedCallToActions.at(result.source.index);
				const destinationCTA = self.orderedCallToActions.at(result.destination.index);
				const temp = destinationCTA.order;
				for (const callToAction of self.orderedCallToActions) {
					if (callToAction.order >= destinationCTA.order && callToAction.order < sourceCTA.order) {
						callToAction.order = callToAction.order + 1;
						callToActionsToUpdate.push(callToAction);
					}
				}
				sourceCTA.order = temp;
				callToActionsToUpdate.push(sourceCTA);
			} else {
				// Moving from the top down
				const sourceCTA = self.orderedCallToActions.at(result.source.index);
				const destinationCTA = self.orderedCallToActions.at(result.destination.index);
				const temp = destinationCTA.order;
				for (const callToAction of self.orderedCallToActions) {
					if (callToAction.order <= destinationCTA.order && callToAction.order > sourceCTA.order) {
						callToAction.order = callToAction.order - 1;
						callToActionsToUpdate.push(callToAction);
					}
				}
				sourceCTA.order = temp;
				callToActionsToUpdate.push(sourceCTA);
			}

			for (const callToAction of callToActionsToUpdate) {
				callToAction.updateCTA(false);
			}
			message({ type: 'success', text: 'Successfully reorded CTAs.' })
		},
		setSearchTerm(searchTerm) {
			self.searchTerm = searchTerm;
			self.isLoading = true;
			self.searchCallToActions();
		},
	}));

export default CTAViewModel;

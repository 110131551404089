import { useState } from "react";
import { observer } from "mobx-react-lite";
import { 
	Button, 
	Container, 
	Form, 
	Input, 
	KeywordResearch, 
	Menu, 
	Modal, 
	Popover, 
	Select, 
	Title, 
	SVG,
	RichText,
	Toggle,
	StatusSelection,
	DatePicker
} from "components";

import classNames from 'classnames/bind';
import styles from './pinterest.scss';
import ContentLinks from "../../contentLinks";
import { rootStore } from "stores";

const cx = classNames.bind(styles);

const contentOptions = [
	{
		label: 'Image Pin',
		value: 'IMAGE',
	},
	{
		label: 'Video Pin',
		value: 'VIDEO',
	},
	{
		label: 'Static Pin',
		value: 'STATIC',
		hidden: true,
	},
	{
		label: 'Idea Pin',
		value: 'IDEA',
		hidden: true,
	},
];

const KeywordResearchModal = observer(({ additional_keywords, campaign_pinterest, onOk, onCancel, visible }) => (
	<Modal
		className={cx('new-pinterest-pin-modal')}
		width="80%"
		onOk={onOk}
		onCancel={onCancel}
		visible={visible}
		footer={[]}
		closable
		title="Add pinterest keywords"
		titleSize={5}
		titleSVGName="pinterest"
	>
		<KeywordResearch
			additional_keywords={additional_keywords}
			keyword_research={campaign_pinterest.keyword_research}
			title="Pinterest Keyword Research"
			platform="PINTEREST"
		/>
	</Modal>
));

const ConfigurePinterestPinModal = observer(({ additional_keywords, campaign_pinterest, pinterest_pin, onOk, onCancel, visible, addPinterestBoard }) => (
	<Modal
		className={cx('configure-pinterest-pin-modal')}
		width={window.innerWidth <= 790 ? '98%' : '70%'}
		onOk={onOk}
		onCancel={onCancel}
		visible={visible}
		footer={[]}
		closable
		overlayClosable
		title="Add pinterest keywords"
		titleSize={5}
		titleSVGName="pinterest"
	>
		<div className={cx('pinterest-modal-container')}>
			<div className={cx('pinterest-modal-research-container')}>
				<KeywordResearch
					additional_keywords={additional_keywords}
					keyword_research={campaign_pinterest.keyword_research}
					title="Pinterest Keyword Research"
					platform="PINTEREST"
				/>
			</div>
			<PinterestPin pinterest_pin={pinterest_pin} onGenerate={onOk} isModal={true} pinterest_boards={campaign_pinterest.pinterest_boards} addPinterestBoard={addPinterestBoard}/>
		</div>
	</Modal>
))

const UnconfiguredPin = observer(({ additional_keywords, campaign_pinterest, pinterest_pin, addPinterestBoard }) => {
	const [visibleConfigurationModal, setVisibleConfigurationModal] = useState(false);

	return (
		<>
			<Container noPadding fillHeight className="not-configured-pin">
				<Button icon="warning" type="text" style={{ top: '1em', marginRight: '-1.5em', justifySelf: 'flex-end', position: 'absolute'}} />
				<Container.Column span={12}>
					<Title size={6}>
						Pinterest Content
					</Title>
					<p className="mb-2">(based on scheduling targets)</p>
					<div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
						<div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={(e) => { setVisibleConfigurationModal(true) }}>
							<SVG name="edit" size={20} className="mr-1" /><b style={{ fontSize: 16, fontWeight: 500 }}>Edit</b>
						</div>
						<div style={{ borderLeft: '2px solid #000' }}></div>
						<div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={pinterest_pin.deletePin}>
							<SVG name="delete" size={20} className="mr-1"/><b style={{ fontSize: 16, fontWeight: 500 }}>Delete</b>
						</div>
					</div>
				</Container.Column>
			</Container>
			{visibleConfigurationModal && <ConfigurePinterestPinModal
				additional_keywords={additional_keywords}
				campaign_pinterest={campaign_pinterest}
				pinterest_pin={pinterest_pin}
				addPinterestBoard={addPinterestBoard}
				visible={visibleConfigurationModal}
				onOk={() => setVisibleConfigurationModal(false)}
				onCancel={() => setVisibleConfigurationModal(false)}
			/>}
		</>
	);
});

const PinterestPin = observer(({ pinterest_pin, pinterest_boards, onGenerate, isModal, addPinterestBoard }) => {
	return (
		<Container noPadding className={cx('pinterest-pin-form', { 'pinterest-pin-form-modal': isModal })}>
			<Container.Column span={12}>
				<Form.Item>
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<span>Due date</span>
						<DatePicker
							selected={new Date(pinterest_pin?.formated_due_date_input)}
							onChange={pinterest_pin.setDueDate}
							customInput={<Input inputStyle={{ width: isModal ? 200 : 240 }}/>}>
						</DatePicker>
					</div>
				</Form.Item>
				<Form.Item label="Pin Title">
					<Input 
						onChange={(e) => pinterest_pin.setTitle(e.target.value)} 
						value={pinterest_pin?.title || ''} 
						placeholder='title based on keywords'
						canCopy 
						inputStyle={{ backgroundColor: 'transparent' }}
					/>
					<div className={cx('character-limit-text')}>
						{pinterest_pin.character_limit?.title - (pinterest_pin.title?.length ?? 0)} characters left
					</div>
				</Form.Item>
				<Form.Item label="Pin Description">
					<RichText 
						placeholder='post description'
						initialState={pinterest_pin?.description || ''} 
						onChange={state => pinterest_pin.setDescription(state)} 
						canCopy
					/>
					<div className={cx('character-limit-text')}>
						{pinterest_pin.character_limit?.description - (pinterest_pin.description_length ?? 0)} characters left
					</div>
				</Form.Item>
				<div style={{ marginBottom: '1.5em'}}>
					<div style={{ marginBottom: '0.5em' }}>Pinterest Board</div>
					<Select
						value={pinterest_pin.pinterest_board_id}
						options={[					
							{
								label: <div onClick={addPinterestBoard} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}><SVG name='add' className='mr-1'/>Add new Pinterest Board</div>,
								value: 'new-group',
								disabled: true,
								header: true,
							},
							...pinterest_boards
						]}
						placeholder='select'
						position='top'
						type='checked-select'
						menuClass='pinterest-select-small-menu'
						inputStyle={{ backgroundColor: 'transparent' }}
						onChange={pinterest_pin.setPinterestBoard}
						contained={true}
					/>
					<div style={{ marginBottom: '0.5em', marginTop: '1em' }}>Content Type</div>
					<Select
						value={pinterest_pin.pinterest_content_type}
						options={contentOptions}
						placeholder='select'
						position='top'
						menuClass='pinterest-select-small-menu'
						inputStyle={{ backgroundColor: 'transparent', width: '100%' }}
						onChange={pinterest_pin.setPinterestContentType}
					/>
				</div>
				<div className={cx('pinterest-pin-content-links')}>
					<ContentLinks
						single_column
						campaign_content_links={pinterest_pin.campaign_content_links}
						platform="PINTEREST"
						addCampaignContentLink={pinterest_pin.addCampaignContentLink}
					/>
				</div>
				{!pinterest_pin.is_configured &&
				<div style={{ marginTop: '-1em', marginBottom: '-1em', display: 'flex', justifyContent: 'flex-end'}}>
					<Button onClick={() => {
						pinterest_pin.setIsConfigured(true);
						onGenerate();
					}}>
						Save
					</Button>
				</div>
				}
			</Container.Column>
		</Container>
	);
});

const PinterestView = observer(({ additional_keywords, campaign_pinterest, addPinterestBoard }) => {
	const [visiblePinModal, setVisiblePinModal] = useState(false);

	return (
		<Container noPadding>
			<div className={cx('campaign-pinterest-delete-link', 'show-mobile-only')}
				onClick={e => {
					e.stopPropagation();
					campaign_pinterest.deletePinterest(campaign_pinterest.id);
				}}
			>
				<span style={{ fontSize: 12, color: '#707070', marginRight: '0.5em'}}>Delete platform</span>
				<Button
					type="text"
					icon="delete"
					iconSize={16}
					style={{ padding: 0, color: '#707070', marginRight: '1em' }}
				></Button>
			</div>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Button onClick={campaign_pinterest?.addPin} 
					type="hollow" 
					icon="add" 
					className={cx('new-social-post-button')} 
				>
					New pin
				</Button>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<div className={cx('campaign-pinterest-delete-link', 'show-desktop-only')}
						onClick={e => {
							e.stopPropagation();
							campaign_pinterest.deletePinterest(campaign_pinterest.id);
						}}
					>
						<span style={{ fontSize: 12, color: '#707070', marginRight: '0.5em'}}>Delete platform</span>
						<Button
							type="text"
							icon="delete"
							iconSize={16}
							style={{ padding: 0, color: '#707070', marginRight: '1em' }}
						></Button>
          </div>
					<Toggle
						key="toggle_buttons"
						options={[
							{ label: 'Active', value: 'active' },
							{ label: 'Closed', value: 'closed' }
						]}
						onToggle={campaign_pinterest?.toggleActivePins}
					/>
				</div>
			</div>
			<div className={cx('pinterest-post-container')}>
				{campaign_pinterest?.sorted_filtered_platforms.map((pinterest_pin) => (
					<div key={pinterest_pin.id} className={cx('pinterest-post')}>
						{pinterest_pin.is_configured ? 
							<>
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<Popover
										position='left'
										content={
											<StatusSelection
												statusOptions={rootStore.WorkspaceStore.current_workspace.statusOptions('PINTEREST')} 
												onSelect={pinterest_pin.setStatus}
												value={pinterest_pin.status?.id}
											/>
										}
										width={300}
									>
										<div className={cx('tejik-pinterest-widget-status')} style={{ backgroundColor: pinterest_pin.status?.color }}>{pinterest_pin.status?.name}</div>
									</Popover>
									<Popover content={<Menu items={[
										{
											icon: 'query_stats',
											text: 'Keyword Research',
											action: () => {
												campaign_pinterest.setSelectedPin(pinterest_pin.id);
												setVisiblePinModal(true);
											},
										},
										{
											icon: 'delete',
											text: 'Delete',
											action: pinterest_pin.deletePin,
										}
									]} />}>
										<Button icon="more_vert" type="text" />
									</Popover>
								</div>
								<PinterestPin
									pinterest_pin={pinterest_pin}
									pinterest_boards={campaign_pinterest.pinterest_boards}
									addPinterestBoard={addPinterestBoard} 
								/>
							</>
							:
							<UnconfiguredPin
								additional_keywords={additional_keywords}
								campaign_pinterest={campaign_pinterest}
								pinterest_pin={pinterest_pin}
								addPinterestBoard={addPinterestBoard}
							/>
						}
					</div>
				))}
			</div>
			{visiblePinModal && <KeywordResearchModal
				additional_keywords={additional_keywords}
				campaign_pinterest={campaign_pinterest}
				visible={visiblePinModal}
				onOk={() => setVisiblePinModal(false)}
				onCancel={() => setVisiblePinModal(false)}
			/>}
		</Container>
	)
});

export default PinterestView;
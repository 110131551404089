import {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {
  Button,
  Container,
  Form,
  Input,
  KeywordResearch,
  RichText,
  SVG
} from 'components';

import CampaignTasks from '../campaign-tasks/campaign-tasks.view';
import ContentLinks from '../../contentLinks';

import classNames from 'classnames/bind';
import styles from './youtube.scss';

const cx = classNames.bind(styles);

const YoutubeView = observer(({campaign_platform, delePlatformFn}) => {
  const [visible, setVisible] = useState(false);

    return (
      <Container noPadding>
        <Container.Column span={7}>
          <div className={cx('campaign-keyword-research', 'show-mobile-only-flex')}>
            {(campaign_platform?.keyword_research || campaign_platform?.keyword_research?.complete) && 
            <Button
              size="small"
              type="secondary"
              shape="hollow"
              onClick={() => setVisible(!visible)}
              freezeStyle={visible}
              style={{ alignSelf: 'flex-start', marginTop: '1em', height: 40, width: 190 }}
            >
              <SVG name='query_stats' size={12} style={{ marginRight: '0.75em' }}></SVG>
              Keyword Research
            </Button>
            }
            <div 
              style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', cursor: 'pointer' }}
              onClick={e => {
                e.stopPropagation();
                delePlatformFn(campaign_platform.platform);
              }}
            >
              <span style={{ fontSize: 12, color: '#707070', marginRight: '0.5em'}}>Delete platform</span>
              <Button
                type="text"
                icon="delete"
                iconSize={16}
                style={{ padding: 0, color: '#707070' }}
              ></Button>
            </div>
          </div>
          {visible && 
            <KeywordResearch
              keyword_research={campaign_platform?.keyword_research}
              title="Search Keyword Research (SEO)"
              platform={campaign_platform?.platform}
              className={cx('show-mobile-only')}
            />
          }
          <Form.Item label="Title:">
            <Input
              value={campaign_platform?.data?.title || ''}
              onChange={e => campaign_platform.data.setTitle(e.target.value)}
              type="text"
              placeholder="title based on keywords"
              inputStyle={{ backgroundColor: 'transparent' }}
              canCopy
            />
            <div className={cx('character-limit-text')}>
              {campaign_platform?.character_limit.title - (campaign_platform?.data.title?.length ?? 0)}{' '}
              characters left
            </div>
          </Form.Item>
          <Form.Item label="Description:">
            <RichText 
              placeholder='description for search'
              initialState={campaign_platform?.data?.description || ''} 
              onChange={state => campaign_platform.data.setDescription(state)} 
              canCopy
            />
            <div className={cx('character-limit-text')}>
              {campaign_platform?.character_limit.description - (campaign_platform?.data?.description_length ?? 0)}{' '}
              characters left
            </div>
          </Form.Item>
          {/* {campaign_platform.keyword_research && !campaign_platform.keyword_research?.complete &&
            <Button onClick={campaign_platform.keyword_research.markKeywordResearchDone} style={{ marginBottom: '1em' }}>
              Keyword Research Done
            </Button>
          } */}
          <Form.Item>
            <ContentLinks
              campaign_content_links={campaign_platform?.campaign_content_links}
              platform={campaign_platform?.platform}
              addCampaignContentLink={campaign_platform?.addCampaignContentLink}
            />
          </Form.Item>
          <Form.Item className={cx('show-mobile-only')}>
            <CampaignTasks campaign_tasks={campaign_platform?.sorted_campaign_tasks} />
          </Form.Item>
        </Container.Column>
        <Container.Column span={5} className={cx('show-desktop-only')}>
          <div className={cx('campaign-keyword-research')}>
            <Button
              size="small"
              type="secondary"
              shape="hollow"
              onClick={() => setVisible(!visible)}
              freezeStyle={visible}
              style={{ alignSelf: 'flex-start', marginTop: '1em', height: 40, width: 190 }}
            >
              <SVG name='query_stats' size={12} style={{ marginRight: '0.75em' }}></SVG>
              Keyword Research
            </Button>
            <div className={cx('campaign-youtube-delete-link')}
              onClick={e => {
                e.stopPropagation();
                delePlatformFn(campaign_platform.platform);
              }}
            >
              <span style={{ fontSize: 12, color: '#707070', marginRight: '0.5em'}}>Delete platform</span>
              <Button
                type="text"
                icon="delete"
                iconSize={16}
                style={{ padding: 0, color: '#707070' }}
              ></Button>
            </div>
          </div>
          {visible && 
            <KeywordResearch
              keyword_research={campaign_platform?.keyword_research}
              title="Search Keyword Research (SEO)"
              platform={campaign_platform?.platform}
            />
          }
          <CampaignTasks campaign_tasks={campaign_platform?.sorted_campaign_tasks} />
        </Container.Column>
      </Container>
    );
});

export default YoutubeView;

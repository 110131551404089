/* eslint-disable react-hooks/exhaustive-deps */
import { $getRoot } from 'lexical';
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

import { useState, useEffect } from "react";
import { Modal, SVG } from 'components';
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";

function Placeholder({placeholder}) {
	return <div className="tejik-editor-placeholder">{ placeholder ?? 'Enter some text...'}</div>;
}

const theme = {
	placeholder: "tejik-editor-placeholder",
	paragraph: "tejik-editor-paragraph",
	list: {
		nested: {
			listitem: "tejik-editor-nested-listitem"
		},
		ol: "tejik-editor-list-ol",
		ul: "tejik-editor-list-ul",
		listitem: "tejik-editor-listitem"
	},
	link: "tejik-editor-link",
	text: {
		bold: "tejik-editor-text-bold",
		italic: "tejik-editor-text-italic",
		overflowed: "tejik-editor-text-overflowed",
		hashtag: "tejik-editor-text-hashtag",
		underline: "tejik-editor-text-underline",
		strikethrough: "tejik-editor-text-strikethrough",
		underlineStrikethrough: "tejik-editor-text-underlineStrikethrough",
	},
};

const parseInitialState = (initialState) => {
	if (!initialState) return null;

	try {
		JSON.parse(initialState);
	} catch (e) {
		const BASE = `{"root":{"children":[
			${initialState.split('\n').map((value) => (
					`{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":${JSON.stringify(value)},"type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}`
			)).join(',')}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}`;
		return BASE;
	}

	return initialState;
}

const EditorContentUpdater = ({ initialValue, refresh, refreshFn }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (editor && refresh) {
      editor.update(() => {
				const initialJson = parseInitialState(initialValue);

				if(initialJson) {
					const editorState = editor.parseEditorState(initialJson);
					editor.setEditorState(editorState);
				}

				if(refreshFn) refreshFn(false);
      });
    }
  }, [editor, initialValue]);

  return null;
};

const onIconClick = (editor) => {
	const editorState = editor.getEditorState();
	let textContent = '';
	
	editorState.read(() => {
		textContent = $getRoot().getTextContent();
	});
	
	navigator.clipboard.writeText(textContent);
	Modal.message({ type: 'success', text: 'Successfully copied content.' });
}

const CopyButton = () => {
  const [editor] = useLexicalComposerContext();

  return (
		<SVG className='tejik-editor-copy-icon'name='content_copy' onClick={() => onIconClick(editor)}/>
  );
}

export default function RichText({ initialState, onChange, placeholder, canCopy, style, key, refresh, refreshFn }) {
	const [value, setValue] = useState(initialState);

	const editorConfig = {
		theme,
		onError(error) { throw error; },
		nodes: [
			HeadingNode,
			ListNode,
			ListItemNode,
			QuoteNode,
			CodeNode,
			CodeHighlightNode,
			TableNode,
			TableCellNode,
			TableRowNode,
			AutoLinkNode,
			LinkNode
		],
		editorState: parseInitialState(initialState),
	};

	const onTextChange = (editorState) => {
		const editorStateJSON = editorState.toJSON();
		setValue(JSON.stringify(editorStateJSON));

		if (onChange)	onChange(JSON.stringify(editorStateJSON));
	}

	return (
		<LexicalComposer key={'tejik-richtext-' + key} initialConfig={{...editorConfig}}>
			<OnChangePlugin onChange={onTextChange} OnChangePlugin={onTextChange} ignoreHistoryMergeTagChange={false} />
			<div className="tejik-editor-container">
				<div className="toolbar-container">
					<ToolbarPlugin />
				</div>
				<div className="tejik-editor-inner">
					<EditorContentUpdater initialValue={initialState} refresh={refresh} refreshFn={refreshFn}/>
					<RichTextPlugin
						value={value}
						contentEditable={<ContentEditable className="tejik-editor-input" style={style}/>}
						placeholder={<Placeholder placeholder={placeholder} />}
						ErrorBoundary={LexicalErrorBoundary}
					/>
					<HistoryPlugin />
					<ListPlugin />
					<LinkPlugin />
					<AutoLinkPlugin />
					<OnChangePlugin onChange={onTextChange} />
					{canCopy && <CopyButton />}
				</div>
			</div>
		</LexicalComposer>
	);
}

import {useState} from "react";
import {Button, Modal} from "../../../../components";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./calendar.scss";
import EventLink from "./event-link.view";
import EventDot from "./event-dot.view";

const cx = classNames.bind(styles);
const CalendarItemEventsView = (props) => {
  const {events, date, handleClick} = props

  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className={cx('events-container')}>
		  <div className={cx('show-desktop-only')}>
        {events.slice(0, 2).map((event) => <EventLink key={event.id} event={event}/>)}
        {events.length > 2 &&
          <Button type="text" onClick={() => setIsVisible(true)}>
            + {events.length - 2} more
          </Button>
        }
		  </div>
      <div className={cx('event-color-dots')} onClick={() => handleClick(date)}>
        {events.slice(0, 2).map((event) => <EventDot key={event.id} event={event}/>)}
        {events.length > 2 && <EventDot/>}
      </div>
      <Modal visible={isVisible} footer={[]} closable onCancel={() => setIsVisible(false)}>
				<div className={cx('day-name')}>
					{date.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' })}
				</div>
        {events.map((event) => <EventLink key={event.id} event={event}/>)}
      </Modal>
    </div>
  );
}

CalendarItemEventsView.propTypes = {
  events: PropTypes.array,
	date: PropTypes.any
}

export default CalendarItemEventsView
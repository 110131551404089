
import React, { Component } from 'react';
import { Observer } from 'mobx-react-lite';
import { Button, Input, Table } from 'components';
import classNames from 'classnames';

export default class KeywordResearch extends Component {

	render() {
		const { additional_keywords, keyword_research, platform, title, className } = this.props;

		const baseFooter = <>
			<Observer>{() => (
				<Table
					columns={[{
						title: 'Questions',
						headerStyle: () => ({ color: 'black', marginTop: '-1em' }),
						render: (keyword) => (
							<Input
								hidden
								placeholder="question people ask about topic"
								value={keyword.text || ''}
								size="small"
								style={{ width: '100%' }}
								inputStyle={{ backgroundColor: 'transparent' }}
								onChange={(e) => keyword.setText(e.target.value)} />
						),
					}]}
					data={platform === 'YOUTUBE' ? keyword_research?.keyword_hashtags : keyword_research?.keyword_questions}
				/>
			)}</Observer>
			<div className={classNames('tejik-keyword-research-table-footer')}>
				<Button icon="add" type="hollow-dark" onClick={() => platform === 'YOUTUBE' ? keyword_research?.addKeywordHashtag() : keyword_research.addKeywordQuestion()}>New Question</Button>
			</div>
		</>

		const footer = {
			// 'YOUTUBE': <>
			// 	<p className="mb-1">
			// 		<strong>YouTube Hashtags</strong>
			// 	</p>
			// 	<Observer>{() => (
			// 		<div className={classNames('tejik-keyword-hashtags')}>
			// 			{keyword_research?.keyword_hashtags?.map((keyword_hashtag) =>
			// 				<Input
			// 					key={keyword_hashtag.id}
			// 					placeholder="# for video"
			// 					value={keyword_hashtag.tag || ''}
			// 					onChange={(e) => keyword_hashtag.setTag(e.target.value)}
			// 				/>
			// 			)}
			// 		</div>
			// 	)}</Observer>
			// 	<div className={classNames('tejik-keyword-research-table-footer')}>
			// 		<Button icon="add" type="hollow" onClick={keyword_research?.addKeywordHashtag} />
			// 	</div>
			// </>,
			'PINTEREST': additional_keywords ? 
			<div>
				<span style={{ margin: '1em' }}>Search Keyword Research (SEO)</span>
				<Table style={{ marginTop: '1em', border: 'none', width: '100%'}}
					columns={[{
						title: window.innerWidth <= 790 ? 'from blog post' : 'from blog post keyword research',
						headerStyle: () => ({ color: '#707070', fontSize: 14 }),
						render: (keyword) => (
							<div style={{ marginLeft: '1em', marginTop: '0.5em', marginBottom: '0.5em', color: keyword?.text ? 'black' : '#707070' }}>
								{keyword?.text || 'long tail keyword phrases'}
							</div>
						),
					}, {
						title: 'volume',
						headerStyle: () => ({ color: 'black' }),
						render: (keyword) => (
							<div style={{ color: keyword?.volume ? 'black' : '#707070' }}>
								{keyword?.volume || '#'}
							</div>
						),
						// rowStyle: () => ({ width: '100px' }),
						width: 'fit',
					}]}
					data={additional_keywords}
				/>
			</div> : 
			<></>,
		}[platform] || baseFooter;

		return (
			<Observer>
				{() => (
				<div className={className}>
					<Table
						columns={[{
							title: title,
							headerStyle: () => ({ color: 'black' }),
							render: (keyword) => (
								<Input
									hidden
									placeholder="long tail keyword phrases"
									value={keyword?.text || ''}
									size="small"
									style={{ width: '100%' }}
									inputStyle={{ backgroundColor: 'transparent' }}
									onChange={(e) => keyword.setText(e.target.value)} />
							),
						}, {
							title: 'volume',
							headerStyle: () => ({ width: '100px', color: 'black' }),
							render: (keyword) => (
								<Input
									hidden
									placeholder="#"
									value={keyword?.volume || ''}
									size="small"
									inputStyle={{ backgroundColor: 'transparent' }}
									onChange={(e) => keyword.setVolume(e.target.value)}
								/>
							),
							rowStyle: () => ({ width: '100px' }),
						}]}
						data={keyword_research?.keywords}
					/>
					<div className={classNames('tejik-keyword-research-table-footer')}>
						<Button icon="add" type="hollow-dark" title="New Keyword" onClick={() => keyword_research.addKeyword()}>New Keyword</Button>
					</div>
					{footer}
				</div>
				)}
			</Observer>
		);
	}
}
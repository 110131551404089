import React, { Component } from 'react';

import { Button, Select } from 'components';
import SVG from 'components/svg/svg';

import classNames from 'classnames/bind';
import styles from './new-entity-mobile.scss';

const cx = classNames.bind(styles);

export default class NewEntityMobile extends Component {
	constructor(props) {
		super(props);
		this.state = { [this.props.valueKey]: ''};
	}

	componentDidMount() {
		for (const option of this.props.options || []) {
			if (option.defaultValue) {
				this.setValue(option.key, option.defaultValue)
			}
		}
	}

	createEntity = (e) => {
		this.props.onSubmit(e, this.state);
		
		this.setValue(this.props.valueKey, '');
		for (const option of this.props.options || []) {
			this.setState({[option.key]: [] });
		}
	}

	setInput = (e) => {
		this.setValue(this.props.valueKey, e.target.value);
	};

	setValue = (key, value) => {
		this.setState({ [key]: value });
	};

	render() {
		const { buttonProps, className, confirmText = 'Click to create', icon, inputWidth, inputClass, inputStyle, placeholder, title, buttonClass, options = [], buttonType = 'secondary' } = this.props;

		return (
			<div className={classNames(options.length > 0 ? 'tejik-new-entity-mobile' : 'tejik-new-entity-single-mobile', className)}>
				{title && 
					<div style={{ display:'flex', flexDirection: 'row', marginRight: '1em', marginBottom: '1em', marginTop: '1em' }}>
						<SVG name={icon} />
						<div style={{ marginLeft: 8 }}>{title}</div>
					</div>
				}
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					{!title && <SVG name={icon} style={{ marginTop: 10 }}/>}
					<div className={classNames('tejik-entity-fields')}>
						<input
							placeholder={placeholder}
							value={this.state[this.props.valueKey]}
							onChange={this.setInput} onKeyDown={(e) => {
								if (e.key === 'Enter') {
									// Prevent form submission (if the input is wrapped inside a form)
									e.preventDefault();
									this.createEntity(e);	
								}
							}}
							style={{width: inputWidth, fontSize: 14, marginRight: 0, ...inputStyle }}
							className={classNames(inputClass)}
						/>
						{options.map((select, idx) => (
							<Select
								key={select.key}
								value={this.state[select.key]}
								type={select.type}
								defaultValue={select.defaultValue}
								options={select.options}
								onChange={(value) => this.setValue(select.key, value)}
								placeholder={select.placeholder}
								style={{ width: select.width }}
								className={cx(select.className, {'rounded': idx === options.length -1} )}
								menuClass={select.menuClass}
							/>
						))}
						<Button 
							type={buttonType} 
							className={classNames('tejik-button', buttonClass)} 
							onClick={this.createEntity} 
							style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
							{...buttonProps}
						>
							{confirmText}
						</Button>
					</div>
				</div>
			</div>
		);
	}
}
import React, {Component} from "react";
import classNames from "classnames";

export default class Switch extends Component {

    onToggle = (value) => {
        this.setSelectedValue(value);
        this.props.onToggle(value);
    };

    render() {
        const {onChange, value, label, icon, className} = this.props;

        return (
            <div className={classNames('tejik-switch', className)}>
                <div className={classNames('label-container')} onClick={onChange}>
                    {
                        icon &&
                        <span className={classNames('material-icons-outlined')}>{icon}</span>
                    }

                    <p className={classNames('label')}>{label}</p>
                </div>

                <label className={classNames('switch')}>
                    <input type="checkbox" onChange={onChange} checked={value}/>
                    <span className={classNames('slider round')}/>
                </label>
            </div>
        );
    }
}
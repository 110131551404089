import { Container, Info, Input, Table } from "components";
import SVG from "components/svg/svg";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { PlatformCharacterLimitSettings, PlatformIconMap } from "utils/utils";

import CharacterLimitsModel from './character-limits.model';
import { ModelConnector } from "stores";

import classNames from 'classnames/bind';
import styles from './character-limits.scss';

const cx = classNames.bind(styles);

const CharacterLimits = observer(({ model }) => {
	const columns = [{
		render: (row) => (<SVG name={PlatformIconMap.get(row.platform)} style={{ margin: 10 }}/>),
		width: 'fit',
	},
	{
		render: (row) => {
			const setting = PlatformCharacterLimitSettings.get(row.platform);

			return (
				<div className={cx('character-limits-cell')}>
					{setting.title?.label && <div style={{ marginBottom: setting.description?.label ? 16 : 0, fontSize: 14 }}>{setting.title?.label}</div>}
					{setting.description?.label && <div style={{ fontSize: 14 }}>{setting.description?.label}</div>}
				</div>
			);
		}
	}, {
		title: 'default character limit',
		headerStyle: () => ({ display: 'flex', justifyContent: 'flex-end', textAlign: 'right', marginRight: 30, fontSize: 14 }),
		rowStyle: () => ({ minWidth: 180 }),
		render: (row) => {
			const setting = PlatformCharacterLimitSettings.get(row.platform);

			return (
				<div className={cx('character-limits-cell')} style={{ alignItems: 'flex-end'}}>
					{setting.title?.default_character_limit && <div style={{ marginBottom: setting.description?.default_character_limit ? 16 : 0, marginRight: 30, fontSize: 14 }}>{setting.title?.default_character_limit}</div>}
					{setting.description?.default_character_limit && <div style={{ marginRight: 30, fontSize: 14 }}>{setting.description?.default_character_limit}</div>}
				</div>
			);
		}
	}, {
		title: 'ideal character length',
		rowStyle: () => ({ minWidth: 160 }),
		render: (row) => {
			const setting = PlatformCharacterLimitSettings.get(row.platform);

			return (
				<div className={cx('ideal-limits-cell')}>
					{setting.title?.ideal_character_limit && <div style={{ marginBottom: setting.description?.ideal_character_limit ? 16 : 0, fontSize: 14 }}>{setting.title?.ideal_character_limit}</div>}
					{setting.description?.ideal_character_limit && <div style={{ fontSize: 14 }}>{setting.description?.ideal_character_limit}</div>}
				</div>
			);
		}
	}, {
		title: 'your character limit',
		rowStyle: () => ({ minWidth: 180 }),
		render: (row) => (<div className={cx('character-limits-cell')}>
			{PlatformCharacterLimitSettings.get(row.platform).title?.default_character_limit && <Input
				className={cx({ 'empty-input': row.title === '' })}
				size="small"
				value={row.title}
				inputStyle={{ backgroundColor: '#F5F5F5'}}
				onChange={(e) => {
					row.setTitle(e.target.value);
					model.debouncedSave();
				}}
			/>}
			{PlatformCharacterLimitSettings.get(row.platform).description?.default_character_limit && <Input
				className={cx({ 'empty-input': row.description === '' })}
				size="small"
				value={row.description}
				inputStyle={{ backgroundColor: '#F5F5F5'}}
				onChange={(e) => {
					row.setDescription(e.target.value);
					model.debouncedSave();
				}}
			/>}
		</div>)
	}];

	return (
		<Container title="Character Limits" titleSize={1} titleIcon={'text_fields'} titleWeight={400} actions={<div className={cx('character-limit-description')}>
				<div className="show-desktop-only"><SVG name="info" className="info-icon"/>If "Your character limit" is not set, the system will use the default character limits.</div>
				<Link to="/workspace-settings/templates"><SVG name="close" size={16} /></Link>
			</div>}
			isLoading={model.isLoading}
		>
			<div className={cx('character-limit-description')}><div className={classNames("show-mobile-only")}><SVG name="info" className="info-icon"/>If "Your character limit" is not set, the system will use the default character limits.</div></div>
			<div className={cx('character-limit-table')}>
				<Table
					columns={columns}
					data={model.platformsData}
				/>
			</div>
			<Info infoKey="character-limits"/>
		</Container>
	);
});

export default ModelConnector(CharacterLimits, CharacterLimitsModel);

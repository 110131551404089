import React, { Component } from 'react';
import SVG from 'components/svg/svg';

import classNames from 'classnames';

export default class UserImage extends Component {
	render() {
		const { size = 'normal', user, style } = this.props;

		if (!user) return <SVG name="account_circle"/>

		return (
			<div style={style} className={classNames('tejik-user-image', `tejik-user-image-${size}`)}>
				{user.initials}
			</div>
		);
	}
}
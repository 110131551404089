import React, { Component } from 'react';
import { Loader, Title } from 'components';

import classNames from 'classnames';
import SVG from 'components/svg/svg';

export default class Container extends Component {
	constructor(props) {
		super(props);
		this.state = { isLoading: props.isLoading };

		if (props.isLoading) {
			this.interval = setInterval(this.setIsLoading, 500);
		}
	}

	componentDidUpdate (prevProps) {
		if (prevProps.isLoading !== this.state.isLoading) {
			this.setIsLoading(this.props.isLoading);
		}
	}

	setIsLoading = () => {
		this.setState({
			isLoading: this.props.isLoading,
		});

		if (!this.props.isLoading) {
			clearInterval(this.interval);
		}
	}

	static Column = ({ className, children, span, onClick, style }) => {
		return (
			<div style={style} className={classNames('tejik-container-column', `span-${span}`, className)} onClick={onClick}>
				{children}
			</div>
		)
	};

	render() {
		const { actions, children, className, headerClassName, noPadding, fillHeight, style, title, titleIcon, titleSize } = this.props;

		return (
			<div className={classNames('tejik-container', {'tejik-container-no-padding': noPadding, 'tejik-container-fill-height': fillHeight }, className)} style={style}>
				{(title || titleIcon || actions) &&
					<div className={classNames('tejik-container-header', 'tejik-container-title', headerClassName)}>
						{titleIcon && title && <SVG className="tejik-container-header-icon" name={titleIcon} size={22} />}
						{title && <Title className='tejik-container-title-div' size={titleSize || 3}>{title}</Title>}
						{actions && <div className="tejik-container-header-actions">{actions}</div>}
					</div>
				}
				<div className={classNames('tejik-container-body', className)}>
					{this.props.isLoading ? <Loader /> : children}
				</div>
			</div>
		);
	}
}
import { types, flow } from 'mobx-state-tree';
import request from 'api/request';

const { string, boolean, model, optional } = types;
// Model
const SignUpModel = model('SignUpModel', {
	first_name: optional(string, ''),
	last_name: optional(string, ''),
	email: optional(string, ''),
	password: optional(string, ''),
	last_step: optional(boolean, false),
}).actions((self) => ({
	signUp: flow(function* () {
		try {
			yield request.post('/auth/sign-up', {
				first_name: self.first_name,
				last_name: self.last_name,
				email: self.email,
				password: self.password,
			});
			self.AuthStore.fetchSession();
			self.props.navigate('/');
		} catch (err) {
			console.error(err);
		}
	}),
	// Setters
	setFirstName(first_name) {
		self.first_name = first_name;
	},
	setLastName(last_name) {
		self.last_name = last_name;
	},
	setEmail(email) {
		self.email = email;
	},
	setPassword(password) {
		self.password = password;
	},
	setLastStep(last_step) {
		self.last_step = last_step;
	},
}));

export default SignUpModel;

import { Button, Container, Info, Input, NewEntity, PlatformSelection, Popover } from "components";
import SocialViewModel from "./social.model";
import { ModelConnector } from "stores";
import { Observer, observer } from "mobx-react-lite";

import classNames from 'classnames/bind';
import styles from './social.scss';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import SVG from "components/svg/svg";
import { IsSocialMediaPlatform, PlatformIconMap, PlatformLabelMap } from "utils/utils";

const cx = classNames.bind(styles);

const ContentType = observer(({ contentType, index, platformOptions }) => (
	<Draggable draggableId={contentType.id} index={index}>
		{(provided) => (
			<div
				className={cx('draggable-item')}
				ref={provided.innerRef}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
			>
				<Observer>{() => (<>
					<SVG className="text-neutral-2" name="drag_indicator" />
					<Input
						value={contentType.name}
						onChange={(e) => contentType.setName(e.target.value)}
						size="small"
						className={classNames('social-content-type')}
						inputStyle={{ backgroundColor: 'transparent'}}
						hidden
					/>
					<Popover content={
						<PlatformSelection
							platforms={contentType.platforms}
							togglePlatform={contentType.togglePlatform}
							workspacePlatforms={platformOptions}
						/>
					}>
						<div className={classNames('social-media-platforms')}>
							{contentType.platforms.map((platform) => (<SVG key={platform} style={{ margin: 4 }} size={20} name={PlatformIconMap.get(platform)} />))}
						</div>
					</Popover>
					<Button type="text" icon="delete" size="small" onClick={contentType.deleteContentType} />
				</>)}</Observer>
			</div>
		)}
	</Draggable>
));

const SocialView = observer(({ model }) => {
	const platformOptions = model.WorkspaceStore.current_workspace.platforms.filter((platform) => IsSocialMediaPlatform(platform) || platform === 'YOUTUBE')
	const contentTypeCreateOptions = [
		{
			key: 'platforms',
			type: 'multi-select',
			placeholder: 'select platform(s)',
			options: platformOptions.map((platform) => ({
				icon: PlatformIconMap.get(platform),
				label: PlatformLabelMap.get(platform),
				value: platform,
			})),
			className: cx('new-content-type-select'),
			menuClass: cx('new-content-type-menu')
		},
	];

	return (
		<Container title="Social Settings" titleSize={1} titleIcon={'share'} titleWeight={400} isLoading={model.isLoading}>
			{model.socialCaption && 
			<Container.Column span={12} className={cx('mb-1')}>
				<p className="mb-1" style={{ fontSize: 14, lineHeight: 1.6 }}>Select which social platforms you want to utilize the Hook, Content Copy, CTA, and Link. And don’t worry. You can customize each caption more inside the campaign.</p>
				<div className={cx('social-media-captions')}>
					{/* Hook Captions */}
					<div className={cx('social-media-caption-name')}>
						<div style={{ minWidth: 250 }}><SVG name="phishing" size={16} style={{ marginRight: 10 }}/><span style={{ fontSize: 16 }}>Hook</span></div>
						<PlatformSelection
							platforms={model.socialCaption.hook_platforms}
							togglePlatform={model.socialCaption.toggleHookPlatform}
							workspacePlatforms={platformOptions}
						/>
					</div>
					{/* Transformation Hooks */}
					<div className={cx('social-media-caption-name')}>
						<div style={{ minWidth: 250 }}><SVG name="subtitles" size={16} style={{ marginRight: 10 }} /><span style={{ fontSize: 16 }}>Content Copy</span></div>
						<PlatformSelection
							platforms={model.socialCaption.transformation_platforms}
							togglePlatform={model.socialCaption.toggleTransformationPlatform}
							workspacePlatforms={platformOptions}
						/>
					</div>
					{/* CTA Hooks */}
					<div className={cx('social-media-caption-name')}>
						<div style={{ minWidth: 250 }}><SVG name="phone" size={16} style={{ marginRight: 10 }}/><span style={{ fontSize: 16 }}>CTA (call-to-action)</span></div>
						<PlatformSelection
							platforms={model.socialCaption.call_to_action_platforms}
							togglePlatform={model.socialCaption.toggleCallToActionPlatform}
							workspacePlatforms={platformOptions}
						/>
					</div>
					{/* Link Hooks */}
					<div className={cx('social-media-caption-name')}>
						<div style={{ minWidth: 250 }}><SVG name="language" size={16} style={{ marginRight: 10 }}/><span style={{ fontSize: 16 }}>Link (URL for blog post)</span></div>
						<PlatformSelection
							platforms={model.socialCaption.link_platforms}
							togglePlatform={model.socialCaption.toggleLinkPlatform}
							workspacePlatforms={platformOptions}
						/>
					</div>
				</div>
			</Container.Column>}
			<p style={{ fontSize: 16, display: 'flex', width: 'fit-content' }}>Types of social content:&nbsp;&nbsp;&nbsp;<div className="info-text-color"><SVG name="info" className="info-icon-color"/><span className="show-desktop-only">e.g. static post, short clip</span></div></p>
			<div className={cx('draggable-list', 'content-type-list')}>
				<DragDropContext onDragEnd={model.onDragEnd}>
					<Droppable droppableId="list">
						{(provided) => (
							<div ref={provided.innerRef} {...provided.droppableProps}>
								{model.orderedContentTypes.map((contentType, index) => <ContentType contentType={contentType} index={index} key={contentType.id} platformOptions={platformOptions} />)}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</div>
			<Popover contentClass={cx('new-content-popup')} position="top" 
				content={<NewEntity
					placeholder="enter type of content (e.g. static post, short clip)"
					title="New content type"
					key={model.isLoading}
					confirmText="Click to create"
					icon="add"
					valueKey="name"
					options={contentTypeCreateOptions}
					onSubmit={model.addContentType}
					inputClass={cx('new-content-type-input')}
				/>}>
				<Button type="hollow" icon="add">Add content type</Button>
			</Popover>
			<Info infoKey="social-settings"/>
		</Container>
	);
});

export default ModelConnector(SocialView, SocialViewModel);
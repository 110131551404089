import classNames from "classnames/bind";
import styles from "./calendar.scss";

const cx = classNames.bind(styles);
const DAYS_OF_WEEK = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
];

const DAYS_OF_WEEK_MOBILE = [
	'S',
	'M',
	'T',
	'W',
	'T',
	'F',
	'S',
  ];

const CalendarHeaderView = () => {
  return (
    <div className={cx('calendar-header')}>
      {
        DAYS_OF_WEEK.map((value, index) => (
          <div key={index} className={cx('calendar-day-name', 'show-desktop-only')}>{value}</div>
        ))
      }
      {
        DAYS_OF_WEEK_MOBILE.map((value, index) => (
          <div key={index} className={cx('calendar-day-name', 'show-mobile-only')}>{value}</div>
        ))
      }
    </div>

  );
}

export default CalendarHeaderView;
/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames/bind";
import styles from "./calendar.scss";
import PropTypes from "prop-types";
import {useMemo} from "react";
import CalendarItemEventsView from "./calendar-item-events.view";
import {useDroppable} from '@dnd-kit/core';

const cx = classNames.bind(styles);

const daysInMonth = (year, month) => {
  return new Date(year, month, 0).getDate();
}

const CalendarItemView = (props) => {
  const {date, moveWeek, events, index, days, selectedDate, showRightBorder, onEventsClick} = props;

  const monthDate = useMemo(() => {
    if (date) {
      return new Date(date.getFullYear(), date.getMonth(), 1);
    }
  }, [date]);

  const firstDay = useMemo(() => {
    if (monthDate) {
      return monthDate.getDay();
    }
  }, [monthDate])

  const daysInPrevMonth = useMemo(() => {
    if (date) {
      return daysInMonth(date.getFullYear(), (date.getMonth() + 0));
    }
  }, [date]);

  const daysInCurrentMonth = useMemo(() => {
    if (date) {
      return daysInMonth(date.getFullYear(), (date.getMonth() + 1));
    }
  }, [date]);

  const newDate = useMemo(() => {
    if (!date || !daysInCurrentMonth || !daysInPrevMonth || firstDay == null) {
      return null;
    }

    let newDate;
    const weekScale = Math.floor((date.getDate() + firstDay) / 7) * 7;
    const baseDate = (index - firstDay) + (1 * moveWeek ? weekScale + 1 : 1);

    if (firstDay > index) {
      newDate = new Date(date.getFullYear(), date.getMonth() - 1, baseDate + daysInPrevMonth)
    } else if (firstDay <= index && index < daysInCurrentMonth + firstDay) {
      newDate = new Date(date.getFullYear(), date.getMonth(), baseDate)
    } else {
      newDate = new Date(date.getFullYear(), date.getMonth() + 1, baseDate - daysInCurrentMonth)
    }

    return newDate;
  }, [date, daysInCurrentMonth, daysInPrevMonth, firstDay]);

  const {isOver, setNodeRef} = useDroppable({
    id: `calendar-item-${index}`,
    data: {
      date: newDate,
      index: index,
      supports: ['idea-item','campaign-item']
    }
  });

	const changeSelectedDate = (selected) => {
		onEventsClick(selected);
	};

  const RenderDate = ({date, notCurrentMonth }) => (
    <div className={cx('calendar-date', {'not-current-month': notCurrentMonth })}>
      {date}
    </div>
  );

  return (
    <div
      ref={setNodeRef}
      key={index}
      className={cx('calendar-day', 
        {'selected': newDate.toDateString() === selectedDate}, 
        {'idea-over': isOver}, 
        {'first-day-month': index === 0}, 
        {'last-week': index === days - 7}, 
        {'week-end': (index === 6 || index === 13 || index === 20 || index === 27 || index === 34 || index === 41) && showRightBorder },
        {'last-week-day': index === 6}, 
        {'last-week-of-month': index >= days - 7},
        {'last-month-day': index + 1 === days},  
        {'not-this-month': newDate.getMonth() !== date.getMonth()}
      )}
      onClick={() => changeSelectedDate(newDate)}
    >
      {
        events.get(newDate.toDateString()) &&
        <CalendarItemEventsView events={events.get(newDate.toDateString())} date={newDate} handleClick={changeSelectedDate}/>
      }
      <RenderDate notCurrentMonth={newDate.getMonth() !== date.getMonth()} date={newDate.getDate()} />
    </div>
  )
}

CalendarItemView.propTypes = {
  date: PropTypes.any,
  events: PropTypes.any,
  moveWeek: PropTypes.bool,
  index: PropTypes.number
}

export default CalendarItemView;
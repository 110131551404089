import {observer} from 'mobx-react-lite';
import {
  Button,
  Container,
  Form,
  Input,
  KeywordResearch,
  SVG,
} from 'components';

import CampaignTasks from '../campaign-tasks/campaign-tasks.view';
import ContentLinks from '../../contentLinks';

import classNames from 'classnames/bind';
import styles from './blog.scss';
import { rootStore } from 'stores';
import {useState} from 'react';

const cx = classNames.bind(styles);

const BlogView = observer(({campaign_platform, urlValue, updateUrlFn, delePlatformFn}) => {
  const [visible, setVisible] = useState(false);

  return (
    <Container noPadding className='campaign-widget-BLOGPOST'>
      <Container.Column span={7}>
      <div>
        <div className={cx('show-mobile-only-flex', 'campaign-keyword-research')}>
          {(campaign_platform?.keyword_research || campaign_platform?.keyword_research?.complete) && 
            <Button
              size="small"
              type="secondary"
              shape="hollow"
              onClick={() => setVisible(!visible)}
              freezeStyle={visible}
              style={{ alignSelf: 'flex-start', marginTop: '1em', height: 40, width: 190 }}
            >
              <SVG name='query_stats' size={12} style={{ marginRight: '0.75em' }}></SVG>
              Keyword Research
            </Button>
          }
          <div className={cx('campaign-blogpost-delete-link')}
            onClick={e => {
              e.stopPropagation();
              delePlatformFn(campaign_platform.platform);
            }}
          >
            <span style={{ fontSize: 12, color: '#707070', marginRight: '0.5em'}}>Delete platform</span>
            <Button
              type="text"
              icon="delete"
              iconSize={16}
              style={{ padding: 0, color: '#707070' }}
            ></Button>
          </div>
        </div>
        {visible && 
          <KeywordResearch
            keyword_research={campaign_platform?.keyword_research}
            title="Search Keyword Research (SEO)"
            platform={campaign_platform?.platform}
            className={cx('show-mobile-only')}
          />
        }
        <Form.Item label="Post Title:">
          <Input
            value={campaign_platform?.data?.title || ''}
            onChange={e => campaign_platform.data.setTitle(e.target.value)}
            type="text"
            placeholder="title based on keywords"
            inputStyle={{ backgroundColor: 'transparent' }}
            canCopy
          />
          <div className={cx('character-limit-text')}>
            {campaign_platform?.character_limit.title - (campaign_platform?.data.title?.length ?? 0)}{' '}
            characters left
          </div>
        </Form.Item>
        <Form.Item label="Permalink (URL):">
          <Input
            preffix={(rootStore.WorkspaceStore?.current_workspace.domain + (urlValue?.indexOf('/') > -1 ? '' : '/') ?? '')}
            value={urlValue || ''}
            onChange={e => {
              campaign_platform.data.setURL(e.target.value, false); 
              updateUrlFn(e.target.value.replace(' ', '-').toLowerCase()); 
            }}
            type="text"
            placeholder="url slug based on title"
            inputStyle={{ backgroundColor: 'transparent' }}
          />
          <div className={cx('character-limit-text')}></div>
        </Form.Item>
        <Form.Item label="Meta Description:">
          <Input
            value={campaign_platform?.data?.description || ''}
            onChange={e => campaign_platform.data.setDescription(e.target.value)}
            type="textarea"
            placeholder="description for search"
            canCopy
          />
          <div className={cx('character-limit-text')}>
            {campaign_platform?.character_limit.description - (campaign_platform?.data.description?.length ?? 0)}{' '}
            characters left
          </div>
        </Form.Item>
        {/* {campaign_platform.keyword_research && !campaign_platform.keyword_research?.complete &&
          <Button onClick={campaign_platform?.keyword_research?.markKeywordResearchDone} style={{ marginBottom: '1em' }}>
            Keyword Research Done
          </Button>
        } */}
        <Form.Item>
          <ContentLinks
            campaign_content_links={campaign_platform?.campaign_content_links}
            platform={campaign_platform?.platform}
            addCampaignContentLink={campaign_platform?.addCampaignContentLink}
          />
        </Form.Item>
        <Form.Item className={cx('show-mobile-only')}>
          <CampaignTasks campaign_tasks={campaign_platform?.sorted_campaign_tasks} />
        </Form.Item>
      </div>
      </Container.Column>
      <Container.Column span={5} className={cx('show-desktop-only')}>
        <div className={cx('campaign-keyword-research')}>
          <Button
            size="small"
            type="secondary"
            shape="hollow"
            onClick={() => setVisible(!visible)}
            freezeStyle={visible}
            style={{ alignSelf: 'flex-start', marginTop: '1em', height: 40, width: 190 }}
          >
            <SVG name='query_stats' size={12} style={{ marginRight: '0.75em' }}></SVG>
            Keyword Research
          </Button>
          <div className={cx('campaign-blogpost-delete-link')}
            onClick={e => {
              e.stopPropagation();
              delePlatformFn(campaign_platform.platform);
            }}
          >
            <span style={{ fontSize: 12, color: '#707070', marginRight: '0.5em'}}>Delete platform</span>
            <Button
              type="text"
              icon="delete"
              iconSize={16}
              style={{ padding: 0, color: '#707070' }}
            ></Button>
          </div>
        </div>
        {visible && 
          <KeywordResearch
            keyword_research={campaign_platform?.keyword_research}
            title="Search Keyword Research (SEO)"
            platform={campaign_platform?.platform}
            className={cx('show-desktop-only')}
          />
        }
        <CampaignTasks campaign_tasks={campaign_platform?.sorted_campaign_tasks} />
      </Container.Column>
    </Container>
  );
});

export default BlogView;

import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./calendar.scss";
import {useMemo} from "react";

const cx = classNames.bind(styles);

const EventDot = (props) => {
  const {event} = props;

  const statusColor = useMemo(() => {
    if(event) {
      const platforms = event.platforms.filter((platform) => platform.statusColor)
      if (platforms.length === 0) {
        return undefined;
      }
      return platforms[0].statusColor;
    } else {
      return '#FFFFFF';
    }
  }, [event])

  return (
    <>
      {event ? 
        <div key={event.id} className={cx('event-color-dot')} style={{ backgroundColor: statusColor }}/> :
        <div key={0} className={cx('event-color-dot')} style={{ backgroundColor: '#FFFFFF' }}>+</div>
      }
    </>
  )
}

EventDot.propType = {
  event: PropTypes.object
}

export default EventDot

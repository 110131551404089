import { Navigate, Route, Routes } from "react-router-dom";
import { observer } from "mobx-react-lite";
import Sidebar from "views/base/sidebar/sidebar";
import { rootStore } from "stores";

// Route Views
import WorkspaceView from "views/app/organization-settings/workspaces/workspace/workspace.view";

import CategoriesView from "views/app/workspace-settings/social/categories/categories.view";
import HashtagsView from "views/app/workspace-settings/social/hashtags/hashtags.view";
import IntegrationsView from "views/app/workspace-settings/integrations/integrations.view";
import CollaborationView from "views/app/workspace-settings/collaboration/collaboration.view";
import CampaignStatusesView from "views/app/workspace-settings/campaign-statuses/campaign-statuses.view";
import SchedulingTargetsView from "views/app/workspace-settings/scheduling-targets/scheduling-targets.view";
import TasksView from "views/app/workspace-settings/tasks/tasks.view";

// Social
import SocialView from "views/app/workspace-settings/social/social.view";
import HooksView from "views/app/workspace-settings/social/hooks/hooks.view";
import CallToActionView from "views/app/workspace-settings/social/call-to-action/call-to-action.view";
import PinterestView from "views/app/workspace-settings/social/pinterest-board/pinterest-board.view";

import TemplatesView from "views/app/workspace-settings/templates/templates.view";
import SocialMediaCaptionsView from "views/app/workspace-settings/templates/social-media-captions/social-media-captions.view";

// Templates
import CharacterLimitsView from "views/app/workspace-settings/templates/character-limits/character-limits";
import ContentLinksView from "./templates/content-links/content-links.view";
import RecycleContentView from "./templates/recycle-content/recycle-content.view";

import classNames from 'classnames/bind';
import styles from './workspace-settings.scss';

const cx = classNames.bind(styles);

function WorkspaceSettings() {
	const basePath = '/workspace-settings';

	const workspaceSettingsMenuItems = [{
		icon: 'keyboard_backspace',
		text: 'Back',
		to: '/',
		exact: true,
	}, {
		icon: 'workspaces',
		text: 'Workspace',
		to: basePath,
		subPaths: [`${basePath}/scheduling-targets`, `${basePath}/campaign-statuses`, `${basePath}/tasks`],
		exact: true,
	}, {
		icon: 'description',
		text: 'Templates',
		to: `${basePath}/templates`,
	}, {
		icon: 'share',
		text: 'Social',
		to: `${basePath}/social`,
		subPaths: [`${basePath}/social/hooks`, `${basePath}/social/call-to-action`, `${basePath}/social/hashtags`, `${basePath}/social/categories`, `${basePath}/social/pinterest-board`],
		exact: true,
	}, {
		icon: 'phishing',
		text: 'Hooks',
		to: `${basePath}/social/hooks`,
		isSubMenu: true,
	}, {
		icon: 'phone',
		text: 'CTA',
		to: `${basePath}/social/call-to-action`,
		isSubMenu: true,
	}, {
		icon: 'tag',
		text: 'Hashtags',
		to: `${basePath}/social/hashtags`,
		isSubMenu: true,
	}, {
		icon: 'grid_view',
		text: 'Categories',
		to: `${basePath}/social/categories`,
		isSubMenu: true,
	}, {
		icon: 'pinterest',
		text: 'Pinterest',
		to: `${basePath}/social/pinterest-board`,
		isSubMenu: true,
	}, {
		icon: 'handshake',
		text: 'Collaboration',
		to: `${basePath}/collaboration`,
	}, {
		icon: 'hub',
		text: 'Integrations',
		to: `${basePath}/integrations`,
	}];

	const workspace = rootStore.WorkspaceStore?.current_workspace;

	if (!workspace) {
		return (
			<Routes>
				<Route
					path=""
					element={<Navigate to="/" replace />}
				/>
			</Routes>
		);
	}

	return (
		<div className={cx('main')}>
			<Sidebar menuItems={workspaceSettingsMenuItems} allowWorkspaceSelect />
			<div style={{ width: 300, height: '100%' }} className={cx('show-desktop-only')}></div>

			<div className={cx('main-content')} key={workspace.id} id="main-content">
				<Routes>
					<Route index element={<WorkspaceView allowClose={false} onOk={() => { }} shouldConfigure />} />
					<Route path="scheduling-targets" element={<SchedulingTargetsView workspace={workspace} />} />
					<Route path="campaign-statuses" element={<CampaignStatusesView workspace={workspace} />} />
					<Route path="tasks" element={<TasksView workspace={workspace} />} />
					<Route path="templates" element={<TemplatesView />} />
					<Route path="templates/social-media-captions" element={<SocialMediaCaptionsView />} />
					<Route path="templates/character-limits" element={<CharacterLimitsView workspace={workspace} />} />
					<Route path="templates/content-links" element={<ContentLinksView workspace={workspace} />} />
					<Route path="templates/recycle-content" element={<RecycleContentView workspace={workspace} />} />
					<Route path="social">
						<Route index element={<SocialView workspace={workspace} />} />
						<Route path="hooks" element={<HooksView workspace={workspace} />} />
						<Route path="call-to-action" element={<CallToActionView workspace={workspace} />} />
						<Route path="hashtags" element={<HashtagsView workspace={workspace} />} />
						<Route path="categories/*" element={<CategoriesView />} />
						<Route path="pinterest-board" element={<PinterestView workspace={workspace}/>}/>
					</Route>
					<Route path="integrations" element={<IntegrationsView />} />
					<Route path="collaboration" element={<CollaborationView />}/>
					<Route
						path=""
						element={<Navigate to="/" replace />}
					/>
				</Routes>
			</div>
		</div>
	);
}

export default observer(WorkspaceSettings);
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { rootStore } from 'stores';

import { Loader } from 'components';

const Logout = () => {
	const navigate = useNavigate();

	useEffect(() => {
		async function logout() {
			await rootStore.AuthStore.logOut();
			navigate('/login');
		}
		logout();
	}, [navigate]);

	return (
		<Loader type="centered" />
	);
};

export default Logout;

import React, { Component } from 'react';

import './title.scss';
import classNames from 'classnames';

export default class Button extends Component {
	render() {
		const { className, children, size = 1, weight = 600, style } = this.props;

		return ({
			1: (<h1 style={style} className={classNames(className, 'tejik-title', `font-weight-${weight}`)}>{children}</h1>),
			2: (<h2 style={style} className={classNames(className, 'tejik-title', `font-weight-${weight}`)}>{children}</h2>),
			3: (<h3 style={style} className={classNames(className, 'tejik-title', `font-weight-${weight}`)}>{children}</h3>),
			4: (<h4 style={style} className={classNames(className, 'tejik-title', `font-weight-${weight}`)}>{children}</h4>),
			5: (<h5 style={style} className={classNames(className, 'tejik-title', `font-weight-${weight}`)}>{children}</h5>),
			6: (<h6 style={style} className={classNames(className, 'tejik-title', `font-weight-${weight}`)}>{children}</h6>),
		}[size]);
	}
}
import PropTypes from "prop-types";
import {Modal} from "../../components";

const ConfirmationModal = (props) => {
  const {onConfirm, confirmButtonText, onCancel, title, description, showCancel} = props;
  return (
    <Modal
      visible={true}
      closable
      onOk={onConfirm}
      onCancel={onCancel}
      okText={confirmButtonText}
      title={title}
      textBody={description}
	  showCancel={showCancel}
      cancelButtonProps={{type: 'hollow'}}
    />
  )
}

ConfirmationModal.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  confirmButtonText: PropTypes.string,
  showCancel: PropTypes.bool
}

export default ConfirmationModal
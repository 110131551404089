
import { Observer, observer } from 'mobx-react-lite';
import { Button, Container, Info, Input, List, NewEntity, Popover } from "components";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ModelConnector } from "stores";
import SVG from 'components/svg/svg';

import HashtagsModel from "./hashtags.model";

import classNames from 'classnames/bind';
import styles from './hashtags.scss';
import { PlatformIconMap, PlatformLabelMap } from 'utils/utils';

const cx = classNames.bind(styles);

const PlatformHashtagCount = observer(({ platform_hashtags, setCount }) => (
	<div style={{ marginTop: '1em'}}>
		<label className="popup-menu-title">Hashtag limit per post</label>
		<div className="m-1" >
			{platform_hashtags.map((platform_hashtag) => {
				return (
				<div className="popup-menu-container">
					<div className="popup-menu-line">
						<SVG name={PlatformIconMap.get(platform_hashtag.platform)} size={20} className="mr-1" />
						<span className="popup-menu-platform">{PlatformLabelMap.get(platform_hashtag.platform)}</span>
					</div>
					<div className="popup-menu-line">
						<label className="popup-menu-share">share</label>
						<Input className="popup-menu-input" type="number" placeholder="select" min={0} onChange={(e) => setCount(platform_hashtag, e.target.value)}></Input>
						<label className="popup-menu-label">hashtag(s) per post</label>
					</div>
				</div>
				)})
		}
		</div>
	</div>

));

const Hashtag = observer(({ hashtag, index }) => (
	<Draggable draggableId={hashtag.id} index={index}>
		{(provided) => (
			<div
				className={cx('draggable-item')}
				ref={provided.innerRef}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
			>
				<Observer>{() => (<>
					<SVG className="text-neutral-2" name="drag_indicator" />
					<Input
						value={hashtag.tag}
						onChange={(e) => hashtag.setTag(e.target.value)}
						size="small"
						inputStyle={{ backgroundColor: 'transparent'}}
						hidden
					/>
					<Button type="text" icon="delete" size="small" iconSize={14} onClick={hashtag.deleteHashtag} />
				</>)}</Observer>
			</div>
		)}
	</Draggable>
));

const RenderedExpandedView = observer(({ hashtagGroup }) => {
	return (
		<div className={cx('draggable-list')} key={hashtagGroup.orderedHashtags.length}>
			<DragDropContext onDragEnd={hashtagGroup.onDragEnd}>
				<Droppable droppableId="list">
					{(provided) => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							{hashtagGroup.orderedHashtags.map((hashtag, index) => (
								<Hashtag hashtag={hashtag} index={index} key={hashtag.id} />
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</div>
	);
});

const HashtagsView = observer(({ model }) => {

	return (
		<Container title="Hashtags" titleSize={1} titleIcon={'tag'} titleWeight={400} isLoading={model.isLoading}
			actions={[
				<div className={classNames('show-desktop-only')}>
					<Input
						key="search-bar"
						size="small"
						icon="search"
						value={model.searchTerm}
						onChange={(e) => model.setSearchTerm(e.target.value)}
						inputStyle={{ backgroundColor: 'transparent'}}
						style={{ width: 400, height: '100%' }}
					/>
				</div>,
				<Button className={classNames('show-desktop-only')} type="hollow" icon="add" onClick={model.addHashtagGroup}>Add new group</Button>,
				<Popover
					key="hashtag-platform-settings"
					content={<PlatformHashtagCount platform_hashtags={model.platformHashtagCounts} setCount={model.setPlatformHashtagCount}/>}
					contentClass={cx('platforms-settings-popup')}
				>
					<Button size="small" iconSize={16} type="secondary" shape="hollow" icon="build">Settings</Button>
				</Popover>,
			]}
		>
			<div className={classNames('show-mobile-only')}>
				<div className={classNames('hashtag-search-bar')} >
					<Input
						key="search-bar-mobile"
						size="small"
						icon="search"
						value={model.searchTerm}
						onChange={(e) => model.setSearchTerm(e.target.value)}
						inputStyle={{ backgroundColor: 'transparent', height: 36 }}
						style={{ width: '55%' }}
					/>
					<Button type="hollow" icon="add" onClick={model.addHashtagGroup}>Add new group</Button>
				</div>
			</div>
			<Container.Column span={12}>
				<NewEntity
					placeholder="add new hashtag text"
					confirmText="Create new hashtag"
					icon="add"
					valueKey="tag"
					inputClass={cx('new-hashtag-input')}
					options={[{
						key: 'hashtag_group_ids',
						type: 'multi-select',
						placeholder: 'select hashtag group(s)',
						className: cx('new-hashtag-select'),
						menuClass: cx('new-hashtag-menu'),
						options: [
							{
								label: <div onClick={model.addHashtagGroup} style={{ cursor: 'pointer' }}>New Hashtag Group</div>,
								value: 'new-group',
								disabled: true,
								header: true,
							},
							...model.hashtagGroups.map((group) => ({
								label: group.name,
								value: group.id,
							}))
						],
					}]}
					buttonClass={cx('new-hashtag-button')}
					onSubmit={model.addHashtag}
				/>
			</Container.Column>
			<Container.Column span={12}>
				<List rows={model.hashtagGroups.slice().map((hashtagGroup) => ({
					id: hashtagGroup.id,
					title: <Input
						value={hashtagGroup.name}
						onChange={(e) => hashtagGroup.setName(e.target.value)}
						onClick={((e) => e.stopPropagation())}
						size="small"
						className="hashtag-group-name"
						hidden
					/>,
					actions: <Button type="text" icon="delete" iconSize={16} size="small" onClick={hashtagGroup.deleteHashtagGroup} />,
					expandedView: <RenderedExpandedView hashtagGroup={hashtagGroup} addHashtag={model.addHashtag} />,
				}))} />
			</Container.Column>
			<Info infoKey="hashtags" />
		</Container>
	);
});

export default ModelConnector(HashtagsView, HashtagsModel);

import request from 'api/request';
import { Button } from 'components';
import { destroy, flow, types } from 'mobx-state-tree';

import { Modal } from 'components';
import { OrganizationModel } from 'models/organization.model';
import { Link } from 'react-router-dom';
import { OrganizationPaymentStatusMap, OrganizationRoleMap } from 'utils/utils';

const { confirm, message } = Modal;
const { model, array, boolean, string, optional } = types;
// Model
const OrganizationsViewModel = model('OrganizationsViewModel', {
	isLoading: optional(boolean, true),
	organizations: array(OrganizationModel),
	showNewOrganization: optional(boolean, false),
	organizationName: optional(string, ''),
})
	.views((self) => ({
		get baseURL() {
			return '/organizations';
		},
		get organizationsList() {
			return self.organizations.map((organization) => ({
				id: organization.id,
				title: <div>
					{organization.name}
					<br />
					<span style={{ fontSize: 11 }}>
					{OrganizationRoleMap.get(organization.role)} - {OrganizationPaymentStatusMap.get(organization.payment_status)}
					</span>
				</div>,
				actions: organization.role === 'OWNER' ? [
					<Link key="organization_settings" className="flex-center" to={`/organization-settings/${organization.id}`}>
						<Button icon="settings" type="text" iconSize={16} />
					</Link>,
					<Button key="organization_delete" icon="delete" type="text" style={{ marginRight: '1em' }} iconSize={16} onClick={() => self.deleteOrganization(organization)} />,
				] : [],
			}))
		},
	}))
	.actions((self) => ({
		afterCreate() {
			self.fetchOrganizations();
		},
		fetchOrganizations: flow(function* () {
			const { data } = yield request.get(self.baseURL);
			self.organizations = data;
			self.isLoading = false;
		}),
		deleteOrganization: flow(function* (organization) {
			const confirmDelete = yield confirm({ text: 'Are you sure you want to delete this organization? You cannot undo these changes', okText: 'Delete' });
			if (!confirmDelete) return;

			yield request.delete(`${self.baseURL}/${organization.id}`);
			destroy(organization);
			message({ type: 'success', text: 'Successfully deleted organization.' });
			self.AuthStore.fetchSession();
		}),
		onSubmitOrganizationName: flow(function* () {
			if (!self.organizationName) {
				message({ type: 'error', text: 'Please provide an organization name.'});
				return;
			}

			yield request.post('/organizations', {
				organization_name: self.organizationName,
			});

			self.organizationName = '';
			self.fetchOrganizations();
			self.setShowNewOrganization(false);
			message({ type: 'success', text: 'Successfully created organization.' });
		}),
		setShowNewOrganization(showNewOrganization) {
			self.showNewOrganization = showNewOrganization;
		},
		setOrganizationName(organizationName) {
			self.organizationName = organizationName;
		}
	}));

export default OrganizationsViewModel;

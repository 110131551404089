import axios from 'axios';
import { Modal } from 'components';

const { message } = Modal;
const baseURL = process.env.REACT_APP_API_URL;

const instance = axios.create({
	baseURL: `${baseURL}/api`,
	withCredentials: true,
});

// instance.interceptors.request.use(
//     (config) => {
//         config.headers['x-app-version'] = '3.0.0';
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

instance.interceptors.response.use(
	(response) => response, // Success
	(error) => {
		if(error.response.status === 401) {
			message({ type: 'error', text: 'Your session has expired. Please login again', code: 401 });
		} else {
			message({ type: 'error', text: error.response.data.message || error.message });
			throw error;
		}
	},
);

export default instance;

import request from 'api/request';
import { destroy, flow, getParent, getSnapshot, types } from 'mobx-state-tree';
import { PlatformEnum } from 'models/global/global.model';
import { UserModel } from 'models/user.model';
import debounce from 'lodash.debounce';
import { Modal } from 'components';
import { PlatformLabelMap, closePopover, PlatformOrderList, IsSocialMediaPlatform } from 'utils/utils';

const { message, confirm } = Modal;
const { array, boolean, model, enumeration, identifier, maybeNull, number, safeReference, string } = types;

const StatusModel = model('StatusModel', {
	id: identifier,
	name: string,
	color: string,
	order: number,
	type: enumeration('StatusTypeEnum', ['ACTIVE', 'DONE', 'CLOSED']),
	needs_approval: boolean,
	assignee: maybeNull(UserModel),
	approver: maybeNull(UserModel),
})
	.views((self) => ({
		get baseURL() {
			return `${self.parent.baseURL}/${self.id}`;
		},
		get parent() {
			return getParent(self, 4);
		},
	}))
	.actions((self) => ({
		saveStatus: debounce(() => self.updateStatus(), 700),
		updateStatus: flow(function* (showSuccess = true) {
			try {
				yield request.put(self.baseURL, getSnapshot(self));

				if (showSuccess) {
					message({ type: 'success', text: 'Successfully updated status.' });
				}
			} catch (err) {
				console.log(err);
				throw err;
			} finally {
			}
		}),
		deleteStatus: flow(function* () {
			const confirmDelete = yield confirm({ text: 'Are you sure you want to delete this status?', okText: 'Delete' });
			if (!confirmDelete) return;

			try {
				yield request.delete(self.baseURL);
				self.parent.removeStatus(self);
				message({ type: 'success', text: 'Successfully deleted status.' });
			} catch (err) {
				console.log(err);
				throw err;
			} finally {
			}
		}),
		updateFields(e, status) {
			const users = self.parent.WorkspaceStore.current_workspace.users;
			const asignee = users.find(user => user.id === status.assignee_id);
			const approver = users.find(user => user.id === status.assignee_id);

			self.name = status.name.toUpperCase();
			self.toggleAssignee(asignee);
			self.toggleApprover(approver);
			self.color = status.color;
			self.needs_approval = status.needs_approval ? status.needs_approval : false;

			closePopover(e);
			self.saveStatus();
		},
		setName(name) {
			self.name = name.toUpperCase();
			self.saveStatus();
		},
		setColor(color, e) {
			self.color = color;
			closePopover(e);
			self.saveStatus();
		},
		setNeedsApproval(needs_approval) {
			self.needs_approval = needs_approval;
			self.saveStatus();
		},
		setType(type) {
			self.type = type;
			// self.saveStatus();
		},
		setAssignee(assignee) {
			self.assignee = assignee;
			self.saveStatus();
		},
		setApprover(approver) {
			self.approver = approver;
			self.saveStatus();
		},
		toggleAssignee(assignee) {
			if (self.assignee?.id === assignee.id) self.setAssignee(null);
			else self.setAssignee(getSnapshot(assignee));
		},
		toggleApprover(approver) {
			if (self.approver?.id === approver.id) self.setApprover(null);
			else self.setApprover(getSnapshot(approver));
		},
	}));

const StatusTemplateModel = model('StatusTemplateModel', {
	id: identifier,
	platforms: array(PlatformEnum),
	name: string,
	statuses: array(StatusModel),
}).views((self) => ({
	get baseURL() {
		return `${self.parent.baseURL}/templates/${self.id}`;
	},
	get parent() {
		return getParent(self, 2);
	},
	hasPlatform(value) {
		return self.platforms.includes(value) || (self.platforms.find((p) => IsSocialMediaPlatform(p)) && value === 'SOCIAL');
	},
	get filteredPlatforms() {
		const filtered = [];

		if(self.platforms.find((p) => IsSocialMediaPlatform(p))) filtered.push('SOCIAL');
		const platforms = [...self.platforms.filter((p) => !IsSocialMediaPlatform(p)), ...filtered].sort((a, b) => {
			const indexA = PlatformOrderList.findIndex(type => a === type);
			const indexB = PlatformOrderList.findIndex(type => b === type);
			return indexA - indexB;
		});
			  
	  if (self.parent.WorkspaceStore.current_workspace.primary_platform) {
			const index = platforms.findIndex(p => p === self.parent.WorkspaceStore.current_workspace.primary_platform);
			platforms.unshift(platforms.splice(index, 1)[0]);
		}

    return platforms;
	}
})).actions((self) => ({
	saveStatusTemplate: debounce(() => self.updateStatusTemplate(), 700),
	updateStatusTemplate: flow(function* () {
		try {
			yield request.put(self.baseURL, getSnapshot(self));

			message({ type: 'success', text: 'Successfully updated status template.' });
		} catch (err) {
			console.log(err);
			throw err;
		} finally {
		}
	}),
	setName(name) {
		self.name = name;
		self.saveStatusTemplate();
	},
	// Platforms
	addPlatform(value) {
		if(value === 'SOCIAL') {
			const found = self.platforms.find(p => IsSocialMediaPlatform(p));
			if(found) return;
		}
		self.platforms.push(value);
	},
	removePlatform(value) {
		if(value === 'SOCIAL') {
			const index = self.platforms.find(p => IsSocialMediaPlatform(p));
			if(index) {
        const filtered = self.platforms.filter(p => !IsSocialMediaPlatform(p));
        self.platforms.splice(0, self.platforms.length);
        filtered.forEach(p => self.platforms.push(p));
			}
		} else {
			const index = self.platforms.findIndex((platform) => platform === value);
			self.platforms.splice(index, 1);
		}
	},
	togglePlatform(value) {
		// Validate here
		if (self.parent.selectedPlatforms.includes(value) && !self.platforms.includes(value)){
			message({ type: 'error', text: `The platform "${PlatformLabelMap.get(value)}" already has a template`})
			return;
		}

		if (self.hasPlatform(value)) {
			self.removePlatform(value);
		} else {
			self.addPlatform(value);
		}

		self.saveStatusTemplate();
		self.parent.setCurrentPlatforms();
	},
}));

const CampaignStatusesViewModel = model('CampaignStatusesViewModel', {
	templates: array(StatusTemplateModel),
	selectedStatusTemplate: safeReference(StatusTemplateModel),
	selectedPlatforms: array(string),
	isLoading: false,
})
	.views((self) => ({
		get baseURL() {
			return `${self.props.workspace.baseURL}/settings/statuses`;
		},
		get activeStatuses() {
			return Array.from(self.selectedStatusTemplate.statuses.filter((status) => status.type === 'ACTIVE'))
				.slice()
				.sort((a, b) => a.order - b.order);
		},
		get doneStatuses() {
			return Array.from(self.selectedStatusTemplate.statuses.filter((status) => status.type === 'DONE'))
				.slice()
				.sort((a, b) => a.order - b.order);
		},
		get closedStatuses() {
			return Array.from(self.selectedStatusTemplate.statuses.filter((status) => status.type === 'CLOSED'))
				.slice()
				.sort((a, b) => a.order - b.order);
		},
		get availableUsers() {
			return self.WorkspaceStore.current_workspace.users.filter((user) => user.last_active && user.first_name && user.last_name);
		}
	}))
	.actions((self) => ({
		afterCreate() {
			self.fetchCampaignStatuses();
		},
		fetchCampaignStatuses: flow(function* () {
			try {
				const { data } = yield request.get(`${self.baseURL}/templates`);
				self.templates = data;
				if(data.length > 0) {
					self.setSelectedStatusTemplate(data[0].id);
					self.setCurrentPlatforms();
				}
			} catch (err) {
				console.error(err);
			} finally {
				self.isLoading = false;
			}
			self.saveState();
		}),
		deleteStatusTemplate: flow(function* (id) {
			const confirmDelete = yield confirm({ text: 'Are you sure you want to delete this status template?', okText: 'Delete' });

			if (!confirmDelete) return;
			self.selectedStatusTemplate = id;
			try {
				yield request.delete(`${self.baseURL}/templates/${id}`);
				destroy(self.selectedStatusTemplate);
				self.setCurrentPlatforms();
				message({ type: 'success', text: 'Successfully deleted status template.' });
			} catch (err) {
			} finally {
				self.isLoading = false;
			}
		}),
		addStatusTemplate: flow(function* (e, { name, platforms }) {
			try {
				const { data } = yield request.post(`${self.baseURL}/templates/`, { name, platforms });
				closePopover(e);
				
				self.templates.push(data);
				self.setCurrentPlatforms();
			} catch (err) {
			} finally {
				self.isLoading = false;
			}
		}),
		addStatus: flow(function* (e, status) {
			const maxOrder = Math.max(...self.activeStatuses.map(({ order }) => order), 0);

			const newStatus = {
				...status,
				type: 'ACTIVE',
				order: maxOrder + 1,
				status_template_id: self.selectedStatusTemplate.id,
				assignee_id: status.assignee_id,
			};

			try {
				const { data } = yield request.post(self.baseURL, newStatus);
				
				closePopover(e);
				self.selectedStatusTemplate.statuses = [...self.selectedStatusTemplate.statuses, data];
				message({ type: 'success', text: 'Successfully added status.' });
			} catch (err) {
				console.log(err);
			} finally {
				self.isLoading = false;
			}
		}),
		removeStatus(status) {
			destroy(status);
		},
		onDragEnd(result) {
			if (!result.destination) {
				return;
			}

			const statusToUpdate = self.selectedStatusTemplate.statuses.find((status) => status.id === result.draggableId);

			const sourceStatuses = {
				ACTIVE: self.activeStatuses,
				CLOSED: self.closedStatuses,
				DONE: self.doneStatuses,
			}[result.source.droppableId];

			const destinationStatuses = {
				ACTIVE: self.activeStatuses,
				CLOSED: self.closedStatuses,
				DONE: self.doneStatuses,
			}[result.destination.droppableId];

			const statusesToUpdate = [];
			statusToUpdate.setType(result.destination.droppableId);

			const oldOrder = result.source.index;
			const newOrder = result.destination.index;

			if (result.source.droppableId !== result.destination.droppableId) {
				// Different boxes
				destinationStatuses.splice(newOrder, 0, sourceStatuses[oldOrder]);
				sourceStatuses.splice(oldOrder, 1);

				sourceStatuses.forEach((item, idx) => { item.order = idx; statusesToUpdate.push(item) });
				destinationStatuses.forEach((item, idx) => { item.order = idx; statusesToUpdate.push(item) });
			} else {
				// Within the same box
				const newStatus = destinationStatuses[oldOrder];
				destinationStatuses.splice(oldOrder, 1);
				destinationStatuses.splice(newOrder, 0, newStatus);

				destinationStatuses.forEach((item, idx) => { item.order = idx; statusesToUpdate.push(item) });
			}

			for (const status of statusesToUpdate) {
				status.updateStatus(false);
			}

			message({ type: 'success', text: 'Successfully moved status.' });
		},
		// SETTERS
		setIsLoading(isLoading) {
			self.isLoading = isLoading;
		},
		setCurrentPlatforms() {
			const platforms = new Set();
			for (const template of self.templates) {
				for (const platform of template.platforms) {
					platforms.add(platform);
				}
			}

			self.selectedPlatforms = Array.from(platforms);
			return self.selectedPlatforms;
		},
		setSelectedStatusTemplate(id) {
			self.selectedStatusTemplate = id;
		},
	}));

export default CampaignStatusesViewModel;

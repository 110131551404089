import { destroy, flow, getParent, getSnapshot, types } from 'mobx-state-tree';
import { PlatformEnum } from 'models/global/global.model';
import request from 'api/request';
import debounce from 'lodash.debounce';

import { Modal } from 'components';

import { rootStore } from 'stores';
import { closePopover } from 'utils/utils';

const { message } = Modal;
const { array, boolean, string, model, number, optional } = types;

export const TaskModel = model('TaskModel', {
	id: string,
	platform: PlatformEnum,
	name: string,
	order: number,
})
	.views((self) => ({
		get baseURL() {
			return `${rootStore.WorkspaceStore.current_workspace.baseURL}/settings/tasks/${self.id}`;
		},
		get parent() {
			return getParent(self, 2);
		},
	}))
	.actions((self) => ({
		saveTask: debounce(() => self.updateTask(), 700),
		deleteTask: flow(function* () {
			try {
				yield request.delete(self.baseURL);
				self.parent.removeTask(self);
				message({ type: 'success', text: 'Successfully deleted task.' });
			} catch (err) {
			} finally {
			}
		}),
		updateTask: flow(function* () {
			try {
				yield request.put(self.baseURL, getSnapshot(self));
				message({ type: 'success', text: 'Successfully updated task.' });
			} catch (err) {
			} finally {
			}
		}),
		setName(name) {
			self.name = name;
			self.saveTask();
		}
	}));

// Model
const TasksModel = model('TasksModel', {
	tasks: array(TaskModel),
	isLoading: optional(boolean, true),
})
	.views((self) => ({
		get baseURL() {
			return `${self.workspace.baseURL}/settings/tasks`;
		},
		get workspace() {
			return self.props.workspace;
		},
		get orderedTasks() {
			return Array.from(self.tasks).sort((a, b) => a.order - b.order);
		},
		filteredTasks(platform) {
			return self.orderedTasks.filter((task) => task.platform === platform);
		}
	}))
	.actions((self) => ({
		afterCreate() {
			self.fetchTasks();
		},
		addTask: flow(function* (e, name, platform) {
			const maxOrder = Math.max(...self.filteredTasks(platform).map(({ order }) => order), 0);
			const task = { name, platform, order: maxOrder + 1 };
			try {
				const { data } = yield request.post(self.baseURL, task);
				self.tasks.push(data);
				closePopover(e);
				message({ type: 'success', text: 'Successfully created task.' });
			} catch (err) {
				console.error(err);
			} finally {
				self.isLoading = false;
			}
		}),
		fetchTasks: flow(function* () {
			try {
				const { data } = yield request.get(self.baseURL);
				self.tasks = data;
			} catch (err) {
			} finally {
				self.isLoading = false;
			}
		}),
		removeTask(task) {
			destroy(task);
		},
		onDragEnd(result, platform) {
			if (!result.destination) {
				return;
			}

			if (result.destination.index === result.source.index) {
				return;
			}

			const tasksToUpdate = [];

			if (result.source.index > result.destination.index) {
				// Coming from the bottom up
				const sourceTask = self.filteredTasks(platform).at(result.source.index);
				const destinationTask = self.filteredTasks(platform).at(result.destination.index);
				const temp = destinationTask.order;
				for (const task of self.filteredTasks(platform)) {
					if (task.order >= destinationTask.order && task.order < sourceTask.order) {
						task.order = task.order + 1;
						tasksToUpdate.push(task);
					}
				}
				sourceTask.order = temp;
				tasksToUpdate.push(sourceTask);
			} else {
				// Moving from the top down
				const sourceTask = self.filteredTasks(platform).at(result.source.index);
				const destinationTask = self.filteredTasks(platform).at(result.destination.index);
				const temp = destinationTask.order;
				for (const task of self.filteredTasks(platform)) {
					if (task.order <= destinationTask.order && task.order > sourceTask.order) {
						task.order = task.order - 1;
						tasksToUpdate.push(task);
					}
				}
				sourceTask.order = temp;
				tasksToUpdate.push(sourceTask);
			}

			for (const task of tasksToUpdate) {
				task.updateTask();
			}
		},
	}));

export default TasksModel;

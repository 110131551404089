import request from 'api/request';
import { flow, types, getSnapshot } from 'mobx-state-tree';
import {PlatformEnum} from 'models/global/global.model';
import { CampaignModel } from 'models/campaign.model';
import { IdeaModel } from 'models/idea.model';
import {PlatformOrderList} from "../../../utils/utils";

const {array, map, boolean, identifier, union, maybeNull, model, optional, string, Date: mobxDate} = types;

// Model
const PlatformEventModel = model('PlatformEventModel', {
  platform: PlatformEnum,
  statusColor: maybeNull(string)
})

const EventModel = model('EventModel', {
  id: identifier,
  statusColor: maybeNull(string),
  name: string,
  event_link: string,
  platforms: array(PlatformEventModel)
}).actions(self => ({
  setPlatforms(platforms) {
    self.platforms = platforms;
  }
}));

const DashboardModel = model('DashboardModel', {
	campaigns: array(CampaignModel),
	recent_activity: array(union(CampaignModel, IdeaModel)),
	events: map(array(EventModel)),
	isLoading: optional(boolean, true),
	selectedDate: optional(mobxDate, new Date()),
  selectedDateEvents: array(EventModel),
})
	.views((self) => ({
		get baseURL() {
			return self.WorkspaceStore.current_workspace.baseURL;
		},
		get needsApprovalList() {
			const needsApproval = [];
			const confirmNeedsApproval = (status) => {
				return (
					status?.needs_approval &&
					self.AuthStore.user.id === status.assignee?.id
				)
			};

			for (const campaign of self.campaigns) {
				for (const campaign_platform of campaign.campaign_platforms) {
					if (confirmNeedsApproval(campaign_platform?.status)) {
						needsApproval.push({
							id: campaign_platform.id,
							campaign_link: `/campaigns/${campaign.id}`,
							platform: campaign_platform.platform,
							title: campaign.idea.title,
							date: campaign_platform?.due_date,
						});
					}
				}

				if (confirmNeedsApproval(campaign?.campaign_pinterest?.status)) {
					needsApproval.push({
						id: campaign?.campaign_pinterest.id,
						campaign_link: `/campaigns/${campaign.id}`,
						platform: 'PINTEREST',
						title: campaign.idea.title,
						date: campaign.campaign_pinterest?.due_date,
					});
				}

				if (confirmNeedsApproval(campaign?.campaign_social?.status)) {
					needsApproval.push({
						id: campaign?.campaign_social?.id,
						campaign_link: `/campaigns/${campaign.id}`,
						platform: 'SOCIAL',
						title: campaign.idea.title,
						date: campaign.campaign_social?.due_date,
					});
				}
			}

			return needsApproval.sort((a, b) => new Date(b) - new Date(a));
		},
	}))
	.actions((self) => ({
		afterCreate() {
			self.fetchCampaigns();
			self.fetchRecentActivity();
		},
		fetchCampaigns: flow(function* () {
			try {
				const { data } = yield request.get(`${self.baseURL}/campaigns`);

				self.setCampaigns([]);
        self.setCampaigns(data);
				self.setSelectedDate(new Date());
        self.events.clear();
        self.setupEvents();
			} catch (err) {
				console.error(err);
			} finally {
				self.isLoading = false;
			}
		}),
		fetchRecentActivity: flow(function* () {
			try {
				const { data } = yield request.get(`${self.baseURL}/dashboard/recent-activity`);
				self.recent_activity = data;
			} catch (err) {
				console.error(err);
			} finally {
			}
		}),
		setCampaigns(data) {
			self.campaigns = data;
		},
		goToCampaign(campaign) {
			self.props.navigate(`/campaigns/${campaign.id}`)
		},
		addEvent(key, event) {
			const foundEvent = self.events.get(key);
			if (foundEvent) {
				let hasPushed = false;
				for (const loopedEvent of foundEvent) {
					const {color, name, platforms} = loopedEvent;
					if (event.statusColor === color && event.name === name) {
						loopedEvent.setPlatforms(
							Array.from(new Set([...platforms, ...event.platforms]))
						);
						hasPushed = true;
					}
				}
				if (!hasPushed) foundEvent.push(event);
			} else {
				self.events.set(key, [event]);
			}
		},
		setupEvents() {
			for (const campaign of self.campaigns) {
				let eventsByDueDate = new Map();
	
				for (const campaign_platform of campaign.campaign_platforms) {
					const key = new Date(campaign_platform.due_date).toDateString();
					const event = {
						id: campaign_platform.id,
						event_link: `/campaigns/${campaign.id}`,
						name: campaign.idea.title,
						platforms: [{
							platform: campaign_platform.platform,
							statusColor: campaign_platform.status?.color || null
						}],
					};
					if (eventsByDueDate.has(key)) {
						const ev = eventsByDueDate.get(key);
						if (!ev.platforms.find(p => p.platform === campaign_platform.platform)) {
							eventsByDueDate.set(key, {
								...ev, platforms: [...ev.platforms, {
									platform: campaign_platform.platform,
									statusColor: campaign_platform.status?.color || null
								}]
							});
						}
					} else {
						eventsByDueDate.set(key, event);
					}
				}
				for (const social_post of campaign.campaign_social?.social_posts ||
				[]) {
					const key = new Date(social_post.due_date).toDateString();
					const event = {
						id: social_post.id,
						event_link: `/campaigns/${campaign.id}`,
						name: campaign.idea.title,
						platforms: Array.from(campaign.campaign_social?.platforms || []).map(p => {
							return {
								platform: p,
								statusColor: campaign.campaign_social.status?.color || null
							}
						}),
					};
					if (eventsByDueDate.has(key)) {
						const ev = eventsByDueDate.get(key);
						if (campaign.campaign_social?.platforms && !ev.platforms.some(item => campaign.campaign_social.platforms.includes(item.platform))) {
							eventsByDueDate.set(key, {
								...ev, platforms: [...ev.platforms, ...Array.from(campaign.campaign_social?.platforms || []).map(p => {
									return {
										platform: p,
										statusColor: campaign.campaign_social.status?.color || null
									}
								})]
							});
						}
					} else {
						eventsByDueDate.set(key, event);
					}
					// self.addEvent(key, event);
				}
	
				for (const pinterest_pin of campaign.campaign_pinterest?.pins || []) {
					const key = new Date(pinterest_pin.due_date).toDateString();
					const event = {
						id: pinterest_pin.id,
						event_link: `/campaigns/${campaign.id}`,
						name: campaign.idea.title,
						platforms: [{
							platform: 'PINTEREST',
							statusColor: campaign.campaign_pinterest.status?.color || null
						}],
					};
	
					if (eventsByDueDate.has(key)) {
						const ev = eventsByDueDate.get(key);
						if (campaign.campaign_social?.platforms && !event.platforms.includes('PINTEREST')) {
							eventsByDueDate.set(key, {
								...ev, platforms: [...ev.platforms, {
									platform: 'PINTEREST',
									statusColor: campaign.campaign_pinterest.status?.color || null
								}]
							});
						}
					} else {
						eventsByDueDate.set(key, event);
					}
				}

				for (const key of eventsByDueDate.keys()) {
					const event = eventsByDueDate.get(key);
					const platformsSorted = event.platforms.sort((a, b) => {
						const indexA = PlatformOrderList.findIndex(type => a.platform === type);
						const indexB = PlatformOrderList.findIndex(type => b.platform === type);
						return indexA - indexB; // to get positive, 0, negative number for the sort callback.
					});
					self.addEvent(key, {...event, platforms: platformsSorted})
	
					if(key === self.selectedDate.toDateString()) {
						self.selectedDateEvents.push(event);
					}
				}
			}
		},
		setSelectedDate(date){
			self.selectedDate = date;
		},
		setSelectedDateEvents() {
			self.selectedDateEvents = [];
	
			const evts = self.events.get(self.selectedDate.toDateString());
			evts.forEach((evt) => self.selectedDateEvents.push(getSnapshot(evt)));
		},
		setFooterEvents(date) {
			self.setSelectedDate(date);
			self.setSelectedDateEvents();
		},
	}));

export default DashboardModel;

import React, { Component } from 'react';

import './toggle.scss';
import classNames from 'classnames';
import { Button } from 'components';

export default class Toggle extends Component {
	constructor(props) {
		super(props);
		const initialValue = props.defaultValue || props.options[0].value;

		this.state = { selectedValue: initialValue };
	}

	setSelectedValue = (selectedValue) => {
		this.setState({
			selectedValue,
		});
	};

	onToggle = (value) => {
		this.setSelectedValue(value);
		this.props.onToggle(value);
	};

	render() {
		const { className, options } = this.props;

		return (
			<div className={classNames('tejik-toggle', className)}>
				{options.map((option) => (
					<Button
						key={option.value}
						className={classNames('tejik-toggle-option', {
							'tejik-toggle-option-selected': this.state.selectedValue === option.value,
						})}
						type="secondary"
						shape="hollow"
						onClick={() => this.onToggle(option.value)}
					>
						{option.label}
					</Button>
				))}
			</div>
		);
	}
}

import React, {Component} from 'react';
import classNames from 'classnames';

export default class Input extends Component {
  render() {
    const {className, type, center} = this.props;

    return (
      <div className={classNames('tejik-loader', type && `tejik-loader-${type}`, className)}
           style={center && {margin: 'auto'}}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    );
  }
}

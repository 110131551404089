import { Button, Container, Info, Input, List, NewEntity } from "components";
import SVG from 'components/svg/svg';
import { Observer, observer } from 'mobx-react-lite';
import { ModelConnector } from "stores";

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import HooksModel from "./hooks.model";

import classNames from 'classnames/bind';
import styles from './hooks.scss';

const cx = classNames.bind(styles);

const Hook = observer(({ hook, index }) => (
	<Draggable draggableId={hook.id} index={index}>
		{(provided) => (
			<div
				className={cx('draggable-item')}
				ref={provided.innerRef}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
			>
				<Observer>{() => (<>
					<SVG className="text-neutral-2" name="drag_indicator" />
					<Input
						value={hook.name}
						onChange={(e) => hook.setName(e.target.value)}
						size="small"
						inputStyle={{ backgroundColor: 'transparent'}}
						hidden
					/>
					<Button type="text" icon="delete" size="small" iconSize={16} onClick={hook.deleteHook} />
				</>)}</Observer>
			</div>
		)}
	</Draggable>
));

const HooksList = observer(({ hooks }) => {
	return hooks.map((hook, index) => <Hook hook={hook} index={index} key={hook.id} />);
});

const RenderedExpandedView = observer(({ hooks, onDragEnd }) => {
	return (
		<div className={cx('draggable-list')}>
			<DragDropContext onDragEnd={(e) => onDragEnd(e)}>
				<Droppable droppableId="list">
					{(provided) => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							<HooksList hooks={hooks} />
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</div>
	);
});

const Hooks = observer(({ model }) => {
	return (
		<Container title="Hooks" titleSize={1} titleIcon={'phishing'} titleWeight={400} isLoading={model.isLoading} actions={[
			<div className={classNames('show-desktop-only')}>
				<Input
					key="search-bar"
					size="small"
					icon="search"
					value={model.searchTerm}
					onChange={(e) => model.setSearchTerm(e.target.value)}
					inputStyle={{ backgroundColor: 'transparent'}}
					style={{ width: 400, height: '100%' }}
				/>
			</div>,
			<Button type="hollow" icon="add" onClick={model.addHookGroup}>Add new group</Button>
			]}>
			<div className={classNames('show-mobile-only')}>
				<Input
					key="search-bar-mobile"
					size="small"
					icon="search"
					value={model.searchTerm}
					onChange={(e) => model.setSearchTerm(e.target.value)}
					inputStyle={{ backgroundColor: 'transparent', height: 36 }}
					style={{ width: '100%' }}
				/>
			</div>
			<Container.Column span={12}>
				<NewEntity
					placeholder="add new hook text"
					confirmText="Create new hook"
					icon="add"
					valueKey="name"
					inputClass={cx('new-hook-input')}
					options={[{
						key: 'hook_group_id',
						placeholder: 'select hook group(s)',
						type: 'multi-select',
						className: cx('new-hook-select'),
						menuClass: cx('new-hook-menu'),
						options: [
							{
								label: <div onClick={model.addHookGroup} style={{ cursor: 'pointer' }}>New Hook Group</div>,
								value: 'new-group',
								disabled: true,
								header: true,
							},
							...model.hookGroupOptions,
						]
					}]}
					buttonClass={cx('new-hook-button')}
					onSubmit={model.addHook}
				/>
			</Container.Column>
			<Container.Column span={12}>
				<List rows={model.hookGroups.map((hookGroup) => ({
					id: hookGroup.id,
					title: <Input
						value={hookGroup.name}
						onChange={(e) => hookGroup.setName(e.target.value)}
						onClick={((e) => e.stopPropagation())}
						size="small"
						className="hook-group-name"
						hidden
					/>,
					actions: <Button type="text" icon="delete" size="small" iconSize={16} onClick={(e) => {
						e.stopPropagation()
						hookGroup.deleteHookGroup()
					}} />,
					expandedView: <RenderedExpandedView hooks={hookGroup.orderedHooks} onDragEnd={hookGroup.onDragEnd} />,
				}))} />
			</Container.Column>
			<Info infoKey="hooks"/>
		</Container>
	);
});

export default ModelConnector(Hooks, HooksModel);

import { types, flow } from 'mobx-state-tree';
import request from 'api/request';

const { string, model, optional } = types;
// Model
const LoginModel = model('LoginModel', {
	email: optional(string, ''),
	password: optional(string, ''),	
})
.actions((self) => ({
	signIn: flow(function* () {
		try {
			const res = yield request.post('/auth/login', { email: self.email, password: self.password });
			if (!res) return;
			self.AuthStore.fetchSession();
			self.props.navigate('/');
		} catch (err) {
			console.error(err);
		}
	}),
	// Setters
	setEmail(email) {
		self.email = email;
	},
	setPassword(password) {
		self.password = password;
	}
}));

export default LoginModel;
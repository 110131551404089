import request from 'api/request';
import debounce from 'lodash.debounce';
import { Modal } from 'components';

import { PlatformEnum } from 'models/global/global.model';
import { flow, getParent, getSnapshot, types } from 'mobx-state-tree';
import { rootStore } from 'stores';

const { confirm, message } = Modal;
const { enumeration, string, model } = types;

export const ContentLinkTypeEnum = enumeration('ContentLinkIconEnumeration', ['DOCUMENT', 'PHOTO', 'VIDEO', 'FILE']);

export const ContentLinkModel = model('ContentLinkModel', {
	id: string,
	workspace_id: string,
	platform: PlatformEnum,

	name: string,
	type: ContentLinkTypeEnum,
}).views((self) => ({
	get baseURL() {
		return `${rootStore.WorkspaceStore.currentBaseURL}/settings/content-links/${self.id}`;
	},
	get parent() {
		return getParent(self, 2);
	},
	get icon() {
		return {
			'': '',
			'DOCUMENT': 'description',
			'PHOTO': 'image',
			'VIDEO': 'movie',
			'FILE': 'folder',
		}[self.type];
	},
}))
.actions((self) => ({
	saveContentLink: debounce(() => self.updateContentLink(), 700),
	deleteContentLink: flow(function* () {
		try {
			const confirmDelete = yield confirm({
				text: 'Are you sure you want to delete this content link?',
				children: `If you continue, all created content links under '${self.name}', will be removed.`,
				okText: 'Delete'
			});
			if (!confirmDelete) return;
			
			yield request.delete(self.baseURL);
			self.parent.removeContentLink(self);
			message({ type: 'success', text: 'Successfully deleted content link.' });
		} catch (err) {
		} finally {
		}
	}),
	updateContentLink: flow(function* () {
		try {
			yield request.put(self.baseURL, getSnapshot(self));
			message({ type: 'success', text: 'Successfully updated content link.' });
		} catch (err) {
		} finally {
		}
	}),
	setName(name) {
		self.name = name;
		self.saveContentLink();
	},
	setType(type) {
		self.type = type;
		self.saveContentLink();
	},
}));

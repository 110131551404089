import {useDraggable} from '@dnd-kit/core';
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./ideas-drawer.scss";
import SVG from "../../../../components/svg/svg";
import React from "react";

const cx = classNames.bind(styles);

const IdeasItemView = (props) => {

  const {idea} = props;

  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: `idea-${idea.id}`,
    data: {
      idea: idea,
      type: 'idea-item'
    }
  });
  const style = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
  } : undefined;

  return (
    <div ref={setNodeRef}
         {...listeners}
         {...attributes}
         style={{width: "fit-content", ...style}}>
      <div
        className={cx('tejik-ideas-drawer-item')}
        style={{ border: '1px solid #B2B1B1' }}
      >
        <div style={{alignSelf: 'center'}}>
          <SVG name='drag_indicator' className={cx('text-neutral-2')} style={{ marginRight: 10 }} size={20}/>
        </div>
        <p className={cx('title')}>{idea.title}</p>
      </div>
    </div>

  )
}

IdeasItemView.propTypes = {
  idea: PropTypes.any
}
export default IdeasItemView;
import { observer } from 'mobx-react-lite';
import { ModelConnector } from 'stores';
import {
  Container, 
  Info, 
  Input, 
  List, 
  Switch, 
  Toggle, 
  SVG, 
  Title
} from 'components';
import CreateIdeaModal from 'modals/createIdea/createIdea';

import CampaignsModel from './campaigns.model';

import classNames from 'classnames/bind';
import styles from './campaigns.scss';
import FiltersModal from 'modals/filters/filters';

import {rootStore} from 'stores';
import { useEffect } from 'react';

import LinkedIdeasModal from 'modals/linkedIdeas/linkedIdeas';

const cx = classNames.bind(styles);

const Campaigns = observer(({ model }) => {
  useEffect(() => {
    rootStore.AuthStore.setCampaignPlatform('');
  }, []);

  return (
    <Container
      className='campaigns-container'
      title="Campaigns"
			headerClassName='show-desktop-only'
      isLoading={model.isLoading}
      actions={[
        <Switch label={'Me'} icon={'person'} onChange={model.toggleMeFilter} value={model.toggleMeFilterApplied}/>,
        <Input
          key="search-bar"
          icon="search"
          size="small"
          hasFilter={true}
          value={model.searchTerm}
          onFilterClick={model.filterModalClick}
          onChange={e => model.setSearchTerm(e.target.value)}
          style={{ width: 400 }}
          inputStyle={{ backgroundColor: 'transparent' }}
          filterCount={model.filterCount}
        />,
        <Toggle
          key="toggle_buttons"
          options={[
            { label: 'Active', value: 'active' },
            { label: 'Closed', value: 'closed' }
          ]}
          onToggle={model.setCampaignListState}
        />
      ]}
    >
      <div className='show-mobile-only'>
				<div style={{ display: 'flex', alignItems: 'center', marginBottom: '1em'}}>
					<SVG name='emoji_objects' style={{ marginRight: '0.5em'}} size={20} /><Title size={4}>Campaigns</Title>
				</div>
        <Input
          key="search-bar"
          icon="search"
          size="small"
          hasFilter={true}
          value={model.searchTerm}
          onFilterClick={model.filterModalClick}
          onChange={e => model.setSearchTerm(e.target.value)}
          inputStyle={{ backgroundColor: 'transparent', height: 48 }}
          filterCount={model.filterCount}
        />
			</div>
      <div className='show-mobile-only-flex' style={{ justifyContent: 'space-between'}}>
        <Switch label={'Me'} icon={'person'} onChange={model.toggleMeFilter} value={model.toggleMeFilterApplied}/>
        <Toggle
          key="toggle_buttons"
          options={[
            { label: 'Active', value: 'active' },
            { label: 'Closed', value: 'closed' }
          ]}
          onToggle={model.setCampaignListState}
        />
			</div>
      <List
        key={model.campaignListState}
        rows={model.campaignList}
        className={cx('campaigns-list')}
        scrollToView={true}
      />
      {model.visibleCreateIdeaModal && (
        <CreateIdeaModal
          onOk={model.hideCreateIdeaModal}
          onCancel={model.hideCreateIdeaModal}
          visible={model.visibleCreateIdeaModal}
        />
      )}
      {model.visibleFilterModal && (
        <FiltersModal
          onOk={model.filterModalClick}
          onCancel={model.filterModalClick}
          visible={model.visibleFilterModal}
		      filterType={'CAMPAIGNS'}
        />
      )}
      <LinkedIdeasModal
				onOk={model.hideLinkIdeasModal}
				onCancel={model.hideLinkIdeasModal}
				visible={model.visibleLinkIdeasModal}
				idea={model.openedCampaign?.idea}
			/>
      <Info infoKey="campaigns" />
    </Container>
  );
});

export default ModelConnector(Campaigns, CampaignsModel);

import { observer } from "mobx-react-lite";
import { useState, useEffect } from 'react';
import { ModelConnector } from "stores";
import { Button, Container, List, Title } from "components";
import SVG from "components/svg/svg";
import { rootStore } from "stores";
import EventLink from "../planner/calendar/event-link.view";
import DashboardModel from "./dashboard.model";

import classNames from "classnames/bind";
import styles from "./dashboard.scss";
import Calendar from "../planner/calendar/calendar.view";

const cx = classNames.bind(styles);

const Dashboard = observer(({ model }) => {
	const [selectedDate, setSelectedDate] = useState((new Date()).toDateString());

	useEffect(() => {
    rootStore.AuthStore.setCampaignPlatform('');
  }, []);

	const setSelectedDateEvents = (date) => {
		setSelectedDate(date.toDateString());
		model.setFooterEvents(date);
	};

	if (!rootStore.AuthStore.user.tutorial_completed_at) {
		return (
			<Container title="Welcome to Tejik 👋">
				<p className="mb-1 text-neutral-5">
					<i>Congrats on a new workspace!</i>
				</p>
				<iframe
					width="900"
					height="506"
					src="https://www.youtube.com/embed/huPXpbsenag?si=zJUeB0yj_GsDlUJL?controls=0"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowfullscreen
				></iframe>
				<Container.Column>
					<Button
						onClick={rootStore.AuthStore.user.setTutorialCompletedAt}
						style={{ width: "300px" }}
					>
						Done
					</Button>
				</Container.Column>
			</Container>
		);
	}

	return (
		<Container isLoading={model.isLoading}>
				<div className={cx("dashboard-card")}>
					<div style={{ display: 'flex', alignItems: 'center', marginBottom: '1em' }}>
						<SVG name="schedule" style={{ marginRight: '0.5em' }}></SVG>
						<Title size={6}>Recent activity</Title>
					</div>
					<List rows={model.recent_activity.map((activity) => ({
						id: activity.id,
						title: activity?.idea ? activity.idea.title : <>
							<SVG name="emoji_objects" size={14} />
							{activity.title}
						</>,
						onRowClick: () => model.props.navigate(activity?.idea ? `/campaigns/${activity.id}` : `/ideas/${activity.id}`)
					}))} />
				</div>
				<Calendar
					date={new Date()}
					events={model.events}
					weeksToShow={2}
					moveWeek
					fillHeight={false}
					selectedDate={selectedDate}
					clickHandler={setSelectedDateEvents}
				/>
				<div className={cx('show-mobile-only', 'today-tasks-view')}>
					<div className={cx('day-name')}>
						{model.selectedDate.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' })}
					</div>
					{model.selectedDateEvents.length > 0 ?
						model.selectedDateEvents.map((event) => <EventLink key={event.id} event={event} footer={true}/>) 
						:
						<div className={cx('day-name')} style={{ fontWeight: 500}}>{selectedDate === (new Date()).toDateString() ? 'No tasks due for today' : 'No tasks due for this date'}</div>
					}  
				</div>
				<div style={{ height: '1em' }}></div>
		</Container>
	);
});

export default ModelConnector(Dashboard, DashboardModel);

import React, { Component } from 'react';

import classNames from 'classnames';
import { Observer } from 'mobx-react-lite';

class Table extends Component {
	get columnKeys() {
		return this.props.columns.map((column) => column.key);
	}

	render() {
		const { columns, className, data, onRowClick, selectedId, style } = this.props;

		return (
			<table className={classNames('tejik-table', className)} style={style}>
				<thead>
					<tr>
						{columns.map((column, index) => (
						<th
							key={index}
							className={classNames({ 'tejik-table-cell-shrink': column.width === 'fit' })}
							style={column?.headerStyle && column?.headerStyle(null)}
						>
							{column.title}
						</th>))}
					</tr>
				</thead>
				<tbody>
					<Observer>{() =>
						data && Array.from(data).map((row) => (
							<tr key={row.id} className={classNames({ 'clickable-row': onRowClick, 'selected-row': row.id === selectedId })} onClick={onRowClick ? () => onRowClick(row) : null}>
								{columns.map((column, index) => (
									<td
										key={index}
										className={classNames({ 'tejik-table-cell-shrink': column.width === 'fit' }, column.className)}
										height={column.height}
										width={column.width === 'fit' ? null : column.width}
										style={column?.rowStyle && column?.rowStyle(row)}
									>
										{column.render ? <Observer>{() => column.render(row)}</Observer> : row[column.key]}
									</td>
								))}
							</tr>
						))
					}</Observer>
				</tbody>
			</table>
		);
	}
}

export default Table;
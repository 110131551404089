import request from 'api/request';
import { PlatformWidget } from 'components';
import {flow, types, destroy, getSnapshot} from 'mobx-state-tree';
import {CampaignModel} from 'models/campaign.model';
import { PlatformIconMap, PlatformLabelMap, PlatformOrderList } from 'utils/utils';
import { IsSocialMediaPlatform } from 'utils/utils';
import { Modal, Input } from 'components';
import { Observer } from 'mobx-react-lite';
import { rootStore } from 'stores';

const {boolean, maybeNull, model, optional, string} = types;
const {confirm, message} = Modal;

const CampaignsModel = model('CampaignsModel', {
  campaign: maybeNull(CampaignModel),
  isLoading: optional(boolean, true),
  preSelectedPlatform: optional(string, ''),
  selectedPlatform: optional(string, ''),
  newPinterestBoardName: optional(string, ''),
  visibleLinkIdeasModal: optional(boolean, false),
  visibleNotesModal: optional(boolean, false),
})
  .views(self => ({
    get baseURL() {
      return `${self.WorkspaceStore.current_workspace.baseURL}/campaigns/${self.props.params.campaign_id}`;
    },
    get sidemnuLinks() {
      return self.WorkspaceStore.current_workspace.content_links.filter((cl) => cl.platform === 'SIDEMENU');
    },
    get blogCampaign() {
      return self.campaign?.campaign_platforms.filter((cp) => cp.platform === 'BLOG')[0];
    },
    get isBlogSelected() {
      return self.selectedPlatform === PlatformLabelMap.get('BLOG');
    },
    get podcastCampaign() {
      return self.campaign?.campaign_platforms.filter((cp) => cp.platform === 'PODCAST')[0];
    },
    get isBPodcastSelected() {
      return self.selectedPlatform === PlatformLabelMap.get('PODCAST');
    },
    get emailCampaign() {
      return self.campaign?.campaign_platforms.filter((cp) => cp.platform === 'EMAIL')[0];
    },
    get isEmailSelected() {
      return self.selectedPlatform === PlatformLabelMap.get('EMAIL');
    },
    get socialCampaign() {
      return self.campaign?.campaign_social;
    },
    get isSocialSelected() {
      return self.selectedPlatform === PlatformLabelMap.get('SOCIAL');
    },
    get youtubeCampaign() {
      return self.campaign?.campaign_platforms.filter((cp) => cp.platform === 'YOUTUBE')[0];
    },
    get isYoutubeSelected() {
      return self.selectedPlatform === PlatformLabelMap.get('YOUTUBE');
    },
    get pinterestCampaign() {
      return self.campaign?.campaign_pinterest;
    },
    get pinterestKeywords() {
      return self.campaign.campaign_platforms.find(cp => cp.platform === 'BLOG')?.keyword_research?.keywords;
    },
    get isPinterestSelected() {
      return self.selectedPlatform === PlatformLabelMap.get('PINTEREST');
    },
    get workspacePlatforms() {
      const allowed = ['BLOG','EMAIL', 'PODCAST', 'YOUTUBE', 'PINTEREST', 'SOCIAL'];
      const result = [...self.WorkspaceStore.current_workspace.platforms.filter(p => allowed.includes(p)), 'SOCIAL'];

      const platformsSorted = result.sort((a, b) => {
        const indexA = PlatformOrderList.findIndex(type => a === type);
        const indexB = PlatformOrderList.findIndex(type => b === type);
        
        return indexA - indexB;
      });

      if (self.WorkspaceStore?.current_workspace?.primary_platform) {
        const index = platformsSorted.findIndex(p => p === self.WorkspaceStore.current_workspace.primary_platform);
        platformsSorted.unshift(platformsSorted.splice(index, 1)[0]);
      }
      
      return platformsSorted;
    },
    get platformArray() {
      if (!self.campaign) {
        return [];
      }

      const platforms = self.campaign?.campaign_platforms.map(p => { return p.platform });
      if(self.campaign?.campaign_social) platforms.push('SOCIAL');
      if(self.campaign?.campaign_pinterest) platforms.push('PINTEREST');
      
      return platforms;
    },
    get platformSlides() {
      if (!self.campaign) {
        return []
      }

      const platforms = [];
      if (self.campaign.campaign_platforms) {
        platforms.push(
          ...self.campaign?.campaign_platforms.map(campaign_platform => (
            <PlatformWidget style={{ marginRight: '1em', marginBottom: '1em', cursor: 'pointer' }}
              item={{
                sponsored: !!campaign_platform.sponsored_content,
                channel: PlatformIconMap.get(campaign_platform.platform),
                platform: PlatformLabelMap.get(campaign_platform.platform),
                percentage: campaign_platform.percentage_complete,
                due_date: campaign_platform.formated_due_date,
                raw_date: campaign_platform.due_date,
                recycled: !!campaign_platform.recycle_at,
                status: campaign_platform?.status,
                code: campaign_platform.platform
              }} 
              showOptions={true}
              selectedPlatform={self.selectedPlatform}
              onSelect={campaign_platform.setStatus}
              onDateSelect={campaign_platform.setDueDate}
              onClick={() => self.setSelectedPlatform(PlatformLabelMap.get(campaign_platform.platform))}
            />
          ))
        );
      }

      if (self.campaign.campaign_social) {
        platforms.push(
          <PlatformWidget style={{ marginRight: '1em', marginBottom: '1em', cursor: 'pointer' }}
            item={{
              channel: PlatformIconMap.get('SOCIAL'),
              platform: PlatformLabelMap.get('SOCIAL'),
              percentage: self.campaign?.campaign_social.percentage_complete,
              due_date: self.campaign?.campaign_social.formated_due_date,
              recycled: !!self.campaign?.campaign_social.recycle_at,
              status: self.campaign?.campaign_social?.campaign_status,
            }} 
            selectedPlatform={self.selectedPlatform}
            onClick={() => self.setSelectedPlatform(PlatformLabelMap.get('SOCIAL'))}
          /> 
        );
      }

      if (self.campaign.campaign_pinterest) {
        platforms.push(
          <PlatformWidget style={{ marginRight: '1em', marginBottom: '1em', cursor: 'pointer' }}
            item={{
              channel: PlatformIconMap.get('PINTEREST'),
              platform: PlatformLabelMap.get('PINTEREST'),
              percentage: self.campaign?.campaign_pinterest.percentage_complete,
              due_date: self.campaign?.campaign_pinterest.formated_due_date,
              recycled: !!self.campaign?.campaign_pinterest.recycle_at,
              status: self.campaign?.campaign_pinterest?.campaign_status,
            }} 
            selectedPlatform={self.selectedPlatform}
            onClick={() => self.setSelectedPlatform(PlatformLabelMap.get('PINTEREST'))}
          /> 
        );
      }

      const platformsSorted = platforms.sort((a, b) => {
        const indexA = PlatformOrderList.findIndex(type => a.props.item.platform === PlatformLabelMap.get(type));
        const indexB = PlatformOrderList.findIndex(type => b.props.item.platform === PlatformLabelMap.get(type));
        return indexA - indexB;
      });

      if (self.WorkspaceStore?.current_workspace?.primary_platform) {
        const index = platformsSorted.findIndex(p => p.props.item.platform === PlatformLabelMap.get(self.WorkspaceStore.current_workspace.primary_platform));
        platformsSorted.unshift(platformsSorted.splice(index, 1)[0]);
      }

      const platformsClosed = platformsSorted.sort((a, b) => {
        if (a.props.item.status?.type === 'CLOSED' && b.props.item.status?.type !== 'CLOSED')  return 1; 
        else if (a.props.item.status?.type !== 'CLOSED' && b.props.item.status?.type === 'CLOSED') return -1;
        return 0;
      });

      if(platformsClosed[platformsClosed.length -1]) platformsClosed[platformsClosed.length -1].props.style.marginRight = 0;
      return platformsClosed;
    }
  }))
  .actions(self => ({
    afterCreate() {
      self.fetchCampaign();
    },
    fetchCampaign: flow(function* () {
      self.isLoading = true;
      try {
        const {data} = yield request.get(self.baseURL);

        self.campaign = null;
        self.campaign = data;

        const workspace = self.WorkspaceStore.current_workspace.primary_platform;
        let defaultPlatform = '';

        if(IsSocialMediaPlatform(workspace) && data.campaign_social) {
          defaultPlatform = 'SOCIAL';
        } else if(workspace === 'PINTEREST' && data.campaign_pinterest) {
          defaultPlatform = 'PINTEREST';
        } else if (data.campaign_platforms.some(item => item.platform === workspace)){
          defaultPlatform = workspace;
        } else {
          const platforms = data.campaign_platforms.map(p => p.platform);
          if(data.campaign_social) platforms.push('SOCIAL');
          if(data.campaign_pinterest) platforms.push('PINTEREST');
        
          const platformsSorted = platforms.sort((a, b) => {
            const indexA = PlatformOrderList.findIndex(type => a === type);
            const indexB = PlatformOrderList.findIndex(type => b === type);
            return indexA - indexB;
          });

          const index = platformsSorted.findIndex(p => p === workspace);
          platformsSorted.unshift(platformsSorted.splice(index, 1)[0]);

          defaultPlatform = platformsSorted[0];
        }

        self.setSelectedPlatform(self.preSelectedPlatform ? self.preSelectedPlatform : PlatformLabelMap.get(defaultPlatform));
      } catch (err) {
        console.error(err);
      } finally {
        self.isLoading = false;
      }
    }),
    updateCampaign: flow(function* () {
      try {
        yield request.put(self.baseURL, getSnapshot(self.campaign));
      } catch (err) {
        console.error(err);
      } finally {
      }
    }),
    deleteCampaign: flow(function* () {
      const url = `${self.baseURL}`;
      try {
        const confirmDelete = yield confirm({
          text: 'Are you sure you want to delete this campaign? You cannot undo these changes',
          okText: 'Delete'
        });
        if (!confirmDelete) return;

        yield request.delete(url);
        message({type: 'success', text: 'Successfully deleted campaign.'});
        destroy(self);
      } catch (err) {
        throw err;
      } finally {
      }
    }),
    addCampaignPlatform: flow(function* (name) {
      if(self.campaign.campaign_platforms.find(p => p.platform === name) || (self.campaign.campaign_pinterest && name === 'PINTEREST') || (self.campaign.campaign_social && name === 'SOCIAL')){
        return;
      }

      const body = { platform: name, due_date: self.campaign.sorted_due_dates[1], workspace_id: self.WorkspaceStore.current_workspace.id, idea_id: self.campaign.idea.id };

      try {
        yield request.post(self.baseURL, body);
        window.location.reload(true);
      } catch (err) {
        console.error(err);
      }
    }),
    // eslint-disable-next-line require-yield
    deleteCampaignPlatform: flow(function* (name) {
      const confirmDelete = yield confirm({
        text: 'Are you sure you want to delete this platform? You cannot undo these changes',
        okText: 'Delete'
      });
      if (!confirmDelete) return;

      const campaign_platform = self.campaign.campaign_platforms.find(p => p.platform === name);
      campaign_platform.deletePlatform();
      destroy(campaign_platform);

      message({ type: 'success', text: 'Platform successfully deleted.' });

      const workspace = self.WorkspaceStore.current_workspace.primary_platform;
      let defaultPlatform = '';

      if(IsSocialMediaPlatform(workspace) && self.campaign.campaign_social) {
        defaultPlatform = 'SOCIAL';
      } else if(workspace === 'PINTEREST' && self.campaign.campaign_pinterest) {
        defaultPlatform = 'PINTEREST';
      } else if (self.campaign.campaign_platforms.some(item => item.platform === workspace)){
        defaultPlatform = workspace;
      } else {
        const platforms = self.campaign.campaign_platforms.filter(p => p.platform !== name).map(p => p.platform);
        if(self.campaign.campaign_social) platforms.push('SOCIAL');
        if(self.campaign.campaign_pinterest) platforms.push('PINTEREST');
      
        const platformsSorted = platforms.sort((a, b) => {
          const indexA = PlatformOrderList.findIndex(type => a === type);
          const indexB = PlatformOrderList.findIndex(type => b === type);
          return indexA - indexB;
        });

        const index = platformsSorted.findIndex(p => p === workspace);
        platformsSorted.unshift(platformsSorted.splice(index, 1)[0]);

        defaultPlatform = platformsSorted[0];
      }

      self.setSelectedPlatform(PlatformLabelMap.get(defaultPlatform));
      self.setPreSelectedPlatform(PlatformLabelMap.get(defaultPlatform));
    }),
    addLinkedIdea(e, { title }) {
      if (!title) {
        message({
          type: 'error',
          text: 'You must provide a title for the supporting idea.'
        });
        return;
      }

      self.campaign.idea.addNewLinkedIdea({
        id: String(Date.now()),
        title
      });
    },
    addPinterestBoard: flow(function* () {
			try {
				const result = yield confirm({
					text: 'add new Pinterest Board',
          titleSVGName: 'add',
					children: <Observer>{() =>
						<Input
							placeholder="enter Pinterest board name"
							value={self.newPinterestBoardName}
							onChange={(e) => self.setNewBoardName(e.target.value)}
						/>
					}</Observer>
				});

				if (!result) return;

        const maxOrder = Math.max(...self.campaign.campaign_pinterest?.pinterest_boards.map(({ order }) => order), 0);
				const { data } = yield request.post(`${self.WorkspaceStore.current_workspace.baseURL}/settings/social/pinterest-board`, { name: self.newPinterestBoardName, order: maxOrder + 1 });

        rootStore.WorkspaceStore.current_workspace.addPinterestBoard(data);
				self.setNewBoardName('');
				message({ type: 'success', text: 'Successfully created Pinterest board.' });
			} catch (err) {
				console.log(err);
				throw err;
			}
		}),
		setNewBoardName(newBoardName) {
			self.newPinterestBoardName = newBoardName;
		},
    updateCampaignUrl() {
      self.fetchCampaign();
    },
    hideLinkIdeasModal() {
      self.visibleLinkIdeasModal = false;
    },
    openLinkIdeasModal() {
      self.visibleLinkIdeasModal = true;
    },
    showNotesModal() {
      self.visibleNotesModal = true;
    },
    hideNotesModal() {
      self.visibleNotesModal = false;
    },
    toggleNotesModal() {
      self.visibleNotesModal = !self.visibleNotesModal;
    },
    setSelectedPlatform(platform) {
      self.selectedPlatform = platform;
      rootStore.AuthStore.setCampaignPlatform(self.campaign?.title + ' / ' + self.selectedPlatform);
    },
    setPreSelectedPlatform(platform) {
      self.preSelectedPlatform = platform;
    },
  }));

export default CampaignsModel;

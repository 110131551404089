import request from 'api/request';
import { Modal } from 'components';
import { flow, types } from 'mobx-state-tree';

const { message } = Modal;
const { model, enumeration, optional, maybeNull, string } = types;
// Model
const NewOrganizationModel = model('NewOrganizationModel', {
	organization_name: optional(string, ''),
	workspace_id: maybeNull(string),
	state: optional(enumeration('NewOrganizationState', ['welcome', 'create', 'billing', 'workspace', 'workspace-tutorial', 'not-owner']), 'welcome')
}).views((self) => ({
	get progress() {
		return {
			'welcome': '20%',
			'create': '40%',
			'billing': '60%',
			'workspace': '80%',
			'workspace-tutorial': '100%',
		}[self.state] || '0%';
	}
})).actions((self) => ({
	afterCreate() {
		if (self.props.state) {
			self.state = self.props.state;
		}
	},
	onSubmitOrganizationName: flow(function* () {
		if (!self.organization_name) {
			message({ type: 'error', text: 'Please provide an organization name.'});
			return;
		}
		const { data } = yield request.post('/organizations', {
			organization_name: self.organization_name,
		});
		yield self.AuthStore.selectCurrentOrganization(data.id, false);

		self.state = 'billing';
	}),
	onCreateWorkspace(workspace) {
		self.workspace_id = workspace.id;
		self.setState('workspace-tutorial');
	},
	onFinishWorkspace: flow(function* () {
		yield self.WorkspaceStore.onNewWorkspace({ id: self.workspace_id });
		self.props.navigate('/workspace-settings');
	}),
	setOrganizationName(organization_name) {
		self.organization_name = organization_name;
	},
	setState(state) {
		self.state = state;
	}
}));

export default NewOrganizationModel;

import { observer } from 'mobx-react-lite';
import { Link, useParams } from 'react-router-dom';
import { ModelConnector } from 'stores';
import { Button, Container, Menu, Popover, UserImage } from 'components';
import SVG from 'components/svg/svg';

import WorkspacesModel from './workspaces.model';

import classNames from 'classnames/bind';
import styles from './workspaces.scss';

const cx = classNames.bind(styles);

const Workspaces = observer(({ model }) => {
	const { organization_id } = useParams();
	
	return (
		<Container title="Workspaces" className={cx('workspaces-list')} isLoading={model.isLoading}>
			{model.workspaces.map((workspace) => {
				const workspaceMenuItems = [{
					icon: 'create',
					text: 'Edit',
					to: workspace.id
				}, {
				// 	icon: 'content_copy',
				// 	text: 'Duplicate',
				// 	// action: '',
				// }, {
					icon: 'delete',
					text: 'Delete',
					action: (e) => {
						e.stopPropagation();
						model.deleteWorkspace(workspace.id);
					},
				}];

				return (
					<div key={workspace.id} className={cx('workspace-item')} onClick={() => model.props.navigate(`/organization-settings/${organization_id}/workspaces/${workspace.id}`)}>
						<div className={cx('workspace-icon')}>{workspace.name[0] + workspace.name[1]}</div>
						<div className={cx('workspace-info')}>
							<div className={cx('workspace-name')}>{workspace.name}</div>
							<div className={cx('workspace-users')}>{workspace.users.map((user) => (
								<UserImage key={user.id} size="small" user={user} />
							))}</div>
						</div>
						<div className={cx('workspace-platforms')}>
							{/* {workspace.platforms.map((platform) => (<SVG key={platform} name={PlatformIconMap.get(platform)} />))} */}
						</div>
						<div className={cx('workspace-actions')} onClick={(e) => e.stopPropagation()}>
							<Popover content={<Menu items={workspaceMenuItems} />}>
								<SVG name="more_horiz" />
							</Popover>
						</div>
					</div>
				);
			})
			}
			<div className={cx('workspaces-new-workspace')}>
				<Link style={{ textDecoration: 'none' }} to="new"><Button icon="add" type="hollow" >Create New Workspace</Button></Link>
			</div>
		</Container>
	);
});

export default ModelConnector(Workspaces, WorkspacesModel);

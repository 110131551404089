import { Container } from "components";

function Collaboration(props) {

	return (
		<Container title="Collaboration" titleSize={6} titleIcon={'handshake'} titleWeight={400}>
			<div>Coming soon</div>
		</Container>
	);
}

export default Collaboration;

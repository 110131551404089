import classNames from 'classnames';
import React, { Component } from 'react';

export default class Form extends Component {
	constructor(props) {
		super(props);
		this.onSubmit = this.onSubmit.bind(this);
	}

	static Item = ({ children, className, label, labelPosition, style }) => (
		<div className={classNames('tejik-form-item', labelPosition && `tejik-form-item-${labelPosition}`, className)} style={style}>
			{label && <label>{label}</label>}
			{children}
		</div>
	);

	static Row = ({ children }) => (
		<div className="tejik-form-row">
			{children}
		</div>
	);

	onSubmit(e) {
		e.preventDefault();
		this.props.onSubmit(e);
	}

	render() {
		return (
			<form className={classNames('tejik-form', this.props.className)} style={this.props.style} onSubmit={this.onSubmit}>
				{this.props.children}
			</form>
		);
	}
}
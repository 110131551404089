import { Outlet } from "react-router-dom";
import Sidebar from "views/base/sidebar/sidebar";

import classNames from 'classnames/bind';
import styles from './account-settings.scss';

const cx = classNames.bind(styles);

function AccountSettings() {
	const accountSettingsMenuItems = [{
		icon: 'keyboard_backspace',
		text: 'Back',
		to: '/',
		exact: true,
	}, {
		icon: 'manage_accounts',
		text: 'My Profile',
		to: '/account-settings/profile'
	}, {
		icon: 'switch_account',
		text: 'Organizations',
		to: '/account-settings/organizations'
	}];
	
    return (
		<div className={cx('main')}>
			<Sidebar menuItems={accountSettingsMenuItems}/>
			<div style={{ width: 300, height: '100%' }} className={cx('show-desktop-only')}></div>

			<div className={cx('main-content')} id="main-content">
				<Outlet/>
			</div>
		</div>
    );
}

export default AccountSettings;

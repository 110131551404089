import request from 'api/request';
import debounce from 'lodash.debounce';
import {flow, isAlive, types, clone} from 'mobx-state-tree';
import {CampaignModel} from 'models/campaign.model';
import {
  PlatformIconMap, 
  PlatformLabelMap, 
  PlatformOrderList
} from 'utils/utils';

import React from 'react';
import {
  Button,
  Container,
  DatePicker,
  LinkedIdeas,
  Menu,
  SVG,
  PlatformWidget,
} from 'components';

import classNames from 'classnames/bind';
import styles from './campaigns.scss';
import {rootStore} from 'stores';

const cx = classNames.bind(styles);

const RenderedExpandedView = (params) => {
  const {campaign, onItemDeleted, onShowIdeasModal} = params;

  if (!isAlive(campaign)) return;

  const menuItems = [
    ...campaign.campaign_platforms.map(campaign_platform => ({
      text: (
				<PlatformWidget 
					item={{
            sponsored: !!campaign_platform.sponsored_content,
            channel: PlatformIconMap.get(campaign_platform.platform),
						platform: PlatformLabelMap.get(campaign_platform.platform),
            percentage: campaign_platform.percentage_complete,
            due_date: campaign_platform.formated_due_date,
						recycled: !!campaign_platform.recycle_at,
            status: campaign_platform?.status
          }} 
				/>
      ),
      to: `/campaigns/${campaign.id}`,
      payload: { platform: campaign_platform.platform }
    }))
  ];

  if (campaign.campaign_pinterest) {
    menuItems.push({
      text: (
				<PlatformWidget
					item={{
						channel: PlatformIconMap.get('PINTEREST'),
						platform: PlatformLabelMap.get('PINTEREST'),
						percentage: campaign.campaign_pinterest.percentage_complete,
						due_date: campaign.campaign_pinterest.formated_due_date,
						recycled: !!campaign.campaign_pinterest.recycle_at,
						status: campaign.campaign_pinterest?.campaign_status
					}}
				/>
      ),
      to: `/campaigns/${campaign.id}`,
      payload: { platform: 'PINTEREST' }
    });
  }

  if (campaign.campaign_social) {
    menuItems.push({
      text: (
        <PlatformWidget
          item={{
						channel: PlatformIconMap.get('SOCIAL'),
						platform: PlatformLabelMap.get('SOCIAL'),
            percentage: campaign.campaign_social.percentage_complete,
            due_date: campaign.campaign_social.formated_due_date,
						recycled: !!campaign.campaign_social.recycle_at,
            status: campaign.campaign_social?.campaign_status
          }}
        />
      ),
      to: `/campaigns/${campaign.id}`,
      payload: { platform: 'SOCIAL' }
    });
  }
  
  const platformsSorted = menuItems.sort((a, b) => {
    const indexA = PlatformOrderList.findIndex(type => a.text.props.item.platform === PlatformLabelMap.get(type));
    const indexB = PlatformOrderList.findIndex(type => b.text.props.item.platform === PlatformLabelMap.get(type));
    return indexA - indexB;
  });

  if (rootStore.WorkspaceStore?.current_workspace?.primary_platform) {
    const index = platformsSorted.findIndex(p => p.text.props.item.platform === PlatformLabelMap.get(rootStore.WorkspaceStore.current_workspace.primary_platform));
    platformsSorted.unshift(platformsSorted.splice(index, 1)[0]);
  }

  return (
    <Container noPadding>
      <Container.Column span={9}>
        <Menu className={cx('campaign-platforms')} items={platformsSorted}/>
      </Container.Column>
      <Container.Column span={3}>
        <div className={cx('campaign-third-col-container')}>
          <div>
            <p className="mb-1">Linked content</p>
            <LinkedIdeas ideas={campaign.idea.linked_ideas} onUnlink={campaign.idea.removeLinkedIdea} onAddLinkedIdea={() => { onShowIdeasModal(campaign); }}/>
          </div>
          <div className={cx('campaign-delete-btn-container')} onClick={() =>
            campaign.deleteCampaign(() => {
              onItemDeleted();
            })
          }>
						<span className={cx('campaign-delete-btn-text')}>Delete campaign</span>
            <Button
              icon="delete"
              type="text"
              iconSize={16}
              className={cx('campaign-delete-btn-icon')}
            />
          </div>
        </div>
      </Container.Column>
    </Container>
  );
};

const {array, boolean, enumeration, model, optional, string, number, frozen, maybeNull } = types;

const CampaignsModel = model('CampaignsModel', {
  campaigns: array(CampaignModel),
  isLoading: optional(boolean, true),
  campaignListState: optional(
    enumeration('CampaignListState', ['active', 'closed']),
    'active'
  ),
  visibleCreateIdeaModal: optional(boolean, false),
  toggleMeFilterApplied: optional(boolean, false),
  visibleLinkIdeasModal: optional(boolean, false),
  openedCampaign: maybeNull(CampaignModel),
  searchTerm: optional(string, ''),
  filterCount: optional(number, 0),
  filters: frozen({})
})
  .views(self => ({
    get baseURL() {
      return `${self.WorkspaceStore.current_workspace.baseURL}/campaigns`;
    },
    get campaignList() {
      const mapFunc = campaign => ({
        id: campaign.id,
        title: (
          <div className="d-flex">
            {campaign.title}
            <div style={{flexGrow: 1}}/>
            {campaign.recycle_at && <SVG name="recycle" fontSize={16}/>}
            {campaign.sponsored_content && (
              <SVG className="ml-1" name="handshake" fontSize={16}/>
            )}
          </div>
        ),
		    onRedirectClick: () => self.props.navigate(`/campaigns/${campaign.id}`),
        actions: [
          <div
            key="campaign_date"
            className={cx('campaign-date-picker', 'fixed-width')}
            onClick={e => e.stopPropagation()}
          >
            {campaign.due_date && (
              <DatePicker
                selected={new Date(campaign.due_date)}
                onChange={campaign.setDueDate}
                customInput={
                  <div className={cx('campaign-date-picker-input')}>
                    <SVG className="mr-1" name="calendar_today"/>{' '}
                    {campaign.formated_due_date}
                  </div>
                }
              />
            )}
          </div>
        ],
        expandedView: (
          <RenderedExpandedView
            key={campaign.id}
            onItemDeleted={() => {
              self.fetchCampaigns(rootStore.WorkspaceStore.campaignFilters);
            }}
            onShowIdeasModal={self.showLinkIdeasModal}
            campaign={campaign}
            navigate={self.props.navigate}
          />
        )
      });
      
      if (self.campaignListState === 'closed') {
        return self.campaigns
          .filter(campaign => campaign.status?.type === 'CLOSED')
          .sort((a, b) => new Date(a.due_date) - new Date(b.due_date))
          .map(mapFunc);
      }

      return self.campaigns
        .filter(campaign => campaign.status?.type !== 'CLOSED')
        .sort((a, b) => new Date(a.due_date) - new Date(b.due_date))
        .map(mapFunc);
    }
  }))
  .actions(self => ({
    afterCreate() {
      const initialFilters = rootStore.WorkspaceStore.campaignFilters;
      self.fetchCampaigns(initialFilters);
    },
    searchCampaigns: debounce(() => self.fetchCampaigns(), 700),
    fetchCampaigns: flow(function* (initialFilters) {
      self.isLoading = true;
      try {
        self.fillWithStoredFilters();
        const filters = { filterApplied: true, ...(self.filters ?? initialFilters)};

        const {data} = yield request.get(self.baseURL, {
          params: {
            search_term: self.searchTerm,
            ...filters
          }
        });

        if(self.filters ?? initialFilters) self.setFilters(filters);
        self.setCampaigns([]);
        self.setCampaigns(data);
      } catch (err) {
        console.error(err);
      } finally {
        self.isLoading = false;
      }
    }),
    setCampaigns(data) {
      self.campaigns = data;
    },
    setFilters(filters) {
      self.filters = filters;
      self.filterCount = (filters?.users?.length > 0 ? 1 : 0) + (filters?.platforms?.length > 0 ? 1 : 0) + (filters.sponsoredContent ? 1 : 0) + (filters.recycledContent ? 1 : 0);
    },
    setSearchTerm(searchTerm) {
      self.isLoading = true;
      self.searchTerm = searchTerm;
      self.searchCampaigns();
    },
    setCampaignListState(campaignListState) {
      self.campaignListState = campaignListState;
    },
    showCreateIdeaModal() {
      self.visibleCreateIdeaModal = true;
    },
    hideCreateIdeaModal() {
      self.visibleCreateIdeaModal = false;
    },
    toggleMeFilter() {
      self.toggleMeFilterApplied = !self.toggleMeFilterApplied;

      if (self.toggleMeFilterApplied) {
        self.filters = {...self.filters, users: [rootStore.AuthStore.user]};
      } else {
        self.filters = {...self.filters, users: []};
      }

      localStorage.setItem('CAMPAIGNS-FILTER-DATA', JSON.stringify(self.filters));
      self.fetchCampaigns(null, true);
    },
    filterModalClick(filters) {
      if (filters?.filterApplied) {
        self.toggleMeFilterApplied = false;
        self.setFilters(filters);
        self.fetchCampaigns();
      }
      self.visibleFilterModal = !self.visibleFilterModal;
    },
    fillWithStoredFilters(){
      const storedData = localStorage.getItem('CAMPAIGNS-FILTER-DATA');
      if(storedData) {
        const filters = JSON.parse(storedData);
        self.setFilters(filters);
      }
    },
    showLinkIdeasModal(campaign) {
      self.openedCampaign = clone(campaign);
      self.visibleLinkIdeasModal = true;
    },
    hideLinkIdeasModal() {
      const idea = clone(self.openedCampaign.idea);
      const index = self.campaigns.findIndex(c => c.id === self.openedCampaign.id);
      self.campaigns[index].idea.linked_ideas.splice(0, self.campaigns[index].idea.linked_ideas.length);

      idea.linked_ideas.forEach((linked) => {
        self.campaigns[index].idea.linked_ideas.push(clone(linked));
      });

      self.visibleLinkIdeasModal = false;
    }
  }));

export default CampaignsModel;

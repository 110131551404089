import { Button, Container, Form, Input } from "components";
import { useParams } from "react-router-dom";

import classNames from 'classnames/bind';
import styles from './category.scss';
import { observer } from "mobx-react-lite";

const cx = classNames.bind(styles);

function Category ({ model }) {
	const params = useParams();
	
	if (!model.category) {
		model.setSelectedCategory(params.category_id)
	};
	
	return (
		<Container
			title="Category"
			titleIcon="grid_view"
			actions={<Button type="text" icon="close" onClick={model.cancelCategory}/>}
		>
			<Container.Column span={12} className="container-description">
				<p>Represents an angle that your ideal customer needs help with and that they relate to your message. These categories help you expand your central content idea into smaller pieces of content for social media based on the category.</p>
			</Container.Column>
			<Container.Column span={8}>
				<Form onSubmit={() => model.saveCategory(model.category)}>
					<Form.Item label="Title of Category:">
						<Input value={model.category.title} onChange={(e) => model.category.setTitle(e.target.value)} type="text" />
					</Form.Item>
					<Form.Item label="Description or examples:">
						<Input value={model.category.description} onChange={(e) => model.category.setDescription(e.target.value)} type="text" />
					</Form.Item>
					<Form.Item className={cx('align-items-end')}>
						<Button htmlType="submit">Save</Button>
					</Form.Item>
				</Form>
			</Container.Column>
		</Container>
	);
}

export default observer(Category);

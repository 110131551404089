import React, {Component, useState} from 'react';
import {CSSTransition} from 'react-transition-group';
import {createRoot} from 'react-dom/client';

import classNames from 'classnames';
import SVG from 'components/svg/svg';
import {Button, Title} from 'components';
import {Observer} from 'mobx-react-lite';

// Used for the confirm box
let root;

export default class Modal extends Component {
  componentDidMount() {
    if (this.props.visible && !this.props.canScroll) {
      this.preventBodyScrolling(true);
    } else {
      this.preventBodyScrolling(false);
    }
  }

  componentDidUpdate() {
    if (!this.props.visible) this.preventBodyScrolling(false);
  }

  componentWillUnmount() {
    this.preventBodyScrolling(false);
  }

  overlayClick = (e) => {
    // e.stopPropagation();
    if (e?.target?.classList.contains('tejik-modal') && this.props.overlayClosable) return this.props.onCancel(e);
    return null;
  };

  preventBodyScrolling = (state) => {
    if (state) {
      document.body.classList.add('tejik-prevent-scrolling');
    } else {
      document.body.classList.remove('tejik-prevent-scrolling');
    }
  };

  static message = (props) => {
    const ModalWrapper = ({text, type = 'info', code = 0}) => {
      const [visible, setVisible] = useState(true);

      setTimeout(() => {
		if(code === 401) window.location = '/login';
        setVisible(false);
      }, 2000);

      return (
        <Modal
          className="tejik-message-modal"
          footer={[]}
          visible={visible}
          title={text}
          titleSize={6}
          titleSVGName={{'error': 'error', 'info': 'info', 'success': 'check_circle'}[type]}
          titleSVGClassName={{'error': 'text-error', 'info': 'text-info', 'success': 'text-success'}[type]}
          canScroll
        />
      );
    }
    const modal = <ModalWrapper {...props} />;

    const targetId = 'tejik-message-modal';
    if (!document.getElementById(targetId)) {
      const parent = document.createElement('div');
      parent.id = 'tejik-message-modal';
      document.body.appendChild(parent);

      root = createRoot(parent);
    }

    root.render(modal);
  }

  static confirm = (props) => {
    return new Promise((resolve, reject) => {
      const ModalWrapper = (props) => {
        const [visible, setVisible] = useState(true);

        const ok = () => {
          setVisible(false)
          resolve(true);
        }

        const cancel = () => {
          setVisible(false)
          resolve(false);
        }

        return (
          <Modal
            visible={visible}
            overlayClosable
            closable
            cancelButtonProps={{type: 'hollow'}}
            onCancel={cancel}
            onOk={ok}
            okText={props.okText}
            title={props.text}
            titleSize={6}
            headerStyle={props.headerStyle}
            titleStyle={props.titleStyle}
            bodyStyle={props.bodyStyle}
            titleSVGName={props.titleSVGName}
          >
            {props.children}
          </Modal>
        );
      }
      const modal = <ModalWrapper {...props} />;

      const targetId = 'tejik-confirm-modal';
      if (!document.getElementById(targetId)) {
        const parent = document.createElement('div');
        parent.id = 'tejik-confirm-modal';
        document.body.appendChild(parent);

        root = createRoot(parent);
      }

      root.render(modal);
    });
  }

  render() {
    const {
      actions,
      closable,
      children,
      className,
      cancelText,
      onCancel,
      footer,
      footerPosition = 'center',
      cancelButtonProps,
      onOk,
      okButtonProps,
      okText,
      title,
      titleSize = 3,
      titleSVGName,
      titleSVGClassName,
      width = 700,
      paddingModalContent,
      closeIconStyle,
      headerStyle,
      textBody,
      showCancel = true,
      removePadding = false,
      marginModalContent,
      titleStyle,
      bodyStyle,
      visible
    } = this.props;

    const animationTimeout = 200;

    return (
      <CSSTransition in={visible} appear timeout={animationTimeout} classNames="tejik-modal-transition">
        <div
          className={classNames(className, 'tejik-modal')}
          onClick={this.overlayClick}
        >
          <div className="tejik-modal-content" style={{'width': width, 'padding': paddingModalContent, margin: marginModalContent }}>
            {closable && (
              <div className="tejik-modal-close" style={closeIconStyle}>
                <Button type="text" icon="close" onClick={onCancel}/>
              </div>
            )}
            <div className="tejik-modal-header" style={headerStyle}>
              {title && (
                <Title size={titleSize} className="tejik-modal-title" weight={400}>
                  {titleSVGName &&
                    <SVG name={titleSVGName} className={classNames('tejik-modal-title-svg', titleSVGClassName)}/>
                  }
                  <span style={titleStyle}>{title}</span>
                </Title>
              )}
              {actions && <div className="tejik-modal-header-actions">
                {actions}
              </div>}
            </div>
            {
              textBody &&
              <div className={classNames("tejik-modal-body", !title && 'tejik-modal-centered')}>
                <p>{textBody}</p>
              </div>
            }
            {children &&
              <div className={classNames({"tejik-modal-body" : !removePadding})} style={bodyStyle}>
                <Observer>{() => children}</Observer>
              </div>
            }
            <div className={classNames('tejik-modal-actions', `tejik-modal-actions-${footerPosition}`)}>
              {footer ?
                (
                  footer
                ) : (
                  <>
                    {showCancel && <Button onClick={onCancel} {...cancelButtonProps}>{cancelText ? cancelText : 'Cancel'}</Button>}
                    <Button onClick={onOk} {...okButtonProps}>{okText ? okText : 'Save'}</Button>
                  </>
                )
              }
            </div>
          </div>
        </div>
      </CSSTransition>
    );
  }
}
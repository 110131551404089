import React, { Component } from 'react';

import classNames from 'classnames';
import { Observer } from 'mobx-react-lite';
import { ColorForeground, closePopover } from 'utils/utils';
import SVG from 'components/svg/svg';

class StatusSelection extends Component {
	render() {
		const { className, onSelect, statusOptions, value } = this.props;

		return (
			<Observer>{() => (
				<div className={classNames('tejik-status-selection', className)}>
					{statusOptions.map((status) => (
						<div
							key={status.id}
							className={classNames('tejik-status-selection-button')}
							onClick={(e) => {
								e.stopPropagation();
								closePopover(e);
								onSelect(status.id);
							}}
						>
							<div className="tejik-status-selection-color" style={{ backgroundColor: status.color, color: ColorForeground.get(status.color) }}/>
							<div className="tejik-status-selection-text">
								{status.name}
							</div>
							{value === status.id && <SVG name="check" />}
						</div>
					))}
				</div>
			)}</Observer>
		);
	}
}

export default StatusSelection;
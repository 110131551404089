import request from 'api/request';
import {flow, types, getSnapshot} from 'mobx-state-tree';
import {CampaignModel} from 'models/campaign.model';
import {PlatformEnum} from 'models/global/global.model';
import {rootStore} from 'stores';
import debounce from 'lodash.debounce';
import {PlatformOrderList} from "../../../utils/utils";

const {array, boolean, identifier, map, maybeNull, model, optional, string, number, frozen, Date: mobxDate} = types;

const PlatformEventModel = model('PlatformEventModel', {
  platform: PlatformEnum,
  statusColor: maybeNull(string)
})

// Model
const EventModel = model('EventModel', {
  id: identifier,
  statusColor: maybeNull(string),
  name: string,
  event_link: string,
  platforms: array(PlatformEventModel)
}).actions(self => ({
  setPlatforms(platforms) {
    self.platforms = platforms;
  }
}));

const PlannerModel = model('PlannerModel', {
  searchTerm: optional(string, ''),
  campaigns: array(CampaignModel),
  events: map(array(EventModel)),
  visibleCreateIdeaModal: optional(boolean, false),
  visibleFilterModal: optional(boolean, false),
  visibleSearchMobile: optional(boolean, false),
  showConfirmationToMoveAnIdeaModal: optional(boolean, false),
  showInvalidDateIdeaModal: optional(boolean, false),
  toggleMeFilterApplied: optional(boolean, false),
  filterCount: optional(number, 0),
  filters: frozen({}),
	selectedDate: optional(mobxDate, new Date()),
  selectedDateEvents: array(EventModel),
})
  .views(self => ({
    get baseURL() {
      return `${rootStore.WorkspaceStore.current_workspace.baseURL}/campaigns`;
    },
  }))
  .actions(self => ({
    afterCreate() {
      const initialFilters = rootStore.WorkspaceStore.plannerFilters;
      self.fetchCampaigns(initialFilters);
    },
    searchCampaigns: debounce(() => self.fetchCampaigns(), 700),
    fetchCampaigns: flow(function* (initialFilters) {
      try {
		    self.fillWithStoredFilters();
        const filters = { filterApplied: true, ...(self.filters ?? initialFilters)};
		
        const {data} = yield request.get(self.baseURL, {
          params: {
            search_term: self.searchTerm,
            ...filters
          }
        });

        if(self.filters ?? initialFilters) self.setFilters(filters);
        self.setCampaigns([]);
        self.setCampaigns(data);
				self.setSelectedDate(new Date());
        self.events.clear();
        self.setupEvents();
      } catch (err) {
        console.error(err);
      } finally {
        self.isLoading = false;
      }
    }),
	setCampaigns(data) {
		self.campaigns = data;
	},
	setFilters(filters) {
		self.filters = filters;
		self.filterCount = (filters?.users?.length > 0 ? 1 : 0) + (filters?.platforms?.length > 0 ? 1 : 0) + (filters.sponsoredContent ? 1 : 0) + (filters.recycledContent ? 1 : 0);
	},
  addEvent(key, event) {
    const foundEvent = self.events.get(key);
    if (foundEvent) {
      let hasPushed = false;
      for (const loopedEvent of foundEvent) {
        const {color, name, platforms} = loopedEvent;
        if (event.statusColor === color && event.name === name) {
          loopedEvent.setPlatforms(
            Array.from(new Set([...platforms, ...event.platforms]))
          );
          hasPushed = true;
        }
      }
      if (!hasPushed) foundEvent.push(event);
    } else {
      self.events.set(key, [event]);
    }
  },
  setupEvents() {
    for (const campaign of self.campaigns) {
      let eventsByDueDate = new Map();

      for (const campaign_platform of campaign.campaign_platforms) {
        const key = new Date(campaign_platform.due_date).toDateString();
        const event = {
          id: campaign_platform.id,
          event_link: `/campaigns/${campaign.id}`,
          name: campaign.idea.title,
          platforms: [{
            platform: campaign_platform.platform,
            statusColor: campaign_platform.status?.color || null
          }],
        };
        if (eventsByDueDate.has(key)) {
          const ev = eventsByDueDate.get(key);
          if (!ev.platforms.find(p => p.platform === campaign_platform.platform)) {
            eventsByDueDate.set(key, {
              ...ev, platforms: [...ev.platforms, {
                platform: campaign_platform.platform,
                statusColor: campaign_platform.status?.color || null
              }]
            });
          }
        } else {
          eventsByDueDate.set(key, event);
        }
      }
      for (const social_post of campaign.campaign_social?.social_posts ||
      []) {
        const key = new Date(social_post.due_date).toDateString();
        const event = {
          id: social_post.id,
          event_link: `/campaigns/${campaign.id}`,
          name: campaign.idea.title,
          platforms: Array.from(campaign.campaign_social?.platforms || []).map(p => {
            return {
              platform: p,
              statusColor: campaign.campaign_social.status?.color || null
            }
          }),
        };
        if (eventsByDueDate.has(key)) {
          const ev = eventsByDueDate.get(key);
          if (campaign.campaign_social?.platforms && !ev.platforms.some(item => campaign.campaign_social.platforms.includes(item.platform))) {
            eventsByDueDate.set(key, {
              ...ev, platforms: [...ev.platforms, ...Array.from(campaign.campaign_social?.platforms || []).map(p => {
                return {
                  platform: p,
                  statusColor: campaign.campaign_social.status?.color || null
                }
              })]
            });
          }
        } else {
          eventsByDueDate.set(key, event);
        }
        // self.addEvent(key, event);
      }

      for (const pinterest_pin of campaign.campaign_pinterest?.pins || []) {
        const key = new Date(pinterest_pin.due_date).toDateString();
        const event = {
          id: pinterest_pin.id,
          event_link: `/campaigns/${campaign.id}`,
          name: campaign.idea.title,
          platforms: [{
            platform: 'PINTEREST',
            statusColor: campaign.campaign_pinterest.status?.color || null
          }],
        };

        if (eventsByDueDate.has(key)) {
          const ev = eventsByDueDate.get(key);
          if (campaign.campaign_social?.platforms && !event.platforms.includes('PINTEREST')) {
            eventsByDueDate.set(key, {
              ...ev, platforms: [...ev.platforms, {
                platform: 'PINTEREST',
                statusColor: campaign.campaign_pinterest.status?.color || null
              }]
            });
          }
        } else {
          eventsByDueDate.set(key, event);
        }
      }

      for (const key of eventsByDueDate.keys()) {
        const event = eventsByDueDate.get(key);
        const platformsSorted = event.platforms.sort((a, b) => {
          const indexA = PlatformOrderList.findIndex(type => a.platform === type);
          const indexB = PlatformOrderList.findIndex(type => b.platform === type);
          return indexA - indexB; // to get positive, 0, negative number for the sort callback.
        });
        self.addEvent(key, {...event, platforms: platformsSorted})

        if(key === self.selectedDate.toDateString()) {
          self.selectedDateEvents.push(event);
        }
      }
    }
  },
  hideCreateIdeaModal() {
    self.visibleCreateIdeaModal = false;
  },
  showCreateIdeaModal() {
    self.visibleCreateIdeaModal = true;
  },
  toggleConfirmationToMoveAnIdeaModal() {
    self.showConfirmationToMoveAnIdeaModal = !self.showConfirmationToMoveAnIdeaModal;
  },
  toggleInvalidDateIdeaModal() {
    self.showInvalidDateIdeaModal = !self.showInvalidDateIdeaModal;
  },
  toggleMobileSearch() {
    self.visibleSearchMobile = !self.visibleSearchMobile;
  },
  setSearchTerm(searchTerm) {
    self.searchTerm = searchTerm;
    self.isLoading = true;
    self.events.clear();
    self.searchCampaigns();
  },
	setSelectedDate(date){
		self.selectedDate = date;
	},
	setSelectedDateEvents() {
		self.selectedDateEvents = [];

		const evts = self.events.get(self.selectedDate.toDateString());
		evts.forEach((evt) => self.selectedDateEvents.push(getSnapshot(evt)));
	},
	setFooterEvents(date) {
		self.setSelectedDate(date);
		self.setSelectedDateEvents();
	},
  toggleMeFilter() {
    self.toggleMeFilterApplied = !self.toggleMeFilterApplied;

    if (self.toggleMeFilterApplied) {
      self.filters = {...self.filters, users: [rootStore.AuthStore.user]};
    } else {
      self.filters = {...self.filters, users: []};
    }

  localStorage.setItem('PLANNER-FILTER-DATA', JSON.stringify(self.filters));
    self.events.clear();
    self.fetchCampaigns(null, true);
  },
  filterModalClick(filters) {
    if (filters?.filterApplied) {
      self.toggleMeFilterApplied = false;
      self.setFilters(filters);
      self.events.clear();
      self.fetchCampaigns();
    }
    self.visibleFilterModal = !self.visibleFilterModal;
  },
  fillWithStoredFilters() {
    const storedData = localStorage.getItem('PLANNER-FILTER-DATA');
    if(storedData) {
      const filters = JSON.parse(storedData);
      self.setFilters(filters);
    }
  },
}));

export default PlannerModel;

import React, { Component } from 'react';
import classNames from 'classnames';

import  './weekday-selector.scss';

export default class WeekdaySelector extends Component {
	constructor(props) {
		super(props);
		const selection = props.selectedDays;

		this.state = { selectedDays: selection ? selection : [] };
	}
	
	onDayPress = (dayIndex) => {
		const selectedDays = [...this.state.selectedDays];

		if(selectedDays.includes(dayIndex)) {
			selectedDays.splice(selectedDays.findIndex((d) => d === dayIndex), 1);
		} else {
			selectedDays.push(dayIndex);
		}
		selectedDays.sort();

		this.setState({ selectedDays });
		this.props.onChange(selectedDays);
	}

	render () {
		const { style, className } = this.props;
		const weekDays = ["S", "M", "T", "W", "T", "F", "S"];

		return (
			<div className={classNames('tejik-weekday-selector', className)} style={style}>
			{weekDays.map((day, idx) => (
				<button
					key={'weekday-btn-'+idx}
					className={classNames('tejik-weekday-button', {'last': idx === 6} ,{'tejik-weekday-button-active': this.state.selectedDays && this.state.selectedDays.includes(idx.toString()) })}
					onClick={() => this.onDayPress(idx.toString())}
				>
					{day}
				</button>
			))}
			</div>
		)
	}
}

import { Button, Container, Form, Info, Input, List, Popover } from "components";
import SVG from "components/svg/svg";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { PlatformIconMap, PlatformLabelMap, closePopover } from "utils/utils";

import ContentLinksModel from './content-links.model';
import { ModelConnector } from "stores";

import classNames from 'classnames/bind';
import styles from './content-links.scss';

const cx = classNames.bind(styles);

const ContentLinkTypeSelector = observer(({ autoClose = false, setType, type }) => (
	<div className={cx('content-link-types')}>
		<Button
			className={cx({ 'selected': type === 'DOCUMENT' })}
			type="text"
			icon="description"
			iconSize={20}
			onClick={(e) => {
				setType('DOCUMENT');
				if (autoClose) closePopover(e);
			}}
		/>
		<Button
			className={cx({ 'selected': type === 'PHOTO' })}
			type="text"
			icon="image"
			iconSize={20}
			onClick={(e) => {
				setType('PHOTO');
				if (autoClose) closePopover(e);
			}}
		/>
		<Button
			className={cx({ 'selected': type === 'VIDEO' })}
			type="text"
			icon="movie"
			iconSize={20}
			onClick={(e) => {
				setType('VIDEO');
				if (autoClose) closePopover(e);
			}}
		/>
		<Button
			className={cx({ 'selected': type === 'FILE' })}
			type="text"
			icon="folder"
			iconSize={20}
			onClick={(e) => {
				setType('FILE');
				if (autoClose) closePopover(e);
			}}
		/>
	</div>
));

const EditContentLink = observer(({
	title,
	name,
	type,
	setName,
	setType,
	onSubmit
}) => (
	<div style={{ margin: 14 }}>
		<div className="d-flex">
			{title}
		</div>
		<Form.Item>
			<Input
				placeholder="enter a content link title"
				value={name}
				onChange={(e) => setName(e.target.value)}
				size="small"
			/>
		</Form.Item>
		<Form.Item>
			<p className="text-neutral-3" style={{ fontSize: 14 }}>pick an icon</p>
			<ContentLinkTypeSelector type={type} setType={setType} />
		</Form.Item>
		<Form.Item>
			<Button style={{ width: 'fit-content'}} onClick={(e) => {
				closePopover(e);
				onSubmit();
			}}>Save</Button>
		</Form.Item>
	</div>
));

const RenderedExpandedView = observer(({
	platform,
	content_links,
	content_link_name,
	setContentLinkName,
	content_link_type,
	setContentLinkType,
	onNewContentLink,
}) => (
	<Container className={cx('content-links-container')}>
		{platform === 'SIDEMENU' &&
				<div className="info-message"><SVG name="info" className="info-icon"/>only three content links are allowed to display</div>
		}
		{((platform === 'SIDEMENU' && content_links.length < 2) || (platform !== 'SIDEMENU')) &&
			<Container.Column span={4}>
				<Popover position="left" contentClass={classNames('new-content-link-popup')}
					content={<EditContentLink 
						title={<>
							<SVG name="add" className="mr-1" />
							Add new content link type
						</>}
						name={content_link_name}
						setName={setContentLinkName}
						type={content_link_type}
						setType={setContentLinkType}
						onSubmit={onNewContentLink}
					/>}
					>
					<div className={cx('content-link')}>
						<SVG name="add" size="20px" />
					</div>
				</Popover>
			</Container.Column>
		}
		{content_links.map((content_link) => (
			<Container.Column span={4} key={content_link.id}>
				<div className={cx('content-link')}>
					<Popover width="300px" position="left" content={<ContentLinkTypeSelector autoClose type={content_link.type} setType={content_link.setType} />}>
						<SVG name={content_link.icon} size="20px" />
					</Popover>
					<div className={cx('content-name')}>
						<Input hidden size="small" inputStyle={{ backgroundColor: 'transparent'}} value={content_link.name} onChange={(e) => content_link.setName(e.target.value)} />
					</div>
					<Button type="text" icon="delete" iconSize={16} onClick={content_link.deleteContentLink} />
				</div>
			</Container.Column>
		))}
		{platform === 'SIDEMENU' &&
			<Container.Column span={4}>
				<div className={cx('content-link')} style={{ justifyContent: 'flex-start'}}>
					<SVG name='link' size="20px" />
					<span style={{ marginLeft: '1em' }}>Blog post URL</span>
				</div>
			</Container.Column>
		}
	</Container>
))

const ContentLinks = observer(({ model }) => {
	const platformList = model.workspace.contentLinkPlatforms.map((platform) => ({
		id: platform,
		title: (<div className="d-flex">
			<SVG name={PlatformIconMap.get(platform)} className="mr-1" />
			{PlatformLabelMap.get(platform)}
		</div>),
		expandedView: <RenderedExpandedView
			platform={platform}
			content_links={model.content_links.filter((content_link) => content_link.platform === platform)}
			content_link_name={model.content_link_name}
			content_link_type={model.content_link_type}
			setContentLinkName={model.setContentLinkName}
			setContentLinkType={model.setContentLinkType}
			onNewContentLink={() => model.addContentLink(platform)}
		/>,
	}));

	return (
		<Container title="Content Links" titleSize={1} titleIcon={'add_link'} titleWeight={400} actions={<div className={cx('content-links-description')}>
				<Link to="/workspace-settings/templates"><SVG name="close" /></Link>
			</div>}
			isLoading={model.isLoading}
		>
			<List rows={platformList} />
			<Info infoKey="content-links"/>
		</Container>
	);
});

export default ModelConnector(ContentLinks, ContentLinksModel);

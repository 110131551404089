import { types, flow } from 'mobx-state-tree';
import request from 'api/request';

const { string, model, optional } = types;
// Model
const ResetPasswordModel = model('ResetPasswordModel', {
	token: optional(string, ''),
	password: optional(string, ''),
})
.actions((self) => ({
	afterCreate() {
		const queryParams = new URLSearchParams(self.props.location.search);
		self.token = queryParams.get('token') || '';
	},
	confirmReset: flow(function* () {
		try {
			yield request.post('/auth/reset-password', { password: self.password, token: self.token });
			self.AuthStore.fetchSession();
			self.props.navigate('/');
		} catch (err) {
			console.log(err);
		}
	}),
	// Setters
	setPassword(password) {
		self.password = password;
	},
}));

export default ResetPasswordModel;
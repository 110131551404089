/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames/bind";
import styles from './ideas-drawer.scss';
import SVG from "../../../../components/svg/svg";
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import IdeasItemView from "./ideas-item.view";

import {ModelConnector} from 'stores';
import {Input, Loader} from "../../../../components";
import {observer} from "mobx-react-lite";
import IdeasModel from "../../ideas/ideas.model";

const cx = classNames.bind(styles);

const IdeasDrawer = observer(({model, onClose, ideaToRemove}) => {

  useEffect(() => {
    if (ideaToRemove) {
      model.removeIdeaFromList(ideaToRemove);
    }
  }, [ideaToRemove]);

  return (
    <div className={cx('tejik-ideas-drawer-container')}>
      <div className={cx('title-container')}>
        <div className={cx('title-icon')}>
          <SVG name={'emoji_objects'} size={20}/>
          <h6 style={{marginLeft: 4}}>Ideas</h6>
        </div>
        <SVG className={cx('close-button')} name={'close'} size={20} onClick={onClose}/>
      </div>
      <div style={{marginTop: 8}}>
        <Input
          size="small"
          icon="search"
          value={model.searchTerm}
		      inputStyle={{ backgroundColor: 'transparent', padding: 8 }}
          onChange={e => model.setSearchTerm(e.target.value)}/>
      </div>

      <div style={{marginTop: 16}}>
        {
          model.loading &&
          <div>
            <Loader center={true}/>
          </div>
        }
        {
          model.ideas.map((idea, index) => <IdeasItemView idea={idea} key={index}/>)
        }
      </div>


    </div>

  )
})

IdeasDrawer.propTypes = {
  onClose: PropTypes.func,
  ideaToRemove: PropTypes.any
}

export default ModelConnector(IdeasDrawer, IdeasModel);
/* eslint-disable react-hooks/exhaustive-deps */
import { Input, Button, LinkedIdeas, Popover, PlatformSelection, Form, Modal, RichText } from 'components';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import SVG, { TejikLogo } from 'components/svg/svg';
import LinkedIdeasModal from 'modals/linkedIdeas/linkedIdeas';

import { Observer, observer } from 'mobx-react-lite';
import { ModelConnector } from 'stores';
import { rootStore } from 'stores';
import { useEffect } from 'react';

import classNames from 'classnames/bind';
import styles from './campaign-menu.scss';

import CampaignModel from '../../campaign/campaign.model';

const { message } = Modal;
const cx = classNames.bind(styles);

const SideMenu = observer(({ model, setCampaignUrlSlug, campaignUrlSlug }) => {
	const { state } = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const documentHandler = (e) => {
			const main = document.querySelector('#main-content');
			if (main.contains(e.target)) {
				rootStore.AuthStore.closeSidebar();
			}
		};

		document.addEventListener('click', documentHandler);

		return () => {
			document.removeEventListener('click', documentHandler);
		}
	}, []);

	useEffect(() => {
		setCampaignUrlSlug(model.campaign?.url_slug)
	}, [model.campaign?.url_slug]);

	const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    message({ type: 'success', text: 'Successfully copied content.' });
  };

	return (
		<div className={cx('sidemenu', { 'show-sidemenu': rootStore.AuthStore?.showSidebar })}>
			<div>
				<div className={cx('logo-container')}>
					<Link onClick={() => navigate(state && state.platform ? -2 : -1)}>
						<SVG name="keyboard_backspace" />
					</Link>
					<div className={cx('logo')}>
						<Link to="/">
							<TejikLogo />
						</Link>
					</div>
					<div></div>
				</div>
				<Input
					size="large"
					type="dynamic"
					hidden={true}
					inputStyle={{ fontSize: 16, marginTop: '0.5em' }}
					value={model?.campaign?.idea?.title ?? ''}
					onChange={e => model?.campaign?.idea.setTitle(e.target.value)}
				/>
				<div className="flex-start calendar-date-range">
					<SVG name="calendar_today" className="mr-1" />
					<span className={'date-range-span'}>{model.campaign?.date_range}</span>
				</div>
				<Popover key="settings-popup" position="left" width="320px" content={
					<Form>
						<Form.Item label="Platforms:">
							<PlatformSelection
								platforms={model.platformArray}
								togglePlatform={model.addCampaignPlatform}
								workspacePlatforms={model.workspacePlatforms}
								showSocialOption={true}
							/>
						</Form.Item>
						<Form.Item label="Linked Content:">
							<LinkedIdeas
								ideas={model.campaign?.idea.linked_ideas ?? {}}
								onAddLinkedIdea={model.openLinkIdeasModal}
							/>
						</Form.Item>
						<hr />
						<div style={{ display: 'flex', justifyContent: 'center', marginTop: '0.75em', marginBottom: '-1em' }}>
							<Button
								icon="delete"
								type="text"
								onClick={() =>
									model.campaign.deleteCampaign(() => {
										model.props.navigate('/campaigns', { replace: true });
									})
								}
							>
								Delete campaign
							</Button>
						</div>
					</Form>
				}>
					<Button key="settings-button" 
						style={{ width: '100%', justifyContent: 'center', marginTop: '2.5em' }} 
						type="hollow-gray" 
						icon="build"
					>
						Campaign tools
					</Button>
				</Popover>
				<div className={'campaign-link'}>
					<Observer>
						{() => (
							<Input
								placeholder="url-slug-based-on-title"
								size="small"
								type="dynamic"
								hidden={true}
								preffix={(rootStore.WorkspaceStore?.current_workspace.domain + (campaignUrlSlug?.indexOf('/') > -1 ? '' : '/') ?? '')}
								blurValue={(campaignUrlSlug ?? '')}
								value={campaignUrlSlug ?? ''}
								onChange={e => {
									setCampaignUrlSlug(e.target.value.replace(' ', '-').toLowerCase() + (e.target.value.indexOf('/') > 0 ? '' : '/')); 
									model.campaign.setUrlSlug(e.target.value); 
								}}
								className={cx('content-link-url-input')}
							/>
						)}
					</Observer>
					<SVG name="copy" onClick={() => { copyToClipboard((rootStore.WorkspaceStore?.current_workspace.domain ?? '') + (campaignUrlSlug[0] === '/' ? '' : '/') + (campaignUrlSlug ?? ''))}} style={{ cursor: 'pointer'}}/>
				</div>
				<div >
					{model.sidemnuLinks.length > 0 &&
						<div key={'content-link-' + model.sidemnuLinks[0].id} className={'campaign-link'}>
							<SVG name="link"/>
							<div>
								<span style={{ marginLeft: '0.8em', fontSize: 14 }}>{model.sidemnuLinks[0].name}</span>
								<Observer>
									{() => (
										<Input
											placeholder="content link url"
											size="small"
											single={true}
											hidden={true}
											value={model.campaign?.side_link_1 ?? ''}
											onChange={e => model.campaign?.setCampaignLink1(e.target.value)}
											className={cx('content-link-url-input')}
											style={{ margin: '0.1em' }}
										/>
									)}
								</Observer>
							</div>
							<SVG name="copy" onClick={() => { copyToClipboard(model.campaign?.side_link_1)}} style={{ cursor: 'pointer'}}/>
						</div>
					}
					{model.sidemnuLinks.length > 1 &&
						<div key={'content-link-' + model.sidemnuLinks[1].id} className={'campaign-link'}>
							<SVG name="link"/>
							<div>
								<span style={{ marginLeft: '0.8em', fontSize: 14 }}>{model.sidemnuLinks[1].name}</span>
								<Observer>
									{() => (
										<Input
											placeholder="content link url"
											size="small"
											single={true}
											hidden={true}
											value={model.campaign?.side_link_2 ?? ''}
											onChange={e => model.campaign?.setCampaignLink2(e.target.value)}
											className={cx('content-link-url-input')}
											style={{ margin: '0.1em' }}
										/>
									)}
								</Observer>
							</div>
							<SVG name="copy" onClick={() => { copyToClipboard(model.campaign?.side_link_2)}} style={{ cursor: 'pointer'}}/>
						</div>
					}
				</div>
				<LinkedIdeasModal
					onOk={model.hideLinkIdeasModal}
					onCancel={model.hideLinkIdeasModal}
					visible={model.visibleLinkIdeasModal}
					idea={model.campaign?.idea}
				/>
				{model.visibleNotesModal && <div className={cx('campaign-notes')}>
					<div style={{ display: 'flex', justifyContent: 'space-between'}}>
						<div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5em' }}>
							<SVG name="sticky_note_2" className='mr-1'></SVG>
							<h4>Notes</h4>
						</div>
						<SVG name="close" size={16} style={{ cursor: 'pointer' }} onClick={model.hideNotesModal}/>
					</div>
					<RichText
						initialState={(model.campaign?.idea?.rich_notes || model.campaign?.idea?.notes) ?? ''}
						onChange={state => model.campaign?.idea?.setNotes(state)} 
					/>
				</div>
			}
			</div>
			<Button
				size="small"
				iconSize={16}
				type="hollow-dark"
				shape="hollow-gray"
				icon="notes"
				style={{ width: '100%', height: 50 }}
				freezeStyle={model.visibleNotesModal}
				onClick={model.toggleNotesModal}
			>
				<div style={{ justifyContent: 'space-between', width: '100%', alignItems: 'center', display: 'flex' }}>
					<span>Notes</span>
					<SVG className="tejik-card-action" name="chevron_right" size={20}/>
				</div>
			</Button>
		</div>
	);
});

export default ModelConnector(SideMenu, CampaignModel);

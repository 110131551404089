import request from 'api/request';
import { Modal } from 'components';
import { flow, types } from 'mobx-state-tree';
import { UserModel } from 'models/user.model';
import { WorkspaceModel } from 'models/workspace.model';

const { confirm, message } = Modal;
const { array, boolean, model, optional } = types;
// Model
const UsersModel = model('UsersModel', {
	users: array(UserModel),
	workspaces: array(WorkspaceModel),
	isLoading: optional(boolean, true),
}).views((self) => ({
	get baseURL() {
		return `/organizations/${self.organizationId}`;
	},
	get usersBaseURL() {
		return `${self.baseURL}/users`;
	},
	get workspaceBaseURL() {
		return `${self.baseURL}/workspaces`;
	},
	get organizationId() {
		return self.props.params.organization_id;
	},
	get workspaceOptions () {
		return self.workspaces.map((workspace) => ({
			label: workspace.name,
			value: workspace.id,
		}))
	}
})).actions((self) => ({
	afterCreate() {
		self.fetchUsers();
		self.fetchWorkspaces();
	},
	fetchUsers: flow(function* () {
		const { data } = yield request.get(self.usersBaseURL);
		self.users = data;
		self.isLoading = false;
	}),
	fetchWorkspaces: flow(function* () {
		const { data } = yield request.get(self.workspaceBaseURL);
		self.workspaces = data;
	}),
	addUser: flow(function* (e, data) {
		try {
			yield request.post(self.usersBaseURL, data);
			message({ type: 'success', text: `Successfully sent email invite to '${data.email}'` });

		} catch (err) {
			console.error(err);
		}
	}),
	deleteUser: flow(function* (id) {
		const confirmDelete = yield confirm({
			text: 'Are you sure you want to remove this user?',
			children: 'This action cannot be undone.',
			okText: 'Remove',
		});
		if (!confirmDelete) return;

		yield request.delete(`${self.usersBaseURL}/${id}`);
		message({ type: 'success', text: 'Successfully removed user' });
		self.fetchUsers();
	}),
}));

export default UsersModel;

import { observer } from 'mobx-react-lite';
import { ModelConnector } from 'stores';
import { Button, Container, Form, Input, List, Modal } from 'components';

import OrganizationsModel from './organizations.model';

const Organizations = observer(({ model }) => {
	return (
		<Container>
			<Container.Column span={12}>
				<List rows={model.organizationsList} />
			</Container.Column>
			<Container.Column span={12}>
				<div className="flex-center">
					<Button
						icon="add"
						type="hollow"
						onClick={() => model.setShowNewOrganization(true)}
					>
						Create New Organization
					</Button>
				</div>
			</Container.Column>
			<Modal
				visible={model.showNewOrganization}
				onOk={model.onSubmitOrganizationName}
				onCancel={() => model.setShowNewOrganization(false)}
			>
				<Form>
					<Form.Item label="Organization name">
						<Input
							placeholder="enter new organization name here"
							value={model.organizationName}
							onChange={(e) => model.setOrganizationName(e.target.value)}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</Container>
	);
});

export default ModelConnector(Organizations, OrganizationsModel);

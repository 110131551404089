// import request from 'api/request';
import { PlatformEnum } from 'models/global/global.model';
import { types } from 'mobx-state-tree';
import { rootStore } from 'stores';

const { boolean, optional, string, number, maybeNull, model } = types;

export const CharacterLimitModel = model('CharacterLimitModel', {
	id: string,
	workspace_id: string,
	platform: PlatformEnum,

	title: maybeNull(number),
	description: maybeNull(number),
	
	showActions: optional(boolean, false),
}).views((self) => ({
	get baseURL() {
		return `${rootStore.WorkspaceStore.currentBaseURL}/settings`;
	},
}))
.actions((self) => ({
	setTitle(title) {
		self.title = Number(title);
	},
	setDescription(description) {
		self.description = Number(description);
	},
	setShowActions(showActions) {
		self.showActions = showActions;
	},
}));

import {
	Routes,
	Route,
} from "react-router-dom";

import { observer } from "mobx-react-lite";
import { Container, Info, Input, Modal } from "components";
import { ModelConnector } from "stores";
import CategoriesModel from "./categories.model";
import SVG from "components/svg/svg";

import CategoryView from "./category/category";

import classNames from 'classnames/bind';
import styles from './categories.scss';

const cx = classNames.bind(styles);

export const CategoryCard = observer(({ className, model, category, onSelect }) => (
	<div className={cx('category-card', className)} onClick={onSelect ? () => onSelect(category.id) : () => model.showActions(category.id)}>
		<div className={cx('category-card-title')}>{category.title}</div>
		<div className={cx('category-card-description')}>{category.description}</div>
		<div className={cx('category-card-action-overlay', { 'active': category.showActions })}>
			<div style={{ display: 'flex', alignItems: 'center'}} onClick={(e) => {
				e.stopPropagation();
				model.editCategory(category.id)
			}}>
				<SVG name="edit" size={20} className="mr-1" /><b style={{ fontSize: 16, fontWeight: 500 }}>Edit</b>
			</div>
			<div style={{ display: 'flex', alignItems: 'center'}} onClick={(e) => {
				e.stopPropagation();
				model.deleteCategory(category.id)
			}}>
				<SVG name="delete" size={20} className="mr-1"/><b style={{ fontSize: 16, fontWeight: 500 }}>Remove</b>
			</div>
		</div>
	</div>
));

const CategoriesList = observer(({ model }) => {
	return (
		<Container title="Categories" titleSize={1} titleIcon={'grid_view'} titleWeight={400}>
			<Container.Column span={4}>
				<div onClick={model.newCategory} className={cx('category-card')} style={{height: '100%'}}>
					<div className={cx('category-card-description')}><SVG name="add" size={20} /></div>
				</div>
				{model.visibleCategoryModal && <Modal
					visible={model.visibleCategoryModal}
					closable
					onOk={() => model.saveCategory(model.category)}
					onCancel={model.cancelCategory}
					cancelButtonProps={{type: 'hollow'}}
					okText="Save category"
				>
					<Container title="Category" titleSize={1} titleIcon={'grid_view'} titleWeight={400} style={{ padding: 0}}>
						<span className="create-popup-message">Represents an angle that your ideal customer needs help with and that they relate to your message. These categories help you expand your central content idea into smaller pieces of content for social media based on the category.</span>
						<Input value={model.category.title} placeholder="title of category" onChange={(e) => model.category.setTitle(e.target.value)} type="text" />
						<Input value={model.category.description} placeholder="add a description or example of category" onChange={(e) => model.category.setDescription(e.target.value)} type="text" />
					</Container>
			</Modal>}
			</Container.Column>
			{model.categories.map((category) => (
				<Container.Column key={category.id} span={4}>
					<CategoryCard category={category} model={model} />
				</Container.Column>
			))}
			<Info infoKey="categories"/>
		</Container>
	);
});

function Categories({ model }) {
	const Category = <CategoryView model={model} />;

	return (
		<Routes>
			<Route index element={<CategoriesList model={model} />} />
			<Route path="new" element={Category} />
			<Route path=":category_id" element={Category} />
		</Routes>
	);
}

export default ModelConnector(Categories, CategoriesModel);;

import request from 'api/request';
import { flow, getSnapshot, types } from 'mobx-state-tree';
import debounce from 'lodash.debounce';
import { IdeaModel } from 'models/idea.model';

const { model, array, boolean, optional, string } = types;
// Model
const LinkedIdeasModel = model('LinkedIdeasModel', {
	searchTerm: optional(string, ''),
	foundIdeas: array(IdeaModel),
	isSearching: optional(boolean, false),
})
	.views((self) => ({
		get baseURL() {
			return `${self.WorkspaceStore.current_workspace.baseURL}/dashboard/linked-content`;
		},
		get idea() {
			return self.props.idea;
		},
		get linkedIdeas() {
			return self.idea?.linked_ideas || [];
		},
		get foundIdeasFiltered() {
			const linkedIds = self.idea?.linked_ideas.map(linkedIdea => linkedIdea.id);
			return linkedIds ? self.foundIdeas.filter(idea => !linkedIds.includes(idea.id)).sort((a, b) => a.id - b.id) : [];
		}
	}))
	.actions((self) => ({
		afterCreate() {
			self.searchForIdeas();
		},
		onIdeaSelect(idea) {
			self.idea.addNewLinkedIdea(getSnapshot(idea));
		},
		onIdeaUnlink(idea) {
			self.idea.removeLinkedIdea(idea);
		},
		searchForIdeas: flow(function* () {
			const { data } = yield request.get(self.baseURL, {
				params: {
					search_term: self.searchTerm,
					exclude: self.idea?.id,
				}
			});

			self.foundIdeas = data;
			self.isSearching = false;
		}),
		requestSearch: debounce(() => self.searchForIdeas(), 1000),
		setSearchTerm(searchTerm) {
			self.searchTerm = searchTerm;
			self.isSearching = true;
			self.requestSearch();
		},
	}));

export default LinkedIdeasModel;

import { useState } from "react";
import { observer } from "mobx-react-lite";
import { rootStore } from "stores";
import { 
	Button, 
	Container, 
	Form, 
	Input, 
	List, 
	Modal, 
	PlatformSelection, 
	Select, 
	RichText,
	Toggle,
	Popover,
	StatusSelection,
	DatePicker
} from "components";
import { CategoryCard } from "views/app/workspace-settings/social/categories/categories.view";
import { PlatformIconMap } from "utils/utils";

import SVG from "components/svg/svg";
import { GenerateCaptionText } from "models/campaign.model";

import classNames from 'classnames/bind';
import styles from './social.scss';
// import ContentLinks from "../../contentLinks";
const cx = classNames.bind(styles);

const { confirm, message } = Modal;

const SocialPostModal = observer(({ campaign_social, onOk, onCancel, visible }) => {
	const current_workspace = rootStore.WorkspaceStore.current_workspace;

	const [refresh, setRefresh] = useState(false);
	const [title, setTitle] = useState(campaign_social.selected_social_post?.title ?? '');
	const [categoryId, setCategory] = useState(campaign_social.selected_social_post?.category?.id ?? '');
	const [platforms, setPlatforms] = useState(campaign_social.selected_social_post?.platforms ?? []);

	const togglePlatform = (platform) => {
		const temp = [...platforms];
		if (platforms.includes(platform)) {
			const index = platforms.findIndex((p) => p === platform);
			temp.splice(index, 1);
			setPlatforms(temp);
		} else {
			temp.push(platform);
			setPlatforms(temp);
		}
	}

	const date = campaign_social.selected_social_post?.due_date ? new Date(campaign_social.selected_social_post?.due_date) : new Date();
	const [dueDate, setDueDate] = useState(date);

	const [contentType, setContentType] = useState(campaign_social.selected_social_post?.content_type?.id ?? '');
	const [hookGroup, setHookGroup] = useState(campaign_social.selected_social_post?.hook?.hook_group_id ?? '');
	const [hook, setHook] = useState(campaign_social.selected_social_post?.hook?.id ?? '');
	const [CTA, setCTA] = useState(campaign_social.selected_social_post?.call_to_action?.id ?? '');

	const [design, setDesign] = useState(campaign_social.selected_social_post?.data?.design ?? '');
	const [customHook, setCustomHook] = useState(campaign_social.selected_social_post?.data?.custom_hook ?? '');
	const [steps, setSteps] = useState(campaign_social.selected_social_post?.data?.steps ?? '');

	const contentTypeOptions = current_workspace.content_types.map((content_type) => ({
		label: content_type.name,
		value: content_type.id,
	}));

	const hookTypeOptions = new Map();
	for (const hook of current_workspace.hook_groups) {
		hookTypeOptions.set(hook.id,
			{
				label: hook.name,
				value: hook.id,
			}
		);
	}

	const foundHookGroup = current_workspace.hook_groups.find((hg) => hg.id === hookGroup);
	const filteredHookOptions = (foundHookGroup?.hooks || []).map((hook) => ({
		label: hook.name,
		value: hook.id,
	}));

	const CTAOptions = current_workspace.call_to_actions.map((cta) => ({
		label: cta.name,
		value: cta.id,
	}));

	return (
		<Modal
			className={cx('new-social-post-modal')}
			width={window.innerWidth <= 790 ? '98%' : '80%'}
			onOk={() => {
				const data = {
					...campaign_social.selected_social_post?.data,
					custom_hook: customHook,
					design,
					steps,
				};

				const socialHook = filteredHookOptions.find(({ value }) => value === hook)?.label;
				const socialCTA = CTAOptions.find(({ value }) => value === CTA)?.label;

				for (const platform of platforms) {
					const platformHashtagString = campaign_social.selected_social_post?.data[platform]?.hashtag_string(platform);
					const platformCustomHook = current_workspace.social_caption?.hook_platforms.includes(platform) ? customHook : null;
					const status_id = data[platform]?.status_id ? data[platform].status_id : campaign_social.campaign_status?.id;

					data[platform] = { ...data[platform], caption: GenerateCaptionText(platform, platformCustomHook || socialHook, steps, socialCTA, platformHashtagString), status_id };
				}

				onOk({
					title,
					due_date: dueDate,
					category_id: categoryId || null,
					platforms,
					content_type_id: contentType || null,
					hook_id: hook || null,
					data,
					call_to_action_id: CTA || null,
					is_configured: true,
				})
			}}
			onCancel={onCancel}
			visible={visible}
			footerPosition='between'
			cancelButtonProps={{ type: 'black', style: { borderColor: '#E3E9F0' } }}
			closable
			overlayClosable
			title='Social post'
			titleSize={5}
			titleSVGName='share'
		>
			<Form>
				<Container noPadding>
					<div style={{ marginTop: '-1em', display: 'flex', alignItems: 'center' }}>
						<span style={{ marginRight: '1em', fontSize: 14 }}>Select social platforms</span>
						<PlatformSelection
							platforms={platforms}
							togglePlatform={togglePlatform}
							workspacePlatforms={campaign_social.platforms}
						/>
					</div>
					<Container.Column span={8} style={{ marginTop: '-1em' }}>
						<Form.Item>
							<span style={{ marginRight: '1em', marginBottom: '0.75em', fontSize: 14 }}>Social content idea</span>
							<Input value={title} onChange={(e) => setTitle(e.target.value)} inputStyle={{ backgroundColor: 'transparent' }}/>
						</Form.Item>
					</Container.Column>
					<Container.Column span={4} style={{ marginTop: '-1em' }}>
						<Form.Item>
							<span style={{ marginRight: '1em', marginBottom: '0.75em', fontSize: 14 }}>Due date</span>
							<DatePicker
                selected={new Date(dueDate)}
                onChange={setDueDate}
                customInput={<Input value={dueDate} inputStyle={{ width: '100%' }}/>}
							></DatePicker>
						</Form.Item>
					</Container.Column>
					<Container.Column span={12} style={{ marginTop: '-1em' }}>
						<Form.Item>
							<span style={{ marginRight: '1em', marginBottom: '0.75em', fontSize: 14 }}>Select a social category</span>
							<div className={cx('social-post-categories')}>
								{current_workspace.categories.map((category) => (
									<CategoryCard
										key={category.id}
										className={{ 'active': category.id === categoryId }}
										category={category}
										onSelect={setCategory}
									/>
								))}
							</div>
						</Form.Item>
					</Container.Column>
					<Container.Column span={6}></Container.Column>
				</Container>
				<Container noPadding>
					<Container.Column span={4} style={{ marginTop: '-1em' }}>
						<Form.Item>
							<span style={{ marginRight: '1em', marginBottom: '0.75em', fontSize: 14 }}>Type of content</span>
							<Select 
								placeholder='select' 
								value={contentType} 
								options={contentTypeOptions} 
								inputStyle={{ backgroundColor: 'transparent' }}
								onChange={(value) => {
									setContentType(value);
									const foundContentType = current_workspace.content_types.find((content_type) => (value === content_type.id));
									setPlatforms(foundContentType?.platforms.length > 0 ? foundContentType.platforms : []);
								}}
								contained={true}
							/>
						</Form.Item>
					</Container.Column>
					<Container.Column span={4} style={{ marginTop: '-1em' }}>
						<Form.Item>
							<span style={{ marginRight: '1em', marginBottom: '0.75em', fontSize: 14 }}>Hook category</span>
							<Select 
								placeholder='select'
								inputStyle={{ backgroundColor: 'transparent' }}
								value={hookGroup} 
								options={Array.from(hookTypeOptions.values())} 
								onChange={setHookGroup}
								contained={true}
							/>
						</Form.Item>
					</Container.Column>
					<Container.Column span={4} style={{ marginTop: '-1em' }}>
						<Form.Item>
							<span style={{ marginRight: '1em', marginBottom: '0.75em', fontSize: 14 }}>Select hook prompt</span>
							<Select
								placeholder='select'
								inputStyle={{ backgroundColor: 'transparent' }}
								value={hook}
								options={filteredHookOptions}
								onChange={(value) => {
									setHook(value);
									const option = filteredHookOptions.find((option) => option.value === value);
									setRefresh(true);
									setCustomHook(option.label);
								}}
								contained={true}
							/>
						</Form.Item>
					</Container.Column>
				</Container>
				<Form.Item>
					<span style={{ marginRight: '1em', marginBottom: '0.75em', fontSize: 14 }}>Visualize your post elements (# of slides, storyboard, text, video clips, etc)</span>
					<Input 
						type="textarea" 
						onChange={(e) => setDesign(e.target.value)} 
						value={design}
						placeholder='add text'
					/>
				</Form.Item>
				{current_workspace.social_caption?.hook_platforms.some((caption_platform) => platforms.includes(caption_platform)) &&
					<Form.Item>
						<span style={{ marginRight: '1em', marginBottom: '0.75em', fontSize: 14 }}>Customize Hook: (what’s the problem)</span>
						<RichText 
							key='hook'
              placeholder='add text'
              initialState={customHook || ''} 
              onChange={state => setCustomHook(state)} 
							refresh={refresh}
							refreshFn={setRefresh}
            />
					</Form.Item>
				}
				{current_workspace.social_caption?.transformation_platforms.some((caption_platform) => platforms.includes(caption_platform)) &&
					<Form.Item>
						<span style={{ marginRight: '1em', marginBottom: '0.75em', fontSize: 14 }}>Engaging Content</span>
						<RichText 
							key='engage'
              placeholder='add text'
              initialState={steps || ''} 
              onChange={state => setSteps(state)} 
            />
					</Form.Item>
				}
				{current_workspace.social_caption?.call_to_action_platforms.some((caption_platform) => platforms.includes(caption_platform)) &&
					<Form.Item>
						<span style={{ marginRight: '1em', marginBottom: '0.75em', fontSize: 14 }}>Call-to-action (CTA)</span>
						<Select value={CTA} options={CTAOptions} onChange={setCTA} placeholder='select' contained={true}/>
					</Form.Item>
				}
			</Form>
		</Modal>
	)
});

const YoutubePostItem = observer(({ social_post, platform, hashtagOptions, refreshCaption, refreshCaptionFn }) => {
	return (
		<Container.Column key={platform} span={4} style={{ marginTop: '-3em' }}>
			<Form.Item key={platform + 'title'} label={
				<div className={cx('social-platform-label')}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<SVG name={PlatformIconMap.get(platform)}/>
						<span> title & caption:</span>
					</div>
					<Popover
						position='left'
						width={300}
						content={
							<StatusSelection
								statusOptions={rootStore.WorkspaceStore.current_workspace.statusOptions('SOCIAL')} 
								onSelect={value => social_post.setData(platform, { status_id: value })}
								value={social_post?.data[platform]?.status?.id}
							/>
						}
					>
						<div className={cx('tejik-social-widget-status')} style={{ backgroundColor: social_post?.data[platform]?.status?.color }}>{social_post?.data[platform]?.status?.name}</div>
					</Popover>
				</div>
				}>
				<Input
					onChange={(e) => {
						if (rootStore.WorkspaceStore.current_workspace.getCharacterLimit(platform).title >= e.target.value.length) {
							social_post.setData(platform, { title: e.target.value })
						} else {
							message({ type: 'error', text: 'You have reached the maxium character limit for this field.' });
						}
					}}
					inputStyle={{ backgroundColor: 'transparent' }}
					value={social_post?.data[platform]?.title ?? ''}
					canCopy
				/>
				<div className={cx('social-character-limit-text')}>
					{rootStore.WorkspaceStore.current_workspace.getCharacterLimit(platform).title - (social_post?.data[platform]?.title?.length ?? 0)} characters left
				</div>
				<RichText 
					key={'platform-' + platform}
					placeholder='add text'
					canCopy
					refresh={refreshCaption}
					refreshFn={refreshCaptionFn}
					initialState={social_post?.data[platform]?.caption ?? ''} 
					style={{ height: 145, minHeight: 145 }}
					onChange={state => social_post.setData(platform, { caption: state })} 
				/>
				<div className={cx('social-character-limit-text')}>
					{rootStore.WorkspaceStore.current_workspace.getCharacterLimit(platform).description - (social_post?.data[platform]?.caption_length ?? 0)} characters left
				</div>
			</Form.Item>
			<Form.Item className={cx('hashtag-options')} labelPosition="row" label={<SVG name="tag" />}>
				<Select
					key={social_post?.data[platform]?.hashtag_group_id}
					options={hashtagOptions}
					placeholder='select hashtag group'
					value={social_post?.data[platform]?.hashtag_group_id ?? ''}
					onChange={(value) => { refreshCaptionFn(true); social_post.selectHashtagGroup(platform, value); }}
					inputStyle={{ backgroundColor: 'transparent' }}
					size="small"
				/>
			</Form.Item>
		</Container.Column>
	);
});

const SocialPostItem = observer(({ social_post, platform, hashtagOptions, refreshCaption, refreshCaptionFn }) => {
	return (
		<Container.Column key={platform} span={4} style={{ marginTop: '-3em' }}>
			<Form.Item key={platform} label={
				<div className={cx('social-platform-label')}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<SVG name={PlatformIconMap.get(platform)} />
						<span> caption:</span>
					</div>
					<Popover
						position='left'
						content={
							<StatusSelection
								statusOptions={rootStore.WorkspaceStore.current_workspace.statusOptions('SOCIAL')} 
								onSelect={value => social_post.setData(platform, { status_id: value })}
								value={social_post?.data[platform]?.status?.id}
							/>
						}
						width={300}
					>
						<div className={cx('tejik-social-widget-status')} style={{ backgroundColor: social_post?.data[platform]?.status?.color }}>{social_post?.data[platform]?.status?.name}</div>
					</Popover>
				</div>
				}>
				<RichText 
					key={'platform-' + platform}
					placeholder='add text'
					canCopy
					refresh={refreshCaption}
					refreshFn={refreshCaptionFn}
					initialState={social_post?.data[platform]?.caption ?? ''} 
					style={{ height: 220, minHeight: 220 }}
					onChange={state => social_post.setData(platform, { caption: state })} 
				/>
				<div className={cx('social-character-limit-text')}>
					{rootStore.WorkspaceStore.current_workspace.getCharacterLimit(platform).description - (social_post?.data[platform]?.caption_length ?? 0)} characters left
				</div>
			</Form.Item>
			<Form.Item className={cx('hashtag-options')} labelPosition="row" label={<SVG name="tag" />}>
				<Select
					key={social_post?.data[platform]?.hashtag_group_id}
					options={hashtagOptions}
					placeholder='select hashtag group'
					value={social_post?.data[platform]?.hashtag_group_id ?? ''}
					onChange={(value) => { refreshCaptionFn(true); social_post.selectHashtagGroup(platform, value); }}
					inputStyle={{ backgroundColor: 'transparent' }}
					size="small"
				/>
			</Form.Item>
		</Container.Column>
	);
});

const ExpandedSocialPost = observer(({ social_post, campaign_social }) => {
	const current_workspace = rootStore.WorkspaceStore.current_workspace;

	const hashtagOptions = current_workspace.hashtag_groups.map((group) => ({
		label: group.name,
		value: group.id,
	}));

	return (
		<div>
			<Container noPadding style={{ marginTop: '1.5em' }}>
				<Container.Column span={4} style={{ marginTop: '-3em' }}>
					<Form.Item key="design" label={
						<div style={{ marginTop: '1em' }}>
							<span>Design</span>
						</div>
					}>
						<Input type="textarea" 
							onChange={(e) => social_post.setData('design', e.target.value)} 
							value={social_post?.data?.design ?? ''} 
							style={{ height: 312 }}
						/>
					</Form.Item>
					{/* <Form.Item>
						<ContentLinks
							single_column
							campaign_content_links={social_post.campaign_content_links}
							platform="SOCIAL"
							addCampaignContentLink={social_post.addCampaignContentLink}
						/>
					</Form.Item> */}
				</Container.Column>
				{social_post.platforms.map((platform) => (
					(platform === 'YOUTUBE' && 
						<YoutubePostItem 
							social_post={social_post} 
							platform={platform} 
							hashtagOptions={hashtagOptions} 
							refreshCaption={campaign_social.refresh_youtube} 
							refreshCaptionFn={campaign_social.setRefreshYotube}/>
					) ||
					(platform === 'INSTAGRAM' && 
						<SocialPostItem 
							social_post={social_post} 
							platform={platform} 
							hashtagOptions={hashtagOptions} 
							refreshCaption={campaign_social.refresh_instagram} 
							refreshCaptionFn={campaign_social.setRefreshInstagram}/>
					) ||
					(platform === 'FACEBOOK' && 
						<SocialPostItem 
							social_post={social_post} 
							platform={platform} 
							hashtagOptions={hashtagOptions} 
							refreshCaption={campaign_social.refresh_facebook} 
							refreshCaptionFn={campaign_social.setRefreshFacebook}/>
					) ||
					(platform === 'TIKTOK' && 
						<SocialPostItem 
							social_post={social_post} 
							platform={platform} 
							hashtagOptions={hashtagOptions} 
							refreshCaption={campaign_social.refresh_tiktok} 
							refreshCaptionFn={campaign_social.setRefreshTiktok}/>
					) || 
					(platform === 'LINKEDIN' && 
						<SocialPostItem 
							social_post={social_post} 
							platform={platform} 
							hashtagOptions={hashtagOptions} 
							refreshCaption={campaign_social.refresh_linkedin} 
							refreshCaptionFn={campaign_social.setRefreshLinkedin}/>
					) ||
					(platform === 'TWITTER' && 
						<SocialPostItem 
							social_post={social_post} 
							platform={platform} 
							hashtagOptions={hashtagOptions} 
							refreshCaption={campaign_social.refresh_twitter} 
							refreshCaptionFn={campaign_social.setRefreshTwitter}/>
					) ||
					(platform === 'PINTEREST' && 
						<SocialPostItem 
							social_post={social_post} 
							platform={platform} 
							hashtagOptions={hashtagOptions} 
							refreshCaption={campaign_social.refresh_pinterest} 
							refreshCaptionFn={campaign_social.setRefreshPinterest}/>
					)
				))}
			</Container>
		</div>
	);
});

const SocialView = observer(({ campaign_social }) => {
	const [visibleSocialPost, setVisibleSocialPost] = useState(false);

	const list = campaign_social?.sorted_filtered_platforms.map((social_post) => ({
		id: social_post.id,
		title: social_post.is_configured ? (<div className="social-post-title">
			<div className="social-post-due-date">
				<SVG className="mr-1" name="calendar_today" /> <span style={{ whiteSpace: 'nowrap', marginRight: '1em' }}>{social_post?.formated_due_date}</span>
			</div>
			<div style={{ display: 'flex', width: '100%', justifyContent:'space-between', alignItems: 'center' }}>
				<span>{social_post.title}</span>
				<div className="social-post-platforms">{social_post.platforms?.map((platform) => <SVG key={platform} name={PlatformIconMap.get(platform)} />)}</div>
			</div>
		</div>) : (<div className="social-post-not-configured">
				<SVG className="mr-1" name="warning" /> <span style={{ whiteSpace: 'nowrap', marginRight: '1em'}}>Social Content <i style={{ fontSize: 14 }}>(based on scheduling targets)</i></span>
			</div>),
		className: social_post.is_configured ? '' : 'social-post-row-configured',
		actions: social_post.is_configured ? [
			<div className={classNames('social-post-actions','social-post-actions-category')} key="social_post_title">
				{social_post.category?.title}
			</div>,
			<div className={classNames('social-post-actions','social-post-actions-type')} key="social_post_content_type">
				{social_post.content_type?.name}
			</div>,
			<div className="social-post-actions" key="social_post_edit" style={{ borderRight: '1px solid #D8D8D8'}}>
				<Button icon="edit" type="text" onClick={(e) => {
					e.stopPropagation();
					campaign_social.setSelectedPost(social_post.id);
					setVisibleSocialPost(true);
				}} />
				<Button
					onClick={(e) => {
						e.stopPropagation();
						campaign_social.deleteSocialPost(social_post.id)
					}}
					icon="delete"
					type="text"
				/>
			</div>
		] : [
			<div className="social-post-actions" key="social_post_edit">
				<Button icon="edit" type="text" onClick={(e) => {
					e.stopPropagation();
					campaign_social.setSelectedPost(social_post.id);
					setVisibleSocialPost(true);
				}} />
				<Button
					onClick={(e) => {
						e.stopPropagation();
						campaign_social.deleteSocialPost(social_post.id)
					}}
					icon="delete"
					type="text"
				/>
			</div>
		],
		expandedView: social_post.is_configured ? <ExpandedSocialPost social_post={social_post} campaign_social={campaign_social}/> : null,
	})) || [];

	return (
		<Container noPadding>
			<div className={cx('campaign-social-delete-link', 'show-mobile-only-flex')}
					onClick={e => {
						e.stopPropagation();
						campaign_social.deleteSocial(campaign_social.id);
					}}
				>
				<span style={{ fontSize: 12, color: '#707070', marginRight: '0.5em'}}>Delete platform</span>
				<Button
					type="text"
					icon="delete"
					iconSize={16}
					style={{ padding: 0, color: '#707070', marginRight: '1em' }}
				></Button>
			</div>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Button onClick={() => { 				
					campaign_social.addSocialPost({ due_date: new Date(), data: {}, platforms: campaign_social.platforms }) 
				}} 
					type="hollow" 
					icon="add" 
					className={cx('new-social-post-button')} 
				>
					New social post
				</Button>
				<div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} >
					<div className={cx('campaign-social-delete-link', 'show-desktop-only')}
						onClick={e => {
							e.stopPropagation();
							campaign_social.deleteSocial(campaign_social.id);
						}}
					>
						<span style={{ fontSize: 12, color: '#707070', marginRight: '0.5em'}}>Delete platform</span>
						<Button
							type="text"
							icon="delete"
							iconSize={16}
							style={{ padding: 0, color: '#707070', marginRight: '1em' }}
						></Button>
          </div>
					<Toggle
						key="toggle_buttons"
						options={[
							{ label: 'Active', value: 'active' },
							{ label: 'Closed', value: 'closed' }
						]}
						onToggle={campaign_social?.toggleActiveSocialPosts}
					/>
				</div>
			</div>
			<div style={{ overflowY:'auto', paddingTop: '0.5em' }}>
				<List style={{ minWidth: 820 }} rows={list} className={cx('social-posts-list')} />
			</div>
			{visibleSocialPost && 
				<SocialPostModal
					campaign_social={campaign_social}
					visible={visibleSocialPost}
					onOk={async (data) => {
						const confirmUpdate = await confirm({
							text: 'Are you sure you want to save this?',
							children: 'Continuing will regenerate each social media caption using the current text.',
							okText: 'Generate'
						});

						if (!confirmUpdate) return;

						campaign_social.enableRefreshCaptions();
						campaign_social.updateSocialPost(data);
						setVisibleSocialPost(false);
					}}
					onCancel={() => setVisibleSocialPost(false)}
				/>
			}
		</Container>
	)
});

export default SocialView;
import React, { Component } from 'react';

import classNames from 'classnames';
import { Observer } from 'mobx-react-lite';

export default class Popover extends Component {
	constructor(props) {
		super(props);
		this.state = { counter: 0, active: false, };
		this.popover = React.createRef();
		this.wrapper = React.createRef();
	}

	componentDidMount() {
		document.addEventListener('click', this.handleDocumentClick);
		this.popover.current.addEventListener('force-close', (e) => {
			this.setActive(!this.state.active);
		}, false);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleDocumentClick);
	}

	handleDocumentClick = (e) => {
		if (this.state.active) {
			if (this.popover.current.contains(e.target)) return;
			this.setActive(false);
		}
	}

	setActive = (active) => {
		this.setState({
			counter: 0,
			active,
		});
	}

	toggleActive = (e) => {
		this.setActive(!this.state.active);
	}

	render() {
		const { children, content, position, style, width, contained, contentClass } = this.props;

		return <Observer>{() => (
			<div ref={this.popover} className={classNames('tejik-popover', { 'tejik-popover-active': this.state.active })} style={style}>
				<div onClick={this.toggleActive} className={classNames('tejik-popover', { 'tejik-popover-active': this.state.active })} style={{ cursor: 'pointer', height: '100%'}}>
					{children || ''}
				</div>
				<div ref={this.wrapper} className={classNames('tejik-popover-content', {'contained': contained }, contentClass, position)} style={{ width }} >
					{content}
				</div>
			</div >
		)}</Observer>;
	}
}
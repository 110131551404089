import {Container} from "components";
import {observer} from "mobx-react-lite";

import classNames from 'classnames/bind';
import styles from './calendar.scss';
import CalendarHeaderView from "./calendar-header.view";
import CalendarItemView from "./calendar-item.view";
import { useEffect } from "react";

const cx = classNames.bind(styles);

const Calendar = observer(({date, events, weeksToShow = 6, moveWeek = false, fillHeight = true, clickHandler, showRightBorder = true, selectedDate = (new Date()).toDateString()}) => {
	useEffect(() => {}, [events.size]);
  
	const changeSelectedDate = (selected) => {
		clickHandler(new Date(selected));
	};

  return (
    <Container noPadding fillHeight={fillHeight} className={cx('calendar-container')}>
      <CalendarHeaderView/>
      <div className={cx('calendar-days')} style={{
        gridTemplateRows: `repeat(${weeksToShow}, 1fr)`,
      }}>
        {
          [...Array(7 * weeksToShow).keys()].map((index) => (
            <CalendarItemView date={date} moveWeek={moveWeek} index={index} events={events} days={7 * weeksToShow} onEventsClick={changeSelectedDate} selectedDate={selectedDate} showRightBorder={showRightBorder}/>)
          )
        }
      </div>
    </Container>
  );
});

export default Calendar;

import { observer } from 'mobx-react-lite';
import { ModelConnector, rootStore } from 'stores';
import { Container, Form, Input, UserImage } from 'components';

import ProfileModel from './profile.model';

import classNames from 'classnames/bind';
import styles from './profile.scss';

const cx = classNames.bind(styles);

const Profile = observer(({ model }) => {
	rootStore.WorkspaceStore.hideNewIdeaButton();
  rootStore.AuthStore.setCampaignPlatform('');

	return (
		<Container>
			<Container.Column span={1}>
				<Form className={cx('profile-image-row')}>
					<UserImage style={{ justifySelf: 'flex-end' }} user={model.user} size="xl" />
				</Form>
			</Container.Column>
			<Container.Column span={10}>
				<Form onSubmit={model.updateProfile}>
					<Form.Row>
						<Form.Item label="First Name">
							<Input value={model.user.first_name} onChange={(e) => model.user.setFirstName(e.target.value)} type="text" />
						</Form.Item>
						<Form.Item label="Last Name">
							<Input value={model.user.last_name} onChange={(e) => model.user.setLastName(e.target.value)} type="text" />
						</Form.Item>
					</Form.Row>
					<Form.Item label="Email">
						<Input value={model.user.email} disabled type="text" />
					</Form.Item>
				</Form>
			</Container.Column>
			<Container.Column span={1}></Container.Column>
		</Container>
	);
	
});

export default ModelConnector(Profile, ProfileModel);

import request from 'api/request';
import { destroy, flow, types } from 'mobx-state-tree';

import { Modal } from 'components';
const { confirm, message } = Modal;

const { array, boolean, model, identifier, optional, safeReference, string } = types;

export const CategoryModel = model('CategoryModel', {
	id: identifier,
	title: string,
	description: string,
	showActions: optional(boolean, false),
}).actions((self) => ({
	setTitle(title) {
		self.title = title;
	},
	setDescription(description) {
		self.description = description;
	},
	setShowActions(showActions) {
		self.showActions = showActions;
	},
}));

const CategoriesModel = model('CategoriesModel', {
	categories: array(CategoryModel),
	visibleCategoryModal: optional(boolean, false),
	selectedCategory: safeReference(CategoryModel),
})
	.views((self) => ({
		get baseURL() {
			return `${self.WorkspaceStore.current_workspace.baseURL}/settings/social/categories`;
		},
		get category() {
			return self.selectedCategory || CategoryModel.create({ id: 'new', title: '', description: '' });
		},
	}))
	.actions((self) => ({
		afterCreate() {
			self.fetchCategories();
			document.addEventListener('click', self.clearShowActions);
		},
		beforeDestroy() {
			document.removeEventListener('click', self.clearShowActions);
		},
		fetchCategories: flow(function* () {
			try {
				const { data } = yield request.get(self.baseURL);
				self.categories = data;
			} catch (err) {
			} finally {
				self.isLoading = false;
			}
			self.saveState();
		}),
		deleteCategory: flow(function* (id) {
			self.selectedCategory = id;
			try {
				const response = yield confirm({ text: 'Are you sure you want to delete this category?', okText: 'Delete' });
				if (!response) return;
				yield request.delete(`${self.baseURL}/${id}`);
				message({ type: 'success', text: 'Successfully deleted category.' });
				destroy(self.selectedCategory);
			} catch (err) {
			} finally {
				self.isLoading = false;
			}
		}),
		saveCategory: flow(function* (category) {
			try {
				if (category.id === 'new') {
					const { data } = yield request.post(self.baseURL, category);
					self.categories.push(data);
					message({ type: 'success', text: 'Successfully created category.' });
				} else {
					yield request.put(`${self.baseURL}/${category.id}`, category);
					message({ type: 'success', text: 'Successfully updated category.' });
					category.saveState();
				}
			} catch (err) {
			} finally {
				self.isLoading = false;
			}

			self.selectedCategory = undefined;
			self.hideCategoryModal();
		}),
		// SETTERS
		setSelectedCategory(id) {
			self.selectedCategory = id;
		},
		cancelCategory() {
			self.resetState();
			self.visibleCategoryModal = false;
		},
		editCategory(id) {
			self.selectedCategory = id;
			self.selectedCategory.setShowActions(false);
			self.showCategoryModal();
		},
		newCategory() {
			self.selectedCategory = undefined;
			self.showCategoryModal();
		},
		showActions(id) {
			for (const category of self.categories) {
				if (category.id === id) {
					category.setShowActions(true);
				} else {
					category.setShowActions(false);
				}
			}
		},
		clearShowActions(e) {
			if (e.target.closest('.category-card')) {
				e.stopPropagation();
				return;
			}
			for (const category of self.categories) {
				category.setShowActions(false);
			}
		},
		showCategoryModal() {
			self.visibleCategoryModal = true;
		},
		hideCategoryModal() {
			self.visibleCategoryModal = false;
		},
	}));

export default CategoriesModel;

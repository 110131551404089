import { Modal } from "components";
import { Workspace } from "views/app/organization-settings/workspaces/workspace/workspace.view";

const CreateWorkspaceModal = ({ onOk, onCancel, visible }) => (
	<Modal
		visible={visible}
		closable
		onOk={onOk}
		onCancel={onCancel}
		width="80%"
		footer={[]}
		footerPosition="end"
	>
		<Workspace onOk={onOk} onCancel={onCancel}/>
	</Modal>
);

export default CreateWorkspaceModal;
import { observer } from "mobx-react-lite";
import { Modal } from "components";
import { Idea } from 'views/app/ideas/idea/idea.view';

const CreateIdeaModal = observer(({ onOk, onCancel, visible }) => (
	<Modal
		visible={visible}
		closable
		onOk={onOk}
		onCancel={onCancel}
		footer={[]}
		title='Add New Idea'
		titleSVGName="emoji_objects"
		footerPosition="end"
		removePadding={true}
		marginModalContent="10px auto"
	>
		{visible && <Idea onOk={onOk}/>}
	</Modal>
));

export default CreateIdeaModal;

import { observer } from 'mobx-react-lite';
import { ModelConnector } from 'stores';
import { Button, Container, NewEntity, Select, Table, UserImage } from 'components';
import UsersModel from './users.model';
import { OrganizationRoleMap } from 'utils/utils';

import classNames from 'classnames/bind';
import styles from './users.scss';

const cx = classNames.bind(styles);

const Users = observer(({ model }) => {
	const userCreateOptions = [
		{
			key: 'role',
			options: [
				{ label: OrganizationRoleMap.get('GUEST'), value: 'GUEST', description: `Can add content. Can’t add new projects, edit settings, or view billing.` },
				{ label: OrganizationRoleMap.get('MEMBER'), value: 'MEMBER', description: `Can add new project & add content. Can’t edit settings or view billing.` },
				{ label: OrganizationRoleMap.get('ADMIN'), value: 'ADMIN', description: `Can add and edit all setting and content.` },
			],
			className: cx('new-user-role-select'),
			type: 'checked-select',
			placeholder: "select role",
			width: '280px',
		},
		{
			key: 'workspaces',
			type: 'multi-select',
			label: 'Workspaces',
			options: model.workspaceOptions,
			className: cx('new-user-workspace-select'),
			placeholder: "select workspace(s)",
			width: '200px'
		}
	];

	const columns = [{
		render: (row) => (<UserImage user={row} />),
		width: 'fit',
	},
	{
		title: 'Name',
		key: 'first_name',
		width: '150px',
		render: (row) => (<span style={{ marginLeft: '0.5em', whiteSpace: 'nowrap' }}>{row?.first_name}</span>)
	}, {
		title: 'Email',
		width: '250px',
		key: 'email',
		render: (row) => (<span style={{ marginLeft: '0.5em', whiteSpace: 'nowrap' }}>{row?.email}</span>)
	}, {
		title: 'Role',
		key: 'role',
		render: (row) => (
			<Select
				value={OrganizationRoleMap.get(row.role(model.organizationId)).toUpperCase()}
				hidden
				type="checked-select"
				options={[
					{ label: OrganizationRoleMap.get('GUEST'), value: 'GUEST', description: `Can add content. Can’t add new projects, edit settings, or view billing.`, disabled: OrganizationRoleMap.get(row.role(model.organizationId)).toUpperCase() === 'OWNER' },
					{ label: OrganizationRoleMap.get('MEMBER'), value: 'MEMBER', description: `Can add new project & add content. Can’t edit settings or view billing.`, disabled: OrganizationRoleMap.get(row.role(model.organizationId)).toUpperCase() === 'OWNER' },
					{ label: OrganizationRoleMap.get('ADMIN'), value: 'ADMIN', description: `Can add and edit all setting and content.`, disabled: OrganizationRoleMap.get(row.role(model.organizationId)).toUpperCase() === 'OWNER' },
				]}
				label={OrganizationRoleMap.get(row.role(model.organizationId))}
				onChange={(value) => row.setRole(model.organizationId, value)}
				inputStyle={{ backgroundColor: 'transparent' }}
				style={{ width: 280, marginLeft: '-0.25em' }}
			/>
		),
	}, {
		title: 'Workspaces',
		render: (row) => (
			<Select
				value={row.workspaceIds}
				hidden
				type="multi-select"
				options={model.workspaceOptions}
				onChange={row.setWorkspaces}
				label={row.workspaces.length === model.workspaces.length ? 'All' : row.workspaces.length}
				inputStyle={{ backgroundColor: 'transparent' }}
				style={{ width: 150 }}
			/>
		),
	}, {
		title: 'Last Active',
		width: '200px',
		render: (row) => {
			if (!row.last_active) return <span style={{ marginLeft: '0.5em', whiteSpace: 'nowrap' }}> - </span>;
			const date = new Date(row.last_active);
			return <span style={{ marginLeft: '0.25em', whiteSpace: 'nowrap' }}>
				{date.toLocaleDateString('en-US', {
				month: '2-digit',
				day: '2-digit',
				year: 'numeric'
				})}
			</span>
			
		},
	}, {
		render: (row) => (<Button type="text" icon="delete" onClick={() => model.deleteUser(row.id)} />),
		width: 'fit',
	},];
	return (
		<Container
			title="Users"
			actions={
				<NewEntity
					placeholder="Invite a new user by email"
					confirmText="Invite new user"
					icon="person_add_alt"
					valueKey="email"
					inputWidth="300px"
					options={userCreateOptions}
					onSubmit={model.addUser}
					className={cx('show-desktop-only')}
				/>
			}
			isLoading={model.isLoading}
		>
			<NewEntity
				placeholder="Invite a new user by email"
				confirmText="Invite new user"
				icon="person_add_alt"
				valueKey="email"
				inputWidth="330px"
				options={userCreateOptions}
				onSubmit={model.addUser}
				className={cx('show-mobile-only')}
			/>
			<div className={cx('user-table')}>
				<Table
					columns={columns}
					data={model.users}
				/>
			</div>
		</Container>
	);
});

export default ModelConnector(Users, UsersModel);

import request from 'api/request';
import {destroy, flow, types} from 'mobx-state-tree';
import {IdeaModel} from 'models/idea.model';
import debounce from 'lodash.debounce';

import React from 'react';
import {Button, Modal, SVG} from 'components';

import classNames from 'classnames/bind';
import styles from './ideas.scss';

const cx = classNames.bind(styles);

const {confirm} = Modal;
const {array, boolean, model, optional, safeReference, string} = types;
// Model
const IdeasModel = model('IdeasModel', {
  searchTerm: optional(string, ''),
  ideas: array(IdeaModel),
  isLoading: optional(boolean, true),
  selectedIdea: safeReference(IdeaModel),
  visibleCreateIdeaModal: optional(boolean, false),
  showStarred: optional(boolean, true),
  showIdeas: optional(boolean, true),
})
  .views((self) => ({
    get baseURL() {
      return `${self.WorkspaceStore.current_workspace.baseURL}/ideas`;
    },
    get campaignBaseURL() {
      return `${self.WorkspaceStore.current_workspace.baseURL}/campaigns`;
    },
    get starredIdeas() {
      return self.ideas.filter((idea) => idea.starred_at);
    },
    get unStarredIdeas() {
      return self.ideas.filter((idea) => !idea.starred_at);
    },
    ideasList(ideas) {
      return ideas
        .sort((a, b) => (new Date(b.updated_at) - new Date(a.updated_at)))
        .map((idea) => ({
          id: idea.id,
          title: <div style={{ minWidth: 270, justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
						<div>
							<Button
							type="text"
							icon={idea.starred_at ? 'star' : 'star_border'}
							onClick={(e) => {
								e.stopPropagation();
								idea.setStarredAt();
							}}
							style={{ padding: 0, marginRight: '1em'}}
							/>
							{idea.title}
						</div>
						<div key="date-and-sponsored" className="flex-center">
              {idea.sponsored_platforms.length > 0 &&
                <SVG key="flag" name="handshake" iconSize={16}/>}
            </div>
          </div>,
          actions: [
            self.AuthStore.hasMemberAccess && <Button
							style={{ minWidth: 200 }}
              className={cx('ideas-to-campaigns-button')}
              key="campaigns"
              type="text"
              icon="view_timeline"
              onClick={(e) => self.moveIdeaToCampaign(e, idea)}
            >
              Move to Campaigns
            </Button>,
            self.AuthStore.hasMemberAccess && <Button
              className={cx('ideas-delete-button')}
              key="delete"
              type="text"
              icon="delete"
              onClick={(e) => {
                e.stopPropagation();
                self.removeIdea(idea);
              }}
            />,
          ],
          onRowClick: () => self.props.navigate(`/ideas/${idea.id}`)
        }));
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.fetchIdeas();
      document.addEventListener('refresh-ideas', self.fetchIdeas, false);
    },
    beforeDestroy() {
      document.removeEventListener('refresh-ideas', self.fetchIdeas, false);
    },
    removeIdeaFromList(ideaToRemove) {
      const newIdeas = self.ideas;
      const index = newIdeas.findIndex((idea) => idea.id === ideaToRemove.id);
      if (index > -1) {
        newIdeas.splice(index, 1);
        self.ideas = newIdeas;
      }
    },
    searchIdeas: debounce(() => self.fetchIdeas(), 700),
    fetchIdeas: flow(function* () {
      self.isLoading = true;

      try {
        const {data} = yield request.get(self.baseURL, {
          params: {
            search_term: self.searchTerm,
          }
        });
        self.ideas = data;
      } catch (err) {
      } finally {
        self.isLoading = false;
      }
    }),
    moveIdeaToCampaign: flow(function* (e, idea) {
      e.stopPropagation();
      try {
        const {data} = yield request.post(self.campaignBaseURL, {
          idea_id: idea.id,
        });

        self.props.navigate(`/campaigns/${data.campaign_id}`);
      } catch (err) {
        console.error(err);
      }
    }),
    removeIdea: flow(function* (idea) {
      if (yield confirm({text: 'Are you sure you want to delete this idea?', okText: 'Delete'})) {
        yield request.delete(`${self.baseURL}/${idea.id}`);
        destroy(idea);
      }
    }),
    onIdeaCreation() {
      self.fetchIdeas();
      self.hideCreateIdeaModal();
    },
    showCreateIdeaModal() {
      self.visibleCreateIdeaModal = true;
    },
    hideCreateIdeaModal() {
      self.visibleCreateIdeaModal = false;
    },
    toggleShowStarred() {
      self.showStarred = !self.showStarred;
    },
    toggleShowIdeas() {
      self.showIdeas = !self.showIdeas;
    },
    setSearchTerm(searchTerm) {
      self.searchTerm = searchTerm;
      self.isLoading = true;
      self.searchIdeas();
    },
  }));

export default IdeasModel;

import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { applySnapshot, destroy, getSnapshot, types } from 'mobx-state-tree';

import { AuthStoreModel } from 'stores/auth.store';
import { WorkspaceStoreModel } from 'stores/workspace.store';

const AuthStore = AuthStoreModel.create({});
const WorkspaceStore = WorkspaceStoreModel.create({});

export const rootStore = {
	AuthStore,
	WorkspaceStore,
};

const withRouter = (WrappedComponent) => (props) => {
	const params = useParams();
	const navigate = useNavigate();
	const location = useLocation();

	return <WrappedComponent {...props} params={params} location={location} navigate={navigate} />;
};

function withSnapshots(modelType) {
	const ComposedType = types.compose(
		modelType,
		types
			.model({
				snapshot: types.frozen(),
			})
			.actions((self) => ({
				afterCreate() {
					self.saveState();
				},
				saveState() {
					self.initialState = getSnapshot(self);
				},
				resetState() {
					applySnapshot(self, self.initialState);
				},
			}))
	);

	return ComposedType;
}

function createModel(model, props) {
	const newModel = withSnapshots(
		model
			.views(() => ({
				get AuthStore() {
					return rootStore.AuthStore;
				},
				get WorkspaceStore() {
					return rootStore.WorkspaceStore;
				},
			}))
			.volatile(() => ({
				props,
			}))
			.actions((self) => ({
				updateProps(nextProps) {
					self.props = nextProps;
				},
				isLatestVersion(){
					return rootStore.AuthStore.checkLastVersion();
				}
			}))
	);
	return newModel.create({});
}

export const ModelConnector = (WrappedComponent, modelObject) => {
	return withRouter(
		class extends React.Component {
			constructor(props) {
				super(props);
				this.model = createModel(modelObject, props);
				// this.rollback = model.rollback;
			}

			shouldComponentUpdate(nextProps) {
				this.model.isLatestVersion();
				this.model.updateProps(nextProps);
				return true;
			}

			componentWillUnmount() {
				destroy(this.model);
			}

			render() {
				return <WrappedComponent model={this.model} {...this.props} />;
			}
		}
	);
};

import request from 'api/request';
import { rootStore } from 'stores';
import { flow, types } from 'mobx-state-tree';
import { WorkspaceModel } from 'models/workspace.model';

const { model, array, boolean, optional, safeReference, frozen } = types;

// Model
export const WorkspaceStoreModel = model('WorkspaceStoreModel', {
	workspaces: array(WorkspaceModel),
	current_workspace: safeReference(WorkspaceModel),
	campaignFilters: frozen({}),
	plannerFilters: frozen({}),
	visibleNewWorkspaceModal: optional(boolean, false),
	visibleNewIdea: optional(boolean, false),
	visibleCreateIdeaModal: optional(boolean, false),
})
	.views((self) => ({
		get baseURL() {
			return `${rootStore.AuthStore.baseURL}/workspaces`;
		},
		get currentBaseURL() {
			return `${self.baseURL}/${self.current_workspace.id}`;
		},
		// Used in a Menu
		get workspaceItems() {
			return [
				{
					icon: 'add_box',
					text: 'New Workspace',
					header: true,
					action: rootStore.WorkspaceStore.showNewWorkspaceModal,
					access: rootStore.AuthStore.hasAdminAccess,
				},
				...self.workspaces.slice().map((workspace) => ({
					text: workspace.name,
					action: () => self.selectCurrentWorkspace(workspace.id),
					state: self.current_workspace?.id === workspace.id && 'check',
				})),
			];
		},
	}))
	.actions((self) => ({
		fetchWorkspaces: flow(function* () {
			try {
				const { data } = yield request.get(self.baseURL);
				self.workspaces = data;
			} catch (err) {
				console.error(err);
			} finally {
				self.isLoading = false;
			}
		}),
		selectCurrentWorkspace: flow(function* (workspace_id) {
			if (self.workspaces.find((workspace) => workspace.id === workspace_id)) {
				yield request.post(`${self.baseURL}/${workspace_id}/user-select`);
				self.current_workspace = workspace_id;
				rootStore.AuthStore.updateCurrentWorkspacePendo(workspace_id);
			}
		}),

		// Modal stuff
		onNewWorkspace: flow(function* (workspace) {
			yield self.fetchWorkspaces();
			yield self.selectCurrentWorkspace(workspace.id);
			self.hideNewWorkspaceModal();
		}),
		hideNewWorkspaceModal() {
			self.visibleNewWorkspaceModal = false;
		},
		showNewWorkspaceModal() {
			self.visibleNewWorkspaceModal = true;
		},
		setCampaignsFilters(filters) {
			self.campaignFilters = filters;
		},
		setPlannerFilters(filters) {
			self.plannerFilters = filters;
		},
		onNewIdea() {
			const event = new CustomEvent('refresh-ideas', {
				bubbles: true,
			});
			document.dispatchEvent(event);
			self.visibleCreateIdeaModal = false;
		},
		showNewIdeaButton() {
			self.visibleNewIdea = true;
		},
		hideNewIdeaButton() {
			self.visibleNewIdea = false;
		},
		showCreateIdeaModal() {
			self.visibleCreateIdeaModal = true;
		},
		hideCreateIdeaModal() {
			self.visibleCreateIdeaModal = false;
		},
	}));

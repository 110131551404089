import request from 'api/request';
import { flow, getSnapshot, types } from 'mobx-state-tree';
import { UserModel } from 'models/user.model';

import { WorkspaceModel } from 'models/workspace.model';
import { PlatformLabelMap } from 'utils/utils';
import { PlatformIconMap } from 'utils/utils';
import { Modal } from 'components';

const { message } = Modal;
const { model, array, boolean, optional, maybeNull } = types;
// Model
const WorkspaceViewModel = model('WorkspaceViewModel', {
	isLoading: optional(boolean, true),
	_workspace: maybeNull(WorkspaceModel),
	users: array(UserModel),
})
	.views((self) => ({
		get baseURL() {
			return self.shouldUpdate ?
				`/organizations/${self.organizationId}/workspaces/${self.workspaceId}` :
				`/organizations/${self.organizationId}/workspaces`;
		},
		get usersBaseURL() {
			return `/organizations/${self.organizationId}/users`;
		},
		get shouldFetch() {
			return self.props.params?.workspace_id;
		},
		get shouldUpdate() {
			return self.props.params?.workspace_id || self.shouldConfigure;
		},
		get shouldConfigure() {
			return self.props.shouldConfigure;
		},
		get workspaceId() {
			return self.props.params?.workspace_id || self.workspace?.id;
		},
		get workspace() {
			return self.shouldConfigure ? self.WorkspaceStore.current_workspace : self._workspace;
		},
		get organizationId() {
			return self.props.params.organization_id || self.AuthStore.currentOrganizationId;
		},
		get pillarPlatformOptions() {
			return self.workspace.platforms.map((platform) => ({
				icon: PlatformIconMap.get(platform),
				label: PlatformLabelMap.get(platform),
				value: platform,
			}))
		},
	}))
	.actions((self) => ({
		afterCreate() {
			if (self.shouldFetch) {
				self.fetchWorkspace();
			} else {
				self._workspace = {
					id: 'new',
					name: '',
					primary_platform: null,
					users: [getSnapshot(self.AuthStore.user)],
				};
				self.isLoading = false;
			}

			self.fetchUsers();
		},
		fetchWorkspace: flow(function* () {
			const { data } = yield request.get(self.baseURL);
			self._workspace = data;
			self.isLoading = false;
		}),
		fetchUsers: flow(function* () {
			const { data } = yield request.get(self.usersBaseURL);
			self.users = data;
		}),
		createWorkspace: flow(function* () {
			const { data } = yield request.post(self.baseURL, {
				...getSnapshot(self._workspace),
				organization_id: self.organizationId,
			});

			message({ type: 'success', text: 'Successfully created workspace.'})
			if (self.props.preventFetch) return data;

			self.WorkspaceStore.fetchWorkspaces();
			return data;
		}),
		saveWorkspace: flow(function* () {
			const data = yield self.createWorkspace();

			if (self.props.onOk) {
				self.props.onOk(data);
			} else {
				self.props.navigate(`/organization-settings/${self.props.params.organization_id}/workspaces`);
			}
		}),
	}));

export default WorkspaceViewModel;

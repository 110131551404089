import React, { Component } from 'react';

import './time-picker.scss';
import classNames from 'classnames';

export default class TimePicker extends Component {
	constructor(props) {
		super(props);
		const initialValue = props.defaultValue;

		this.state = { selectedValue: initialValue };
	}

	setSelectedValue = (selectedValue) => {
		this.setState({
			selectedValue,
		});
	};

	onChange = (value) => {
		this.setSelectedValue(value);
		this.props.onChange(value);
	};

	render() {
		const { className } = this.props;

		return (
			<div className={classNames('tejik-timepicker', className)}>
				<input type="time" min="09:00" max="18:00" onChange={this.onChange}/>
			</div>
		);
	}
}

import { observer } from 'mobx-react-lite';
import { ModelConnector } from 'stores';
import { Button, Form, Input, Title } from 'components';
import { TejikLogo } from 'components/svg/svg';
import ResetPasswordModel from './reset-password.model';

import classNames from 'classnames/bind';
import styles from './reset-password.scss';

const cx = classNames.bind(styles);
const ResetPassword = observer(({ model }) => {
	return (
		<div className={cx('tejik-password-container')}>
			<div className={cx('tejik-password')}>
				<TejikLogo />
				<Title size={4} weight={600}>Password Reset</Title>
				<Form onSubmit={model.confirmReset} style={{ paddingRight: 0, paddingLeft: 0 }}>
					<Form.Item label="New Password">
						<Input value={model.password} onChange={(e) => model.setPassword(e.target.value)} type="password"/>
					</Form.Item>
					<Form.Item>
						<Button htmlType="submit" type="secondary" size="large" style={{ width: '100%', padding: 14, justifyContent: 'center', flex: 1 }}>Reset Password</Button>
					</Form.Item>
				</Form>
			</div>
		</div>
	);
});

export default ModelConnector(ResetPassword, ResetPasswordModel);

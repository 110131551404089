import {observer} from 'mobx-react-lite';
import {
  Container, 
  Form, 
  Input,
  Button, 
  RichText} 
from 'components';

import CampaignTasks from '../campaign-tasks/campaign-tasks.view';
import ContentLinks from '../../contentLinks';

import classNames from 'classnames/bind';
import styles from './email.scss';

const cx = classNames.bind(styles);

const EmailView = observer(({campaign_platform, delePlatformFn}) => {
  return (
    <Container noPadding>
      <Container.Column span={7}>
        <div>
          <div className={cx('campaign-keyword-research', 'show-mobile-only-flex')}>
            <div 
              style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', cursor: 'pointer' }}
              onClick={e => {
                e.stopPropagation();
                delePlatformFn(campaign_platform.platform);
              }}
            >
              <span style={{ fontSize: 12, color: '#707070', marginRight: '0.5em'}}>Delete platform</span>
              <Button
                type="text"
                icon="delete"
                iconSize={16}
                style={{ padding: 0, color: '#707070' }}
              ></Button>
            </div>
          </div>
          <Form.Item label="Subject Line:">
            <Input
              value={campaign_platform.data.subject}
              onChange={e => campaign_platform.data.setSubject(e.target.value)}
              type="text"
              inputStyle={{ backgroundColor: 'transparent' }}
              canCopy
            />
            <div className={cx('character-limit-text')}>
              {campaign_platform?.character_limit.title - (campaign_platform?.data.subject?.length ?? 0)}{' '}
              characters left
            </div>
          </Form.Item>
          <Form.Item label="Email Content:">
            <RichText 
              placeholder='description for search'
              initialState={campaign_platform?.data?.content || ''} 
              onChange={state => campaign_platform.data.setContent(state)} 
              canCopy
            />
          </Form.Item>
          {/* {campaign_platform.keyword_research && !campaign_platform.keyword_research?.complete &&
            <Button onClick={campaign_platform.keyword_research.markKeywordResearchDone} style={{ marginBottom: '1em' }}>
              Keyword Research Done
            </Button>
          } */}
          <Form.Item>
            <ContentLinks
              campaign_content_links={campaign_platform?.campaign_content_links}
              platform={campaign_platform?.platform}
              addCampaignContentLink={campaign_platform?.addCampaignContentLink}
            />
          </Form.Item>
          <Form.Item className={cx('show-mobile-only')}>
            <CampaignTasks campaign_tasks={campaign_platform?.sorted_campaign_tasks} />
          </Form.Item>
        </div>
      </Container.Column>
      <Container.Column span={5} className={cx('show-desktop-only')}>
        <div className={cx('campaign-keyword-research')}>
          <div className={cx('campaign-email-delete-link')}
            onClick={e => {
              e.stopPropagation();
              delePlatformFn(campaign_platform.platform);
            }}
          >
            <span style={{ fontSize: 12, color: '#707070', marginRight: '0.5em'}}>Delete platform</span>
            <Button
              type="text"
              icon="delete"
              iconSize={16}
              style={{ padding: 0, color: '#707070' }}
            ></Button>
          </div>
        </div>
        <CampaignTasks campaign_tasks={campaign_platform?.sorted_campaign_tasks} />
      </Container.Column>
    </Container>
  );
});

export default EmailView;

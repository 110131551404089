/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';

import classNames from 'classnames';
import {Button} from 'components';

export default function List({className, rows, defaultSelect, style, scrollToView}) {
  const [active, setActive] = useState(null);

  useEffect(() => {
		if(defaultSelect) {
			toggleExpanded(rows[0].id);
		} else {
			return () => setActive(false);
		}
  }, [])

  const toggleExpanded = (id) => {
		// Reset all existing ones.
		const tempElem = document.querySelector(`[data-id="${active}"].tejik-list-item-expanded`);
		if (tempElem) {
			tempElem.style.maxHeight = 0;
		}

		const header = document.querySelector('.tejik-list-item-last');
		header.style.borderBottomLeftRadius = '10px';
		header.style.borderBottomRightRadius = '10px';

		// Toggle list item
		if (active === id) {
			const elem = document.querySelector(`[data-id="${active}"].tejik-list-item-expanded`);
			elem.style.maxHeight = 0;
			setActive(null);
		} else {
			setActive(id);
			expand(id);
		}
  };

  const expand = (id) => {
		const header = document.querySelector(`[header-id="${id}"]`);
		if(header) {
			header.style.borderBottomLeftRadius = 0;
			header.style.borderBottomRightRadius = 0;
		}


		const elem = document.querySelector(`[data-id="${id}"].tejik-list-item-expanded`);
		if(elem) {
			const parent = elem.closest('.main-content');
			elem.style.maxHeight = '100%';

			setTimeout(() => {
				if(scrollToView) parent.scrollTo({ top: header.offsetTop - header.offsetHeight - 20, behavior: 'smooth' });
			}, 100);
		}
  }

  if (active) {
		expand(active);
  }

  return (
	<ul className={classNames('tejik-list', className)} style={style}>
	  {Array.from(rows).map((row, idx) => (
		<React.Fragment key={row.id}>
		  <li
		  	header-id={row.id}
				className={classNames('tejik-list-item', {
					'tejik-list-item-expandable': row.expandedView && active !== row.id,
					'tejik-list-item-clickable': row.onRowClick && active !== row.id,
					'tejik-list-item-selected': active === row.id,
					'tejik-list-item-first': idx === 0,
					'tejik-list-item-last': idx === rows.length -1,
				}, row.className)}

			onClick={() => {
			  if (row.onRowClick) {
					row.onRowClick();
					return
			  }

			  if (!row.expandedView) return;
			  toggleExpanded(row.id)
			}}
		  >
			<div className="tejik-list-item-title" onClick={() => {
			  if (row.onTitleClick) {
					row.onTitleClick();
			  }
			}}>{row.title}</div>
			<div className="tejik-list-item-actions">
			  {row.actions}
			  {row.expandedView && !row.onRedirectClick &&
			  	<div className="tejik-list-item-action">
						<Button type="text" icon={active === row.id ? 'expand_less' : 'expand_more'} iconSize={20}/>
					</div>
				}
				{row.onRedirectClick &&
			  	<div className="tejik-list-item-redirect">
						<Button type="text" icon='chevron_right' iconSize={20} onClick={row.onRedirectClick}/>
					</div>
				}
			</div>
		  </li>
		  {row.expandedView &&
			<div data-id={row.id} className={classNames('tejik-list-item-expanded', {'active': active === row.id})}>
			  <li className="tejik-list-item" key={row.id}>
				{row.expandedView}
			  </li>
			</div>}
		</React.Fragment>
	  ))}
	</ul>
  )
}
import React, { Component } from 'react';

import classNames from 'classnames';
import { Observer } from 'mobx-react-lite';
import { PlatformIconMap, PlatformLabelMap } from 'utils/utils';
import { Button } from 'components';

class PlatformSelection extends Component {
	
	get platformOptions() {
		return this.props.workspacePlatforms.map((platform) => ({
			icon: PlatformIconMap.get(platform),
			label: PlatformLabelMap.get(platform),
			value: platform,
		}));
	}

	hasPlatform(value) {
		return this.props.platforms.find((platform) => (platform === value))
	}

	render() {
		const { className, togglePlatform } = this.props;

		return (
			<Observer>{() => (
				<div className={classNames('tejik-platform-selection', className)}>
					{this.platformOptions.map((option) => (
						<Button
							key={option.value}
							className={classNames('tejik-platform-selection-button', { 'selected': this.hasPlatform(option.value) })}
							type="text"
							icon={option.icon}
							iconSize={20}
							onClick={() => togglePlatform(option.value)}
						/>
					))}
				</div>
			)}</Observer>
		);
	}
}

export default PlatformSelection;
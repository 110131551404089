import { Outlet, useParams } from "react-router-dom";
import Sidebar from "views/base/sidebar/sidebar";
import { rootStore } from "stores";

import classNames from 'classnames/bind';
import styles from 'styles/utils.scss';

const cx = classNames.bind(styles);

function OrganizationSettings() {
	const { organization_id } = useParams();

	const organizationSettingsMenuItems = [{
		icon: 'keyboard_backspace',
		text: 'Back',
		to: '/',
		exact: true,
	}, {
		icon: 'settings',
		text: 'Organization',
		to: `/organization-settings/${organization_id}`,
		exact: true,
	}, {
		icon: 'workspaces',
		text: 'Workspaces',
		to: `/organization-settings/${organization_id}/workspaces`
	}, {
		icon: 'people',
		text: 'Users',
		to: `/organization-settings/${organization_id}/users`
	}, rootStore.AuthStore.current_organization.role === 'OWNER' && {
		icon: 'credit_card',
		text: 'Billing',
		href: process.env.NODE_ENV === 'development' ? 'https://billing.stripe.com/p/login/test_bIYfZz8z79lW1EI144' : 'https://billing.stripe.com/p/login/9AQ9Ep9Dpaln1KU4gg',
	}];
	
    return (
		<div className="main">
			<Sidebar menuItems={organizationSettingsMenuItems}/>
			<div style={{ width: 300, height: '100%' }} className={cx('show-desktop-only')}></div>

			<div key={organization_id} className="main-content" id="main-content">
				<Outlet />
			</div>
		</div>
    );
}

export default OrganizationSettings;

import request from 'api/request';

import { flow, getSnapshot, types } from 'mobx-state-tree';
import { PlatformEnum } from 'models/global/global.model';
import debounce from 'lodash.debounce';
import { UserModel } from 'models/user.model';
import { CharacterLimitModel } from './character-limits.model';
import { IsSocialMediaPlatform, PlatformCharacterLimitSettings } from 'utils/utils';
import { CTAModel } from 'views/app/workspace-settings/social/call-to-action/call-to-action.model';
import { PinterestBoardModel } from 'views/app/workspace-settings/social/pinterest-board/pinterest-board.model';
import { CategoryModel } from 'views/app/workspace-settings/social/categories/categories.model';
import { ContentTypeModel, SocialCaptionModel } from 'views/app/workspace-settings/social/social.model';
import { HookGroupModel } from 'views/app/workspace-settings/social/hooks/hooks.model';
import { HashtagGroupModel } from 'views/app/workspace-settings/social/hashtags/hashtags.model';
import { StatusTemplateModel } from './status-template.model';
import { ContentLinkModel } from './content-link.model';
import { Modal } from 'components';
import { PlatformHashtagCount } from './platform_hashtag_count.model';

const { message } = Modal;
const { array, identifier, maybeNull, optional, string, model } = types;

export const WorkspaceModel = model('WorkspaceModel', {
	id: identifier,
	organization_id: optional(string, ''),
	name: optional(string, ''),
	domain: maybeNull(string),
	primary_platform: maybeNull(PlatformEnum),
	platforms: array(PlatformEnum),
	users: array(UserModel),

	character_limits: array(CharacterLimitModel),
	call_to_actions: array(CTAModel),
	categories: array(CategoryModel),
	content_links: array(ContentLinkModel),
	content_types: array(ContentTypeModel),
	hashtag_groups: array(HashtagGroupModel),
	platform_hashtag_counts: array(PlatformHashtagCount),
	hook_groups: array(HookGroupModel),
	social_caption: maybeNull(SocialCaptionModel),
	status_templates: array(StatusTemplateModel),
	pinterest_boards: array(PinterestBoardModel),
})
	.views((self) => ({
		get baseURL() {
			return `/organizations/${self.organization_id}/workspaces/${self.id}`;
		},
		get shorthand() {
			return self.name
				.split(' ')
				.map((word) => word[0])
				.join('');
		},
		get joinedSocialPlatforms() {
			let hasAtLeastOne = false;
			const platforms = self.platforms.filter((platform) => {
				const isSocial = IsSocialMediaPlatform(platform);
				if (isSocial) {
					hasAtLeastOne = true;
					return false;
				} else {
					return true;
				}
			});
			if (hasAtLeastOne) {
				platforms.push('SOCIAL');
			}

			return platforms;
		},
		get contentLinkPlatforms() {
			let hasAtLeastOne = false;
			const platforms = self.platforms.filter((platform) => {
				const isSocial = IsSocialMediaPlatform(platform);
				if (isSocial) {
					hasAtLeastOne = true;
					return false;
				} else {
					return true;
				}
			});
			if (hasAtLeastOne) {
				platforms.push('SOCIAL');
			}

			return ['SIDEMENU', ...platforms];
		},
		get owners(){
			return self.users.filter(user => user.organizations.some(org => org.organization_id === self.organization_id && org.role === 'OWNER'));
		},
		getCharacterLimit(platform) {
			const characterLimit = self.character_limits.find((limit) => limit.platform === platform);

			const defaults = PlatformCharacterLimitSettings.get(platform);
			let title = defaults.title?.value;
			let description = defaults.description?.value || '';

			return { title: characterLimit?.title || title, description: characterLimit?.description || description };
		},
		hasPlatform(value) {
			return self.platforms.includes(value);
		},
		hasUser(id) {
			return self.users.find((user) => user.id === id);
		},
		statusOptions(platforms) {
			const activeStatuses = [];
			const doneStatuses = [];
			const closedStatuses = [];
			for (const status_template of self.status_templates) {
				if (Array.from(status_template.platforms).some((platform) => platforms?.includes(platform))) {
					for (const status of status_template.statuses) {
						if (status.type === 'ACTIVE')
							activeStatuses.push(status);
						else if (status.type === 'DONE')
							doneStatuses.push(status);
						else if (status.type === 'CLOSED')
							closedStatuses.push(status);
						else continue;
					}
				}
			}
			return [
				...activeStatuses.sort((a, b) => a.order - b.order),
				...doneStatuses.sort((a, b) => a.order - b.order),
				...closedStatuses.sort((a, b) => a.order - b.order),
			];
		},
	}))
	.actions((self) => ({
		saveWorkspace: debounce((...props) => self.updateWorkspace(...props), 700),
		updateWorkspace: flow(function* () {
			try {
				yield request.put(self.baseURL, getSnapshot(self));
				message({ type: 'success', text: 'Successfully updated workspace.'})
			} catch (err) {
				throw err;
			} finally {
			}
		}),

		// Platform
		addPlatform(value) {
			self.platforms.push(value);
		},
		removePlatform(value) {
			const index = self.platforms.findIndex((platform) => platform === value);
			self.platforms.splice(index, 1);
		},
		togglePlatform(value) {
			if (self.hasPlatform(value)) {
				self.removePlatform(value);
			} else {
				self.addPlatform(value);
			}

			if (self.id !== 'new') {
				self.saveWorkspace();
			}
		},
		addPinterestBoard(board) {
			self.pinterest_boards.push(board);
		},

		// Users
		addUser(id, user) {
			self.users.push(user);
		},
		removeUser(id) {
			const index = self.users.findIndex((user) => user.id === id);
			self.users.splice(index, 1);
		},
		toggleUser(id, user) {
			if (self.hasUser(id)) {
				self.removeUser(id);
			} else {
				self.addUser(id, getSnapshot(user));
			}
			
			if (self.id !== 'new') {
				self.saveWorkspace();
			}
		},

		// Setters
		setDomain(domain) {
			self.domain = domain;
			if (self.id !== 'new') {
				self.saveWorkspace();
			}
		},
		setName(name) {
			self.name = name;
			if (self.id !== 'new') {
				self.saveWorkspace();
			}
		},
		setPlatforms(platforms) {
			self.platforms = platforms;
			if (self.id !== 'new') {
				self.saveWorkspace();
			}
		},
		setPrimaryPlatform(primary_platform) {
			self.primary_platform = primary_platform;
			if (self.id !== 'new') {
				self.saveWorkspace();
			}
		},
	}));

import { Outlet } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { ModelConnector, rootStore } from 'stores';
import { Container, Title } from "components";

import NewOrganizationView from "views/app/organization/new-organization.view";
import Sidebar from "views/base/sidebar/sidebar";
import MainModel from "./main.model";

import classNames from 'classnames/bind';
import styles from './main.scss';
import { useEffect } from "react";

const cx = classNames.bind(styles);

const Main = observer(({ model }) => {
	const mainMenuItems = [{
	// 	text: <Button icon="add" id="main-create-idea-button" type="hollow">New Idea</Button>,
	// 	action: model.showCreateIdeaModal,
	// 	access: rootStore.AuthStore.hasMemberAccess,
	// }, {
		icon: 'dashboard',
		text: 'Dashboard',
		to: '/',
		exact: true,
	}, {
		icon: 'view_timeline',
		text: 'Campaigns',
		to: '/campaigns',
	}, {
		icon: 'calendar_today',
		text: 'Planner',
		to: '/planner',
	}, {
		icon: 'emoji_objects',
		text: 'Ideas',
		to: '/ideas',
	}, {
		icon: 'settings',
		text: 'Settings',
		to: '/workspace-settings',
		access: rootStore.AuthStore.hasAdminAccess,
	},
		// {
		// 	icon: 'poll',
		// 	text: 'Analytics',
		// 	to: '/analytics',
		// }
	];

	// const bottomItems = [{
	// 	icon: 'help_center',
	// 	text: 'Help',
	// 	to: '/help',
	// }];

	const workspace = rootStore.WorkspaceStore?.current_workspace;

	// Has no organization -> is creating organization -> pricing table 
	// Has organization and has billing setup and no workspaces -> create workspace -> created workspace
	// Hasn't finished initial tutorial

	if (!rootStore.AuthStore.currentOrganizationId) {
		return (
			rootStore.AuthStore.hasAvailableOrganizations ?
				<Container title="Select a organization to start" className={cx('workspace-cards')}>
					<Container.Column span={12}>
						<p>Here are some available organizations that you have been invited to.</p>
					</Container.Column>
					{rootStore.AuthStore?.organizations.map((organization) => (
						<Container.Column
							key={organization.id}
							span={3}
							className={cx('workspace-card')}
							onClick={() => rootStore.AuthStore.selectCurrentOrganization(organization.id)}
						>
							<span>{organization.name}</span>
						</Container.Column>
					))}
				</Container>
				:
				<NewOrganizationView />
		);
	}

	if (rootStore.AuthStore.current_organization.payment_status === 'NOT_SETUP') {
		return <NewOrganizationView state="billing" />
	}

	if (rootStore.AuthStore.current_organization.payment_status === 'UNPAID') {
		return <Container>
			<Title size={3}>Please ask your organization admin to update subscription</Title>
		</Container>
	}

	if (!workspace) {
		if (rootStore.AuthStore.current_organization.role === 'OWNER') {
			return <NewOrganizationView state="workspace" />
		} else {
			return <NewOrganizationView state="not-owner" />
		}
	}

	useEffect(() => {
		rootStore.WorkspaceStore.showNewIdeaButton();
	}, []);

	return (
		<div className={cx('main')}>
			<Sidebar allowWorkspaceSelect menuItems={mainMenuItems} />
			<div style={{ width: 300, height: '100%' }} className={cx('show-desktop-only')}></div>

			<div className={cx('main-content')} id="main-content">
				<Outlet key={workspace.id} />
			</div>
		</div>
	);
});

export default ModelConnector(Main, MainModel);

import request from 'api/request';
import { UserModel } from 'models/user.model';
import { OrganizationModel } from 'models/organization.model';
import { Modal } from 'components';

import { flow, types } from 'mobx-state-tree';
import { rootStore } from 'stores';
const { array, boolean, model, maybeNull, optional, safeReference, string } = types;

const { message } = Modal;

export const PendoModel = model('PendoModel', {
	currentOrganizationId: string,
	currentWorkspaceId: string
})
// Model
export const AuthStoreModel = model('AuthStore', {
	showSidebar: optional(boolean, false),
	isLoading: optional(boolean, true),
	isLatestVersion: optional(boolean, true),
	user: maybeNull(UserModel),
	organizations: array(OrganizationModel),
	current_organization: safeReference(OrganizationModel),
	changeOrganizationsModal: optional(boolean, false),
	currentPendoUserAndSpace: maybeNull(PendoModel),
	currentCampaignPlatform: optional(string, ''),
})
	.views((self) => ({
		get isAuthenticated() {
			if (self.user) return true;
			return false;
		},
		get hasAvailableOrganizations() {
			return self.organizations.length > 0;
		},
		get currentOrganizationId() {
			return self.current_organization?.id;
		},
		get currentRole() {
			return self.current_organization?.role;
		},
		get hasAdminAccess() {
			return self.currentRole === 'ADMIN' || self.currentRole === 'OWNER';
		},
		get hasMemberAccess() {
			return self.currentRole === 'MEMBER' || self.currentRole === 'ADMIN' || self.currentRole === 'OWNER';
		},
		get hasOwnerAccess() {
			return self.currentRole === 'OWNER';
		},
		get baseURL() {
			return `/organizations/${self.currentOrganizationId}`;
		},
	}))
	.actions((self) => ({
		afterCreate() {
			self.fetchSession();
		},
		checkLastVersion: flow(function*() {
			const { data } = yield request.get('/auth/version');
			self.isLatestVersion = data === self.user?.appVersion;
		}),
		fetchSession: flow(function* (updatePendo) {
			try {
				self.isLoading = true;
				const { data } = yield request.get('/auth/session');

				self.user = data;
				yield self.fetchOrganizations();
				self.current_organization = data.current_organization_id;
				
				if (self.currentOrganizationId) {
					yield rootStore.WorkspaceStore.fetchWorkspaces();
					yield rootStore.WorkspaceStore.selectCurrentWorkspace(data.current_workspace_id);
				}

				self.currentPendoUserAndSpace = {
					currentOrganizationId: self.current_organization?.id,
					currentWorkspaceId: data.current_workspace_id
				}

				if(updatePendo){
					self.updatePendo();
				}else{
					self.initializePendo()
				}

			} catch (err) {
				console.error(err);
			} finally {
				self.isLoading = false;
			}
		}),
		selectCurrentOrganization: flow(function* (organization_id, show_message = true) {
			if (self.current_organization?.id === organization_id) return;

			yield request.post('/organizations/select-organziation', { organization_id });
			self.changeOrganizationsModal = false;
			yield self.fetchSession(true);
			
			if (show_message) {
				message({ type: 'success', text: 'You have successfully switched organizations.' });
			}
		}),
		fetchOrganizations: flow(function* () {
			try {
				const { data } = yield request.get('/organizations');
				self.organizations = data;
			} catch (err) {
				console.error(err);
			}
		}),
		logOut: flow(function* () {
			try {
				if(window.pendo){
					try {
						window.pendo.clearSession();
						window.pendo.stopSendingEvents();
					} catch (error) {
						console.error('Not able to untrack pendo');
						console.error(error);
					}
				}
				yield request.post('/auth/logout');
			} catch (err) {
				console.log(err);
			}
		}),
		setChangeOrganizationsModal(changeOrganizationsModal) {
			self.changeOrganizationsModal = changeOrganizationsModal;
		},
		setCampaignPlatform(value) {
			self.currentCampaignPlatform = value;
		},
		closeSidebar() {
			self.showSidebar = false;
		},
		toggleShowSidebar() {
			self.showSidebar = !self.showSidebar;
		},
		updateCurrentWorkspacePendo(currentWorkspaceId) {
			if(self.currentPendoUserAndSpace){
				self.currentPendoUserAndSpace = {
					...self.currentPendoUserAndSpace,
					currentWorkspaceId
				}
				self.updatePendo();
			}
		},
		initializePendo(){
			const { currentOrganizationId, currentWorkspaceId } = self.currentPendoUserAndSpace;

			window.pendo.initialize({
				visitor: {
					id: self.user?.id,
					email: self.user?.email,
					full_name: `${self.user?.first_name || ''} ${self.user?.last_name}`,
					role: self.currentRole
				},
				account: {
					id: currentOrganizationId,
					name: self.current_organization?.name,
					currentWorkspaceId
				}
			});
		},
		updatePendo() {
			const { currentOrganizationId, currentWorkspaceId } = self.currentPendoUserAndSpace;
			
			window.pendo.identify({
				visitor: {
					id: self.user?.id,
					email: self.user?.email,
					full_name: `${self.user?.first_name || ''} ${self.user?.last_name}`,
					role: self.currentRole
				},
				account: {
					id: currentOrganizationId,
					name: self.current_organization?.name,
					currentWorkspaceId
				}
			});
		}
	}));

import { observer } from 'mobx-react-lite';
import { ModelConnector } from 'stores';
import { Container, Form, Input } from 'components';
import OrganizationModel from './organization.model';

const Organization = observer(({ model }) => {
	return (
		<Container isLoading={model.isLoading}>
			<Form>
				<Form.Item label="Organization Name">
					<Input
						placeholder="Name of organization"
						value={model.organization?.name}
						onChange={(e) => model.organization.setName(e.target.value)}
						type="text"
					/>
				</Form.Item>
			</Form>
		</Container>
	);
});

export default ModelConnector(Organization, OrganizationModel);

import classNames from 'classnames';
import SVG from 'components/svg/svg';
import React, {Component} from 'react';
import PropTypes from "prop-types";

export default class Button extends Component {
  render() {
    const {
      className,
      icon,
      id,
      iconSize,
      htmlType = 'button',
      onClick,
      shape,
      size,
      style,
      type = 'primary',
      suffix,
			freezeStyle = false,
    } = this.props;

    return (
      <button
        className={classNames(`tejik-button tejik-button-${type}`, {
          [`tejik-button-${shape}`]: (shape !== null) && !freezeStyle,
					[`tejik-button-${shape}-no-hover`]: (shape !== null) && freezeStyle,
          'tejik-button-large': size === 'large',
          'tejik-button-small': size === 'small',
          'tejik-button-icon': !this.props.children
        }, className)}
        id={id}
        type={htmlType}
        onClick={onClick}
        style={style}
      >
        {icon && <SVG className="tejik-button-prefix-icon" name={icon} size={iconSize || 16}/>}
        {this.props.children}
        {suffix && <SVG className="tejik-button-suffix-icon" name={suffix} size={iconSize || 16}/>}
      </button>
    );
  }
}

Button.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string,
  iconSize: PropTypes.number,
  htmlType: PropTypes.oneOf(['button']),
  onClick: PropTypes.func,
  shape: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary', 'black', 'hollow', 'link', 'text', 'prefix-icon', 'sufix-icon', 'hollow-dark', 'hollow-gray']),
  suffix: PropTypes.string
}
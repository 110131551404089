import { Card, Container, Info } from "components";

// import classNames from 'classnames/bind';
// import styles from './workspace-settings.scss';

// const cx = classNames.bind(styles);

function Templates() {
	return (
		<Container title="Templates" titleSize={1} titleIcon={'description'} titleWeight={400} >
			<Container.Column span={4}>
				<Card action="edit" icon="text_fields" text="Character Limits" to="character-limits" />
			</Container.Column>
			<Container.Column span={4}>
				<Card action="edit" icon="add_link" text="Content Links" to="content-links" />
			</Container.Column>
			<Container.Column span={4}>
				<Card action="edit" icon="recycling" text="Recycle Content" to="recycle-content" />
			</Container.Column> 
			<Info infoKey="workspace-templates"/>
		</Container>
	);
}

export default Templates;

import request from 'api/request';
import { flow, types } from 'mobx-state-tree';
import { OrganizationModel } from 'models/organization.model';

const { boolean, maybeNull, model, optional } = types;
// Model
const OrganizationViewModel = model('OrganizationViewModel', {
	isLoading: optional(boolean, true),
	organization: maybeNull(OrganizationModel),
})
	.views((self) => ({
		get baseURL() {
			return `/organizations/${self.organizationId}`;
		},
		get organizationId() {
			return self.props.params.organization_id;
		},
	}))
	.actions((self) => ({
		afterCreate() {
			self.fetchOrganization();
		},
		fetchOrganization: flow(function* () {
			const { data } = yield request.get(self.baseURL);
			self.organization = data;
			self.isLoading = false;
		}),
	}));

export default OrganizationViewModel;

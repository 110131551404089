// import request from 'api/request';
import { types } from 'mobx-state-tree';

const { model } = types;
// Model
const ProfileModel = model('ProfileModel', {}).views((self) => ({
	get user() {
		return self.AuthStore.user;
	}
})).actions((self) => ({
	updateProfile() {
		console.log('updated profile');
	}
}));

export default ProfileModel;

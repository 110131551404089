import request from 'api/request';
import { flow, getSnapshot, types } from 'mobx-state-tree';
import debounce from 'lodash.debounce';
import { Modal } from 'components';
import { rootStore } from 'stores';

const { message } = Modal;
const { array, enumeration, identifier, string, maybeNull, model } = types;

export const UserModel = model('UserModel', {
	id: identifier,
	first_name: maybeNull(string),
	last_name: maybeNull(string),
	email: string,
	organizations: array(model('UserOrganizationModel', {
		organization_id: string,
		role: enumeration('UserRole', ['ADMIN', 'GUEST', 'MEMBER', 'OWNER']),
	})),
	last_active: maybeNull(string),
	workspaces: array(model('WorkspaceIds', {
		id: string,
	})),
	tutorial_completed_at: maybeNull(string),
	appVersion: maybeNull(string),
}).views((self) => ({
	get baseURL() {
		return `${rootStore.AuthStore.baseURL}/users/${self.id}`;
	},
	get initials() {
		if (!self.first_name || !self.last_name) return null;
		return self.first_name[0] + self.last_name[0];
	},
	get fullName() {
		return (!self.first_name ? '' : self.first_name) + (self.first_name && self.last_name ? ' ' : '') +  (!self.last_name ? '' : self.last_name);
	},
	get workspaceIds() {
		return self.workspaces.map((workspace) => (workspace.id));
	},
	role(organization_id) {
		return self.organizations.find((organization) => organization.organization_id === organization_id)?.role;
	}
})).actions((self) => ({
	saveUser: debounce((...props) => self.updateUser(...props), 700),
	updateUser: flow(function* (include_message = true, include_workspaces = true, organization_id = null) {
		try {
			let data = getSnapshot(self);
			if (!include_workspaces) {
				data = {
					...data,
					workspaces: undefined,
				};
			}

			if (organization_id) {
				data = {
					...data,
					organization: self.organizations.find((organization) => organization.organization_id === organization_id),
				};
			}

			yield request.put(self.baseURL, data);
			if (include_message) {
				message({ type: 'success', text: 'Successfully updated user.' });
			}
		} catch (err) {
			throw err;
		} finally {
		}
	}),
	setFirstName(first_name) {
		self.first_name = first_name;
		self.saveUser(true, false);
	},
	setLastName(last_name) {
		self.last_name = last_name;
		self.saveUser(true, false);
	},
	setWorkspaces(workspaceIds) {
		self.workspaces = workspaceIds.map((id) => ({ id }))
		self.saveUser();
	},
	setRole(organization_id, role) {
		const orgIndex = self.organizations.findIndex((organization) => organization.organization_id === organization_id);

		self.organizations[orgIndex].role = role;
		self.saveUser(true, false, organization_id);
	},
	setTutorialCompletedAt() {
		const date = new Date();
		self.tutorial_completed_at = date.toISOString();
		self.saveUser(false, false);
	}
}));

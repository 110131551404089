import { types } from 'mobx-state-tree';
import { UserModel } from './user.model';

const { enumeration, identifier, boolean, number, string, maybeNull, model } = types;

export const StatusModel = model('StatusModel', {
	id: identifier,
	name: string,
	color: string,
	assignee: maybeNull(UserModel),
	order: number,
	type: enumeration('StatusType', ['ACTIVE', 'DONE', 'CLOSED']),
	needs_approval: maybeNull(boolean),
})
	.views((self) => ({}))
	.actions((self) => ({}));

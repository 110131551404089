import { types, flow } from 'mobx-state-tree';
import request from 'api/request';

const { string, boolean, model, optional } = types;
// Model
const JoinOrganizationModel = model('JoinOrganizationModel', {
	first_name: optional(string, ''),
	last_name: optional(string, ''),
	email: optional(string, ''),
	password: optional(string, ''),
	token: optional(string, ''),
	isLoading: optional(boolean, true),
}).actions((self) => ({
	afterCreate() {
		const queryParams = new URLSearchParams(self.props.location.search);
		self.token = queryParams.get('token') || '';
		self.init();
	},
	init: flow(function* () {
		const { data } = yield request.get('/auth/join-organization', {
			params: {
				token: self.token,
			}
		});

		self.email = data.email;
		self.isLoading = false;
	}),
	joinOrganization: flow(function* () {
		try {
			yield request.post('/auth/join-organization', {
				first_name: self.first_name,
				last_name: self.last_name,
				password: self.password,
				token: self.token,
			});

			self.AuthStore.fetchSession();
			self.props.navigate('/');
		} catch (err) {
			console.error(err);
		}
	}),
	// Setters
	setFirstName(first_name) {
		self.first_name = first_name;
	},
	setLastName(last_name) {
		self.last_name = last_name;
	},
	setPassword(password) {
		self.password = password;
	},
}));

export default JoinOrganizationModel;

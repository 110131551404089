import request from 'api/request';
import { destroy, flow, getParent, getSnapshot, types } from 'mobx-state-tree';
import { Modal } from 'components';
import debounce from 'lodash.debounce';
import { PlatformEnum } from 'models/global/global.model';

const { message } = Modal;
const { array, boolean, model, optional, identifier, string, maybeNull, number } = types;

export const ContentTypeModel = model('ContentTypeModel', {
	id: identifier,
	name: string,
	platforms: array(PlatformEnum),
	order: number,
})
	.views((self) => ({
		get baseURL() {
			return `${self.parent.contentTypeBaseURL}/${self.id}`;
		},
		get parent() {
			return getParent(self, 2);
		},
		hasPlatform(value) {
			return self.platforms.includes(value);
		},
	}))
	.actions((self) => ({
		saveContentType: debounce(() => self.updateContentType(), 700),
		deleteContentType: flow(function* () {
			try {
				yield request.delete(self.baseURL);
				self.parent.removeContentType(self);
				message({ type: 'success', text: 'Successfully deleted content type.' });
			} catch (err) {
				throw err;
			} finally {
			}
		}),
		updateContentType: flow(function* (showSuccess = true) {
			try {
				yield request.put(self.baseURL, getSnapshot(self));

				if (showSuccess) {
					message({ type: 'success', text: 'Successfully updated content type.' });
				}
			} catch (err) {
				console.log(err);
				throw err;
			} finally {
			}
		}),
		setName(name) {
			self.name = name;
			self.saveContentType();
		},

		// Platform
		addPlatform(value) {
			self.platforms.push(value);
		},
		removePlatform(value) {
			const index = self.platforms.findIndex((platform) => platform === value);
			self.platforms.splice(index, 1);
		},
		togglePlatform(value) {
			if (self.hasPlatform(value)) {
				self.removePlatform(value);
			} else {
				self.addPlatform(value);
			}
			self.saveContentType();
		},
	}));

export const SocialCaptionModel = model('SocialCaptionModel', {
	id: identifier,
	hook_platforms: array(PlatformEnum),
	transformation_platforms: array(PlatformEnum),
	call_to_action_platforms: array(PlatformEnum),
	link_platforms: array(PlatformEnum),
})
	.views((self) => ({
		get baseURL() {
			return `${self.parent.socialCaptionBaseURL}/${self.id}`;
		},
		get parent() {
			return getParent(self, 1);
		},
	}))
	.actions((self) => ({
		saveSocialCaption: debounce(() => self.updateSocialCaption(), 700),
		updateSocialCaption: flow(function* () {
			try {
				yield request.put(self.baseURL, getSnapshot(self));
				message({ type: 'success', text: 'Successfully updated social media captions.' });
			} catch (err) {
				console.log(err);
				throw err;
			} finally {
			}
		}),
		// Hook Platforms
		toggleHookPlatform(value) {
			const hasPlatform = self.hook_platforms.includes(value);
			if (hasPlatform) {
				const index = self.hook_platforms.findIndex((platform) => platform === value);
				self.hook_platforms.splice(index, 1);
			} else {
				self.hook_platforms.push(value);
			}
			self.saveSocialCaption();
		},
		toggleTransformationPlatform(value) {
			const hasPlatform = self.transformation_platforms.includes(value);
			if (hasPlatform) {
				const index = self.transformation_platforms.findIndex((platform) => platform === value);
				self.transformation_platforms.splice(index, 1);
			} else {
				self.transformation_platforms.push(value);
			}
			self.saveSocialCaption();
		},
		toggleCallToActionPlatform(value) {
			const hasPlatform = self.call_to_action_platforms.includes(value);
			if (hasPlatform) {
				const index = self.call_to_action_platforms.findIndex((platform) => platform === value);
				self.call_to_action_platforms.splice(index, 1);
			} else {
				self.call_to_action_platforms.push(value);
			}
			self.saveSocialCaption();
		},
		toggleLinkPlatform(value) {
			const hasPlatform = self.link_platforms.includes(value);
			if (hasPlatform) {
				const index = self.link_platforms.findIndex((platform) => platform === value);
				self.link_platforms.splice(index, 1);
			} else {
				self.link_platforms.push(value);
			}
			self.saveSocialCaption();
		},
	}));

// Model
const SocialViewModel = model('SocialViewModel', {
	contentTypes: array(ContentTypeModel),
	socialCaption: maybeNull(SocialCaptionModel),
	isLoading: optional(boolean, true),
})
	.views((self) => ({
		get socialCaptionBaseURL() {
			return `${self.props.workspace.baseURL}/settings/social/social-captions`;
		},
		get contentTypeBaseURL() {
			return `${self.props.workspace.baseURL}/settings/social/content-types`;
		},
		get orderedContentTypes() {
			return Array.from(self.contentTypes)
				.slice()
				.sort((a, b) => a.order - b.order);
		},
	}))
	.actions((self) => ({
		afterCreate() {
			self.fetchSocialCaption();
			self.fetchContentTypes();
		},
		fetchSocialCaption: flow(function* () {
			try {
				const { data } = yield request.get(self.socialCaptionBaseURL);
				self.socialCaption = data;
			} catch (err) {
				console.log(err);
				throw err;
			} finally {
			}
		}),
		fetchContentTypes: flow(function* () {
			try {
				const { data } = yield request.get(self.contentTypeBaseURL);
				self.contentTypes = data;
			} catch (err) {
				console.log(err);
				throw err;
			} finally {
				self.isLoading = false;
			}
		}),
		addContentType: flow(function* (e, { name, platforms }) {
			try {
				self.isLoading = true;
				const maxOrder = Math.max(...self.contentTypes.map(({ order }) => order), 0);
				const contentType = { name, platforms, order: maxOrder + 1 };

				const { data } = yield request.post(self.contentTypeBaseURL, contentType);
				self.contentTypes = [...self.contentTypes, data];
				message({ type: 'success', text: 'Successfully created content type.' });
			} catch (err) {
				throw err;
			} finally {
				self.isLoading = false;
			}
		}),
		removeContentType(contentType) {
			self.isLoading = true;
			destroy(contentType);
			self.isLoading = false;
		},
		onDragEnd(result) {
			if (!result.destination) {
				return;
			}

			if (result.destination.index === result.source.index) {
				return;
			}

			const contentTypesToUpdate = [];

			if (result.source.index > result.destination.index) {
				// Coming from the bottom up
				const sourceContentType = self.orderedContentTypes.at(result.source.index);
				const destinationContentType = self.orderedContentTypes.at(result.destination.index);
				const temp = destinationContentType.order;
				for (const contentType of self.orderedContentTypes) {
					if (contentType.order >= destinationContentType.order && contentType.order < sourceContentType.order) {
						contentType.order = contentType.order + 1;
						contentTypesToUpdate.push(contentType);
					}
				}
				sourceContentType.order = temp;
				contentTypesToUpdate.push(sourceContentType);
			} else {
				// Moving from the top down
				const sourceContentType = self.orderedContentTypes.at(result.source.index);
				const destinationContentType = self.orderedContentTypes.at(result.destination.index);
				const temp = destinationContentType.order;
				for (const contentType of self.orderedContentTypes) {
					if (contentType.order <= destinationContentType.order && contentType.order > sourceContentType.order) {
						contentType.order = contentType.order - 1;
						contentTypesToUpdate.push(contentType);
					}
				}
				sourceContentType.order = temp;
				contentTypesToUpdate.push(sourceContentType);
			}

			for (const contentType of contentTypesToUpdate) {
				contentType.updateContentType(false);
			}
			message({ type: 'success', text: 'Successfully reorded content types.' });
		},
	}));

export default SocialViewModel;

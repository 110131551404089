import { useState } from 'react';
import { Observer, observer } from 'mobx-react-lite';
import { Button, Container, Form, Info, Input, List, Popover, Title } from 'components';
import SVG from "components/svg/svg";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Link } from "react-router-dom";
import { ModelConnector } from "stores";
import TasksModel from "./tasks.model";
import { IsTaskPlatform, PlatformIconMap, PlatformLabelMap } from 'utils/utils';

import classNames from 'classnames/bind';
import styles from './tasks.scss';

const cx = classNames.bind(styles);

const Task = observer(({ task, index }) => (
	<Draggable draggableId={task.id} index={index}>
		{(provided) => (
			<div
				className={cx('draggable-item')}
				ref={provided.innerRef}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
			>
				<Observer>{() => (<>
					<SVG className="text-neutral-2" name="drag_indicator" />
					<Input
						value={task.name}
						onChange={(e) => task.setName(e.target.value)}
						size="small"
						hidden
						inputStyle={{ backgroundColor: 'transparent', fontSize: 14 }}
					/>
					<Button type="text" icon="delete" size="small" onClick={task.deleteTask} />
				</>)}</Observer>
			</div>
		)}
	</Draggable>
));

const RenderedExpandedView = observer(({ addTask, platform, orderedTasks, onDragEnd }) => {
	const [value, setValue] = useState('');

	return (
		<div className={cx('draggable-list')} >
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="list">
					{(provided) => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							{orderedTasks.map((task, index) => <Task task={task} index={index} key={task.id} />)}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
			<Popover
				contentClass={classNames('new-task-popup')}
				content={
					<Form onSubmit={(e) => addTask(e, value, platform)}>
						<Title size={6} weight={400}>
							<SVG name="add" width="20" className="mr-1" />
							Add new task
						</Title>
						<Form.Item>
							<Input value={value} onChange={(e) => setValue(e.target.value)} size="small" placeholder="enter task name/description" />
						</Form.Item>
					</Form>
				}
				position="left"
			>
				<Button style={{marginTop: orderedTasks.length > 0 ? '1.25em' : 0, marginBottom: '0.25em'}} type="hollow" icon="add">
					Add new task
				</Button>
			</Popover>
		</div>
	);
});

const Tasks = observer(({ model }) => {
	return (
		<Container title="Tasks Settings" titleSize={1} titleIcon={'task'} titleWeight={400} actions={<Link to="/workspace-settings"><SVG name="close" /></Link>} isLoading={model.isLoading}>
			<List defaultSelect={true} rows={model.workspace.platforms.filter((platform) => IsTaskPlatform(platform)).map((platform) => ({
				id: platform,
				title: (
					<div className={cx('task-platform-name')}>
						<SVG name={PlatformIconMap.get(platform)} className="mr-1"/>
						{PlatformLabelMap.get(platform)}
					</div>
				),
				expandedView: <RenderedExpandedView
					platform={platform}
					orderedTasks={model.filteredTasks(platform)}
					addTask={model.addTask}
					onDragEnd={(e) => model.onDragEnd(e, platform)}
				/>,
			}))} />
			<Info infoKey="workspace-tasks"/>
		</Container>
	);
});

export default ModelConnector(Tasks, TasksModel);

import { observer } from "mobx-react-lite";
import { Outlet } from "react-router-dom";
import CreateWorkspaceModal from "modals/createWorkspace/createWorkspace";
import Topbar from "views/base/topbar/topbar";
import { rootStore } from 'stores';

import classNames from 'classnames/bind';
import styles from './app.scss';

const cx = classNames.bind(styles);

function Main() {
	return (
		<div className={cx('app')}>
			<Topbar/>
			<Outlet />
			<CreateWorkspaceModal
				onOk={rootStore.WorkspaceStore.onNewWorkspace}
				onCancel={rootStore.WorkspaceStore.hideNewWorkspaceModal}
				visible={rootStore.WorkspaceStore.visibleNewWorkspaceModal}
			/>
		</div>
	);
}

export default observer(Main);

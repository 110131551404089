import { Loader } from 'components';
import { observer } from 'mobx-react-lite';
import React from 'react'
import { Navigate, useLocation } from "react-router-dom"
import { rootStore } from 'stores';

const ProtectedRoute = ({ children }) => {
	const location = useLocation();

	const { isAuthenticated, isLoading } = rootStore.AuthStore;

	if (isLoading) {
		return (
			<Loader type="centered" />
		);
	}

	if (!isAuthenticated) {
		return <Navigate to="/login" state={{ from: location }} />
	}
	return children;

};

export default observer(ProtectedRoute);
import {useEffect} from "react";
import {
  Routes, Route, Navigate, useLocation,
} from "react-router-dom";

import ProtectedRoute from "views/protected-route.view";

// App Routes
import AppView from "views/app/app.view";
import DashboardView from "./app/dashboard/dashboard.view";
import MainView from "./app/main/main.view";
import CampaignsView from "views/app/campaigns/campaigns.view";
import CampaignView from "views/app/campaigns/campaign/campaign.view";
import PlannerView from "./app/planner/planner.view";
import HelpView from "./app/help/help.view";
import IdeasView from "views/app/ideas/ideas.view";
import IdeaView from "views/app/ideas/idea/idea.view";
import CampaignDetail from "views/app/campaigns/campaign-detail/campaign-detail.view";

// Workspace Settings Routes
import WorkspaceSettings from "views/app/workspace-settings/workspace-settings.view";

// Account Routes
import AccountSettings from "views/app/account-settings/account-settings.view";
import ProfileView from "views/app/account-settings/profile/profile.view";
import OrganizationsView from "./app/account-settings/organizations/organizations.view";

// Organization Routes
import OrganizationSettings from "views/app/organization-settings/organization-settings.view";
import OrganizationView from "./app/organization-settings/organization/organization.view";
import WorkspacesView from "views/app/organization-settings/workspaces/workspaces.view";
import WorkspaceView from "views/app/organization-settings/workspaces/workspace/workspace.view";
import UsersView from "views/app/organization-settings/users/users.view";

// Auth Routes
import LoginPage from "views/authentication/login.view";
import ForgotPassword from "views/authentication/forgot-password.view";
import ResetPassword from "views/authentication/reset-password.view";
import SignUp from "views/authentication/sign-up.view";
import Logout from "views/authentication/logout.view";
import JoinOrganizationView from "./authentication/join-organization/join-organization";

import TagManager from 'react-gtm-module'
import {DndContext, PointerSensor, useSensor, useSensors} from '@dnd-kit/core';
// import {act} from "react-dom/test-utils";

const gtmId = process.env.NODE_ENV === 'production' ? 'GTM-NCR3JPKT' : 'GTM-MPD2NMLT';
const tagManagerArgs = {
  gtmId,
}

TagManager.initialize(tagManagerArgs)

function App() {
  let location = useLocation();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {distance: 10}
    }),)

  useEffect(() => {
    window.dataLayer.push({
      event: 'pageview', value: location.pathname,
    });
  }, [location]);

  return (<DndContext sensors={sensors}>
    <Routes>
      <Route path="/" element={<ProtectedRoute>
        <AppView/>
      </ProtectedRoute>}>
        {/* Main */}
        <Route path="/" element={<MainView/>}>
          <Route index element={<DashboardView/>}/>
          <Route path="/campaigns" element={<CampaignsView/>}/>
          <Route path="/planner" element={<PlannerView/>}/>
          <Route path="/ideas" element={<IdeasView/>}/>
          <Route path="/ideas/:idea_id" element={<IdeaView/>}/>
          <Route path="/help" element={<HelpView/>}/>
          {/* <Route path="/analytics" element={<Campaigns />} /> */}
        </Route>
        <Route path="/campaigns/:campaign_id" element={<CampaignDetail/>}>
          <Route index element={<CampaignView/>}/>
        </Route>
        {/* Workspace Settings */}
        <Route path="/workspace-settings/*" element={<WorkspaceSettings/>}/>

        {/* Settings */}
        <Route path="/account-settings" element={<AccountSettings/>}>
          <Route path="profile" element={<ProfileView/>}/>
          <Route path="organizations" element={<OrganizationsView/>}/>
          <Route
            path=""
            element={<Navigate to="profile" replace/>}
          />
        </Route>

        {/* Settings */}
        <Route path="/organization-settings/:organization_id" element={<OrganizationSettings/>}>
          <Route index element={<OrganizationView/>}/>
          <Route path="users" element={<UsersView/>}/>
          <Route path="workspaces">
            <Route index element={<WorkspacesView/>}/>
            <Route path="new" element={<WorkspaceView/>}/>
            <Route path=":workspace_id" element={<WorkspaceView/>}/>
          </Route>
          <Route
            path=""
            element={<Navigate to="profile" replace/>}
          />
        </Route>
      </Route>
      <Route path="/login" element={<LoginPage/>}/>
      <Route path="/logout" element={<Logout/>}/>
      <Route path="/forgot-password" element={<ForgotPassword/>}/>
      <Route path="/reset-password" element={<ResetPassword/>}/>
      <Route path="/sign-up" element={<SignUp/>}/>
      <Route path="/join-organization" element={<JoinOrganizationView/>}/>
      {/* <Route
				path="*"
				element={<Navigate to="/" replace />}
			/> */}
    </Routes>
  </DndContext>);
}

export default App;
